<template>
  <v-content style="height: 100% !important;">
    <v-container fluid pa-0 style="height: 100% !important;">
      <loading-status-box
        v-if="isTaskDataLoading"
        :message="taskLoadingMessage"
        :type="taskLoadingType"
      >
      </loading-status-box>
      <template v-else>
        <task-header
          :taskId="Number($route.params.taskid)"
          :taskData="taskDataRetrieved"
          :selfSigned="selfSigned"
        ></task-header>

        <!-- general task view -->
        <v-card class="px-4 py-4 mt-0 " elevation="0" :style="cardHeightStyle">
          <v-row class="modal-row" ref="vRowHeight" :style="rowHeightStyle">
            <!-- task -->
            <v-spacer></v-spacer>
            <v-col cols="10" lg="8" xl="6" class="d-flex fill-height">
              <task-container
                :TDSHStyle="rowHeightStyle"
                :task="taskDataRetrieved"
                :canCreate="false"
                :key="0"
                :agentInfo="agentInfo"
                :selfSigned="selfSigned"
              ></task-container>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </template>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DemoHandler from "@/utils/DemoHandler";
import TaskHeader from "@/components/Tasks/TaskHeader.vue";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import TaskContainer from "@/components/Tasks/TaskContainer.vue";

const NO_TDL_NO_SS_CARD = [188, 150];
const NO_TDL_NO_SS_ROW = [388, 250];
const NO_TDL_IS_SS_CARD = [125, 200];
const NO_TDL_IS_SS_ROW = [50, 200];
const DEFAULT_CARD = [50, 200];
const DEFAULT_ROW = [100, 200];

export default {
  components: {
    TaskHeader,
    LoadingStatusBox,
    TaskContainer
  },
  data() {
    return {
      taskDataRetrieved: null,
      rowHeight: 0,
      rowHeightStyle: "",
      cardHeight: 0,
      cardHeightStyle: "",
      debounceTimeout: null
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Number,
      required: true
    },
    taskDataPassed: {
      type: Object,
      required: false,
      default: null
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("tasks", [
      "taskData",
      "taskDataLoadingStatus",
      "taskDataLoadingError",
      "taskDataLoadingErrorStatus"
    ]),
    ...mapGetters("resources", [
      "agentData",
      "agentDataLoadingStatus",
      "agentDataLoadingError",
      "agentDataLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userData"]),
    agentInfo() {
      if (this.agentDataLoadingStatus == "loaded") {
        // if route is self signed, use url agent id otherwise use the agent id of the task...
        if (this.selfSigned) {
          return this.agentData.find(o => o.id == this.$route.params.agentid);
        } else {
          return this.taskDataRetrieved &&
            this.taskDataRetrieved.hasOwnProperty("task_agent")
            ? this.agentData.find(
                o => o.id == this.taskDataRetrieved.task_agent
              )
            : { name: "", id: "" };
        }
      } else {
        return { name: "", id: "" };
      }
    },
    isTaskDataLoading() {
      return (
        this.taskDataLoadingStatus != "loaded" ||
        this.agentDataLoadingStatus != "loaded"
      );
    },
    taskLoadingType() {
      if (this.taskDataLoadingError || this.agentDataLoadingError)
        return "error";
      else return "loading";
    },
    taskLoadingMessage() {
      if (this.taskDataLoadingError || this.agentDataLoadingError)
        return "There was an error loading this task...";
      else return "Task loading, please wait...";
    },
  },
  methods: {
    ...mapActions({
      retrieveTaskData: "tasks/retrieveTaskData",
      retrieveAgentData: "resources/retrieveAgentData",
      retrieveAuthorizedUserListData: "users/retrieveAuthorizedUserListData"
    }),
    setHeights() {
      let cardParams, rowParams;
      if (!this.isTaskDataLoading) {
        if (!this.selfSigned) {
          cardParams = NO_TDL_NO_SS_CARD;
          rowParams = NO_TDL_NO_SS_ROW;
        } else {
          cardParams = NO_TDL_IS_SS_CARD;
          rowParams = NO_TDL_IS_SS_ROW;
        }
      } else {
        cardParams = DEFAULT_CARD;
        rowParams = DEFAULT_ROW;
      }
      this.cardHeight = this.getHeightVal(...cardParams);
      this.rowHeight = this.getHeightVal(...rowParams);
      this.cardHeightStyle = `height: ${this.cardHeight}px !important;`;
      this.rowHeightStyle = `height: ${this.rowHeight}px !important;`;
    },
    getHeightVal(valOne, valTwo) {
      return Math.max(window.innerHeight - valOne, valTwo);
    },
    debouncedResize() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setHeights();
      }, 50);
    },
    requestParams() {
      return {
        agent_id: this.$route.params.agentid,
        task_id: this.taskId,
        selfSigned: this.selfSigned,
        reckey: this.$route.params.reckey,
        userid: this.$route.params.userid,
        agent_task_id: this.$route.params.agentid,
        callTask: false,
        user_id: this.$route.params.userid
      };
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  },
  mounted() {
    this.setHeights();
    window.addEventListener("resize", this.debouncedResize);

    let params = this.requestParams();
    if (this.taskDataLoadingStatus == "loaded") {
      this.taskDataRetrieved = this.taskData;
      this.setHeights();
    } else if (!this.taskDataPassed) {
      this.retrieveTaskData(params);
      if (this.selfSigned) {
        this.retrieveAuthorizedUserListData(params);
      } else {
        this.retrieveAuthorizedUserListData(this.userData.id);
      }
    } else if (this.taskDataPassed) {
      this.taskDataRetrieved = this.taskDataPassed;
    }

    if (this.agentDataLoadingStatus != "loaded") {
      this.retrieveAgentData(params);
    }
  },
  watch: {
    taskDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.taskDataRetrieved = this.taskData;
        this.setHeights();
      }
    }
  }
};
</script>
<style scoped>
.d-flex {
  display: flex;
  flex-direction: column;
}

.fill-height {
  height: 100% !important;
}

.modal-row {
  background-color: #fff;
}
</style>
