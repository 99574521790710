<template>
  <div class="main">
    <template v-if="mySelector==''||mySelector==undefined">
      <v-row>
        <v-col class="d-flex justify-center pa-6 ma-0">
          <h4>{{ selectMessage }}</h4>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col class="d-flex justify-center pl-6 pr-6 ma-0">
          <glue-button-group
            ref="dynaLabelGlue"
            :glue="glue"
            :glueOptions="myGlueOptions"
          ></glue-button-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="myType=='list'" class="justify-center ml-6 mr-6 pl-6 pr-6 ma-0 rounded border">
          <v-card-title>
            <v-text-field
                v-model="dataTableSearch"
                class="mt-0 mb-0 mr-2 ml-2"
                placeholder="Find data"
                dense
                hide-details
                variant="solo"
                :clearable="!searchDisabled"
                @keydown.enter.prevent="searchData"
                @click:clear="clearSearch"
            ></v-text-field>
            <v-btn
                :disabled="searchDisabled"
                :dark="!searchDisabled"
                small
                color="primary"
                @click="searchData"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            item-key="value"
            dense
            show-select
            some-items
            height="40vh"
            disable-pagination
            :hide-default-footer="true"
            v-model="dataTableSelected"
            :headers="dataTableHeaders"
            :items="dataTableData"
            :loading="selectionDataLoadingType"
            :loading-text="selectionDataLoadingMessage"
            class="dl-dt"
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-simple-checkbox
                :value="props.value || props.indeterminate"
                v-on="on"
                :indeterminate="props.indeterminate"
                color="primary"
              />
            </template>
            <template v-slot:header.name="{ header }">
              {{ header.text }}
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else-if="myType=='number'" class="d-flex justify-center pl-6 pr-6 ma-0">
          <v-text-field
            outlined
            v-model="numberField"
            type="number"
            placeholder="digits only"
            label="Value/Metric for Comparison"
            class="numberField"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col v-else-if="myType=='timesec'" class="d-flex justify-center pl-6 pr-6 ma-0">
          <vue-timepicker
            manual-input
            hide-dropdown
            drop-direction="up"
            format="HH:mm:ss"
            v-model="hmsTime"
            @change="timeHandler"
          ></vue-timepicker>
        </v-col>
        <v-col v-else-if="myType=='datetime'" class="d-flex justify-center pl-6 pr-6 ma-0">
          <vc-date-picker class="modalPicker"
            v-model="selectedDate"
            :mode="this.getPickerMode()"
            :available-dates="{ start: null, end: new Date() }"
          >
          </vc-date-picker>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>

  import GlueButtonGroup from "@/components/DynaLabels/GlueButtonGroup.vue";
  import {mapGetters} from "vuex";
  import VueTimepicker from "vue2-timepicker";
  import moment from "moment";

  export default {
    components: {
      GlueButtonGroup,
      VueTimepicker,
    },
    props: {
      mySelector: String,
      mySelectors: Array,
      myType: String,
      myKind: String,
      myGlueOptions: Array,
      originalSelector: String,
      originalSelection: Array,
      originalGlue: String,
      originalGlueOptions: Array,
      originalKind: String,
      originalType: String,
      mySearch: String,
      dataTableSelectedRoot: Array,
      searchActionClicked: Boolean
    },
    data() {
      return {
        glue: '',
        mySelection: [],
        myOptions: [],
        numberField: null,
        dataTableData: [],
        dataTableSelected: [],
        dataTableSearch: this.mySearch,
        hmsTime: {
          HH: "",
          mm: "",
          ss: ""
        },
        selectedDate: {
          start: moment().subtract(1, 'days').toDate(),
          end: moment().subtract(1, 'days').toDate()
        },
        uiSearchFields: ['agent_id', 'acctnmbr'],
      }
    },
    computed: {
      ...mapGetters('dynaLabels', [
        'dynaLabelsSelectors',
        'dynaLabelsSelectorsLoadingStatus',
        'dynaLabelsSelectorsLoadingError',
        'dynaLabelsSelectorsLoadingErrorStatus',
        'dynaLabelsSelections',
        'dynaLabelsSelectionsLoadingStatus',
        'dynaLabelsSelectionsLoadingError',
        'dynaLabelsSelectionsLoadingErrorStatus',
      ]),
      ...mapGetters('resources', [
        'accountData',
        'accountDataLoadingStatus',
        'accountDataLoadingError',
        'accountDataLoadingErrorStatus',
        'agentData',
        'agentDataLoadingStatus',
        'agentDataLoadingError',
        'agentDataLoadingErrorStatus',
      ]),
      selectionDataLoadingMessage() {
        switch(this.dynaLabelsSelectionsLoadingStatus) {
          case "notLoading":
            return "Initializing, please wait"
          case "loading":
            return "Loading selection options, please wait"
          case "failed":
            return "Unable to load selection options, please try again"
          case "loaded":
          default:
            return "Selection options loading complete"
        }
      },
      selectionDataLoadingType() {
        switch(this.dynaLabelsSelectionsLoadingStatus) {
          case "notLoading":
            return "warning"
          case "loading":
            return "primary"
          case "failed":
            return "error"
          case "loaded":
          default:
            return false
        }
      },
      loadingSelections() {
        return this.dynaLabelsSelectionsLoadingStatus != 'loading'
      },
      searchDisabled() {
        let validSearch = this.dataTableSearch && this.dataTableSearch.trim() != "" ? true : false;
        return !this.loadingSelections || !validSearch;
      },
      dataTableHeaders() {
        return [{text: this.getSelectorHeader, value: 'name', class: 'overline'}]
      },
      myAccountResources() {
        return this.accountData.map(a => { return {'acctnmbr': a.id, 'name': a.name} })
        .sort((a, b) => (a.name > b.name) ? 1 : -1)
      },
      myAgentResources() {
        return this.agentsOnly.map(a => { return {'agent_id': a.id, 'name': a.name} })
          .sort((a, b) => (a.name > b.name) ? 1 : -1)
      },
      getSelectorHeader() {
        return this.mySelectors.find(s=>s.value==this.mySelector).text + 's' || ''
      },
      myGlue() {
        return this.$refs.dynaLabelGlue.toggleGroup
      },
      selectMessage() {
        if(this.myType=='dynalabel') return 'please select a dynamic label property from the list above'
        else if(this.myType=='search') return 'please select a search'
        else return ''
      },
      agentsOnly() {
        return this.agentData.filter((profile) => profile.profile_type == 0)
      },
    },
    methods: {
      checkLoaded() {
        if(this.dynaLabelsSelectionsLoadingStatus==='loaded') {
          this.loadSelections() // does this infinite loop things?
        } else {
          this.dataTableData = []
        }
      },
      frontendSearch(frontendData) {
        //using startsWith() instead of includes() because the backend search runs the query using : value LIKE 'xyz%' ...
        return (this.dataTableSearch) ? frontendData.filter(str => str.name.toLowerCase().startsWith(this.dataTableSearch.toLowerCase())) : frontendData
      },
      loadSelections() {
        if(this.dynaLabelsSelectionsLoadingStatus!=='loaded') return
        let options = []
        let sortedData = []
        let allSelected = []
        let selections = []
        if(this.originalSelector!=this.mySelector) {
          // the selector was changed, so reset things
          this.glue = this.myGlueOptions[0].value
          this.mySelection = []
        } else {
          // the selector is back to its original state, so put things back
          this.glue = this.originalGlue
          this.mySelection = this.originalSelection
        }
        if(this.myType=='list') {
          switch (this.mySelector) {
            case 'acctnmbr':
              let accounts = this.myAccountResources.map(obj => { return {'name': obj.name + ' (' + obj.acctnmbr + ')', 'value': obj.acctnmbr} })
              options = this.frontendSearch(accounts)
              selections = this.getSelectionsFrontend(accounts)
              sortedData = this.getAlphaSortSelectedUnselected(options, selections)
              this.dataTableData = sortedData.all_data
              allSelected = sortedData.selected
              break;
            case 'agent_id':
              let agents = this.myAgentResources.map(obj => { return {'name': obj.name + ' (' + obj.agent_id + ')', 'value': obj.agent_id} })
              options = this.frontendSearch(agents)
              selections = this.getSelectionsFrontend(agents)
              sortedData = this.getAlphaSortSelectedUnselected(options, selections)
              this.dataTableData = sortedData.all_data
              allSelected = sortedData.selected
              break;
            default:

              options = (this.dynaLabelsSelections[this.mySelector]) ? this.dynaLabelsSelections[this.mySelector].map(v => { return {'name': v, 'value': v} }) : []
              selections = this.getSelectionsBackend()
              sortedData = this.getAlphaSortSelectedUnselected(options, selections)
              this.dataTableData = sortedData.all_data
              allSelected = sortedData.selected
              break;
          }

          this.$emit("selectedDataChanged", allSelected);

        } else if(this.myType=='number') {
          this.numberField = this.mySelection
        } else if(this.myType=='timesec') {
          this.timeSec = this.mySelection[0]
          let times = this.formatHMS(this.timeSec)
          this.hmsTime = {
            HH: times[0],
            mm: times[1],
            ss: times[2]
          };
        } else if(this.myType=='datetime') {
          this.setSelectedDate()
        }
      },
      removeDupes(selectedArr) {
        return Array.from(new Set(selectedArr.map(a => a.value))).map(value => {return selectedArr.find(a => a.value === value)})
      },
      getSelectionsBackend() {
        if (!this.searchActionClicked) {
          let selections = []
          this.mySelection.forEach((item)=>{
            selections.push({
              name: item,
              value: item
            })
          })
          return selections
        }
        return this.dataTableSelected
      },
      getSelectionsFrontend(selectedData) {
        if (!this.searchActionClicked) {
          return selectedData.filter(item => this.mySelection.includes(item.value)).map(item => ({ name: item.name, value: item.value }))
        }
        return this.dataTableSelected
      },
      getAlphaSortSelectedUnselected(options, selections) {
        let combinedSelection = this.removeDupes(Array(...selections,...this.dataTableSelected))
        let selected = combinedSelection.sort((a, b) => (a.name > b.name) ? 1 : -1)
        let unselectedBackend = options.filter(d => !this.mySelection.some(s => d.value == s)).sort((a, b) => (a.name > b.name) ? 1 : -1)
        let unselectedFrontend = options.filter(d => !this.dataTableSelected.some(s => d.value == s)).sort((a, b) => (a.name > b.name) ? 1 : -1)
        let allUnselected = this.removeDupes(Array(...unselectedBackend,...unselectedFrontend))
        return {
          'all_data' : this.removeDupes(Array(...selected,...allUnselected)),
          'selected' : selected
        }
      },
      timeHandler(e) {
        if (e.displayTime == "") {
          this.timeSec = 0;
        } else {
          this.timeSec = this.convertTimeToSeconds(e.displayTime);
        }
      },
      formatHMS(seconds) {
        return moment("1900-01-01 00:00:00")
          .add(seconds, "seconds")
          .format("HH:mm:ss")
          .split(":");
      },
      convertTimeToSeconds(time) {
        return moment(time.replace(/[Hms]/g,0), 'HH:mm:ss')
        .diff(moment()
        .startOf('day'), 'seconds');
      },
      setSelectedDate() {
        if (this.glue == 'between') {
          // is a range
          let dates = this.mySelection[0].split(' - ')
          this.selectedDate.start = new Date(dates[0] + 'T00:00:00')
          this.selectedDate.end = new Date(dates[1] + 'T00:00:00')
        } else {
          // only one date
          this.selectedDate = new Date(this.mySelection[0] + 'T00:00:00')
        }
      },
      getPickerMode() {
        let pickerMode = 'range'
        let glueValue = (this.$refs.dynaLabelGlue !== undefined ? this.myGlue : this.glue)
        if (glueValue == 'between')
          pickerMode = 'range'
        else if (glueValue == 'eq' || glueValue == 'lte' || glueValue == 'gte')
          pickerMode = 'single'
        return pickerMode
      },
      filterDataSearch() {
        this.$emit("searchSelectors", {search_string: this.dataTableSearch, columns: [this.mySelector]});
      },
      searchData() {
        this.$emit("searchActionClicked",true);
        if (!this.uiSearchFields.includes(this.mySelector)) {
          //if not searching in the ui, need to send a request to the backend
          this.filterDataSearch()
        } else {
          //if searching in the UI, load the selections and filter by search
          this.loadSelections()
        }
        this.$emit("setSearch", this.dataTableSearch);
      },
      clearSearch() {
        this.$emit("searchActionClicked",true);
        this.clearSearchVar()
        this.$emit("setSearch", this.dataTableSearch);
        this.filterDataSearch()
      },
      clearSearchVar() {
        this.dataTableSearch = "";
      },
      resetSearchAction() {
        this.$emit("searchActionClicked",false);
      },
      updateSearch() {
        this.dataTableSearch = this.mySearch;
      },
      updateSelected() {
        this.dataTableSelected = this.dataTableSelectedRoot
      },
      selectedDataChanged() {
        this.$emit("selectedDataChanged", this.dataTableSelected);
      }
    },
    mounted() {
      this.loadSelections()
    },
    watch: {
      mySelector: 'loadSelections',
      mySelector: 'clearSearchVar',
      mySelector: 'resetSearchAction',
      mySearch: 'updateSearch',
      dynaLabelsSelectionsLoadingStatus: 'checkLoaded',
      dataTableSelectedRoot: "updateSelected",
      dataTableSelected: "selectedDataChanged"
    }
  }
</script>

<style scoped>

  .main {
    max-height: 60vh
  }

  .numberField input[type='number'] {
    -moz-appearance:textfield;
  }
  .numberField input::-webkit-outer-spin-button,
  .numberField input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .v-data-table.dl-dt /deep/ thead {
    background-color: rgb(244,244,244) !important;
  }

  .dl-dt-header {
    font-size: 2rem;
  }

  .time-picker {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .modalPicker {
    position: fixed !important;
  }


</style>
