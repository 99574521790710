import axios from "@/utils/AxiosInstance.js"
import moment from "moment";

export const scorecardTasks = {
  namespaced: true,
  state: {

    scorecardTaskListLoadingErrorStatus: false,
    scorecardTaskListLoadingError: "",
    scorecardTaskListLoadingStatus: "notLoading",
    scorecardTaskList: [],

    guidanceTaskListLoadingErrorStatus: false,
    guidanceTaskListLoadingError: "",
    guidanceTaskListLoadingStatus: "notLoading",
    guidanceTaskList: [],

    guidanceTaskTitlesLoadingErrorStatus: false,
    guidanceTaskTitlesLoadingError: "",
    guidanceTaskTitlesLoadingStatus: "notLoading",
    guidanceTaskTitles: [],

    taskSnapshotLoadingErrorStatus: false,
    taskSnapshotLoadingError: "",
    taskSnapshotLoadingStatus: "notLoading",
    taskSnapshot: [],

    updatingTaskItemInPlace: false,

  },
  getters: {

    scorecardTaskListLoadingErrorStatus: state => state.scorecardTaskListLoadingErrorStatus,
    scorecardTaskListLoadingError: state => state.scorecardTaskListLoadingError,
    scorecardTaskListLoadingStatus: state => state.scorecardTaskListLoadingStatus,
    scorecardTaskList: state => state.scorecardTaskList,

    guidanceTaskListLoadingErrorStatus: state => state.guidanceTaskListLoadingErrorStatus,
    guidanceTaskListLoadingError: state => state.guidanceTaskListLoadingError,
    guidanceTaskListLoadingStatus: state => state.guidanceTaskListLoadingStatus,
    guidanceTaskList: state => state.guidanceTaskList,

    guidanceTaskTitlesLoadingErrorStatus: state => state.guidanceTaskTitlesLoadingErrorStatus,
    guidanceTaskTitlesLoadingError: state => state.guidanceTaskTitlesLoadingError,
    guidanceTaskTitlesLoadingStatus: state => state.guidanceTaskTitlesLoadingStatus,
    guidanceTaskTitles: state => state.guidanceTaskTitles,

    taskSnapshotLoadingErrorStatus: state => state.taskSnapshotLoadingErrorStatus,
    taskSnapshotLoadingError: state => state.taskSnapshotLoadingError,
    taskSnapshotLoadingStatus: state => state.taskSnapshotLoadingStatus,
    taskSnapshot: state => state.taskSnapshot,

    updatingTaskItemInPlace: state => state.updatingTaskItemInPlace,

  },
  mutations: {

    SET_SCORECARD_TASK_LIST_LOADING_ERROR_STATUS(state, status) {
      state.scorecardTaskListLoadingErrorStatus = status
    },
    SET_SCORECARD_TASK_LIST_LOADING_ERROR(state, error) {
      state.scorecardTaskListLoadingError = error
    },
    SET_SCORECARD_TASK_LIST_LOADING_STATUS(state, status) {
      state.scorecardTaskListLoadingStatus = status
    },
    SET_SCORECARD_TASK_LIST(state, scorecardTaskList) {
      state.scorecardTaskList = scorecardTaskList
    },

    UPDATE_OR_ADD_TASK_IN_TASK_LISTS(state, task) {
      const sc_index = state.scorecardTaskList.findIndex(
        (item) => item.task_id === task.task_id
      );

      if (sc_index !== -1) {
        // Update the existing task with the new task properties
        state.scorecardTaskList.splice(sc_index, 1, task);
      } else {
        state.scorecardTaskList.push(task)
      }

      const g_index = state.guidanceTaskList.findIndex(
        (item) => item.task_id === task.task_id
      );

      if (g_index !== -1) {
        // Update the existing task with the new task properties
        state.guidanceTaskList.splice(g_index, 1, task);
      } else {
        state.guidanceTaskList.push(task)
      }

    },

    UPDATING_TASK_ITEM_IN_PLACE(state, status) {
      state.updatingTaskItemInPlace = status
    },

    SET_GUIDANCE_TASK_LIST_LOADING_ERROR_STATUS(state, status) {
      state.guidanceTaskListLoadingErrorStatus = status
    },
    SET_GUIDANCE_TASK_LIST_LOADING_ERROR(state, error) {
      state.guidanceTaskListLoadingError = error
    },
    SET_GUIDANCE_TASK_LIST_LOADING_STATUS(state, status) {
      state.guidanceTaskListLoadingStatus = status
    },
    SET_GUIDANCE_TASK_LIST(state, guidanceTaskList) {
      state.guidanceTaskList = guidanceTaskList
    },

    SET_GUIDANCE_TASK_TITLES_LOADING_ERROR_STATUS(state, status) {
      state.guidanceTaskTitlesLoadingErrorStatus = status
    },
    SET_GUIDANCE_TASK_TITLES_LOADING_ERROR(state, error) {
      state.guidanceTaskTitlesLoadingError = error
    },
    SET_GUIDANCE_TASK_TITLES_LOADING_STATUS(state, status) {
      state.guidanceTaskTitlesLoadingStatus = status
    },
    SET_GUIDANCE_TASK_TITLES(state, guidanceTaskTitles) {
      state.guidanceTaskTitles = guidanceTaskTitles
    },

    SET_SCORECARD_SNAPSHOT_LOADING_ERROR_STATUS(state, status) {
      state.taskSnapshotLoadingErrorStatus = status
    },
    SET_SCORECARD_SNAPSHOT_LOADING_ERROR(state, error) {
      state.taskSnapshotLoadingError = error
    },
    SET_SCORECARD_SNAPSHOT_LOADING_STATUS(state, status) {
      state.taskSnapshotLoadingStatus = status
    },
    SET_SCORECARD_SNAPSHOT(state, taskSnapshot) {
      state.taskSnapshot = taskSnapshot
    },

    // make sure an update to the message propagates without another call to the API
    SET_TASK_MESSAGE_IN_STORE(state, payload) {
      const { task_id, task_message } = payload
      const task = state.scorecardTaskList.find(task => task.task_id === task_id)
      if (task) {
        task.task_message = task_message
      }
    },

    SET_GUIDANCE_TASK_READ_REPLY(state, payload) {
      let guidanceTaskList = state.guidanceTaskList
      let guidanceListIndex = guidanceTaskList.findIndex(object => {
        return object.task_id == payload.task_id;
      });

      if (guidanceListIndex > -1) {
        let task = guidanceTaskList[guidanceListIndex]
        task.last_reply_read_by_coach = payload.read_replies
        guidanceTaskList.splice(guidanceListIndex, 1, task);
      }
    },

    SET_SCORECARD_TASK_READ_REPLY(state, payload) {
      let scorecardTaskList = state.scorecardTaskList
      let scorecardListIndex = scorecardTaskList.findIndex(object => {
        return object.task_id == payload.task_id;
      });

      if (scorecardListIndex > -1) {
        let task = scorecardTaskList[scorecardListIndex]
        task.last_reply_read_by_coach = payload.read_replies
        scorecardTaskList.splice(scorecardListIndex, 1, task);
      }
    },

  },
  actions: {

    async updateTaskInTaskLists(context, payload) {
      context.commit("UPDATING_TASK_ITEM_IN_PLACE", true)

      let axios_params = {params:{}}
      let task_url = process.env.VUE_APP_TASKS + payload.task_id

      if(payload.selfSigned) {
        task_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.task_id
        axios_params['params']['route'] = 'tasks'
        if (payload.callTask) {
          axios_params['params']['call_id'] = payload.call_id
        } else {
          axios_params['params']['agent_id_for_reckey'] = payload.agent_id
        }
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}

        // agent reckey authorization parameters
        axios_params['params']['agent_task_id'] = payload.agent_task_id
      }

      try {
        const response = await axios.get(task_url, axios_params)
        let task = response.data.data
        if (task) {
          context.commit("UPDATE_OR_ADD_TASK_IN_TASK_LISTS", task);
          context.commit("UPDATING_TASK_ITEM_IN_PLACE", false)
          return task;
        }
        context.commit("UPDATING_TASK_ITEM_IN_PLACE", false)
      } catch (error) {
        context.commit("UPDATING_TASK_ITEM_IN_PLACE", false)
        console.error(error);
      }
    },

    retrieveScorecardTaskList(context, payload) {
      let scorecard_id = payload.scorecard_id
      context.commit("SET_SCORECARD_TASK_LIST_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_TASKS + '?type=by_scorecard&scorecard_id=' + scorecard_id)
        .then(response => {

          let scorecardTaskList = response.data.data

          context.commit("SET_SCORECARD_TASK_LIST", scorecardTaskList);
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_STATUS", "loaded");
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_SCORECARD_TASK_LIST", []);
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARD_TASK_LIST_LOADING_ERROR", 'retrieveScorecardTaskList(): ' + error_message)
        });
    },

    retrieveGuidanceTaskList(context, payload) {
      let scorecard_id = payload.scorecard_id
      let agent_id = payload.agent_id
      context.commit("SET_GUIDANCE_TASK_LIST_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_TASKS + '?type=by_sc_agent&scorecard_id=' + scorecard_id + '&agent_id[]=' + agent_id)
        .then(response => {

          let guidanceTaskList = response.data.data

          context.commit("SET_GUIDANCE_TASK_LIST", guidanceTaskList);
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_STATUS", "loaded");
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_GUIDANCE_TASK_LIST", []);
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_STATUS", "failed");
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_GUIDANCE_TASK_LIST_LOADING_ERROR", 'retrieveGuidanceTaskList(): ' + error_message)
        });
    },

    retrieveGuidanceTaskTitles(context) {
      context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_TASK_TITLES)
        .then(response => {

          let guidanceTaskTitles = response.data.data

          context.commit("SET_GUIDANCE_TASK_TITLES", guidanceTaskTitles);
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_STATUS", "loaded");
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_ERROR_STATUS", false);
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_GUIDANCE_TASK_LIST", []);
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_STATUS", "failed");
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_GUIDANCE_TASK_TITLES_LOADING_ERROR", 'retrieveGuidanceTaskList(): ' + error_message)
        });
    },

    retrieveTaskSnapshotUrl(context, payload) {

      let axios_params = {
        headers: {},
        params: {}
      }
      let axios_url = process.env.VUE_APP_SCORECARD_SNAPSHOTS + '/' + payload.call_id + '?scorecard_id=' + payload.scorecard_id

      if(payload.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.call_id
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
        axios_params['params']['scorecard_id'] = payload.scorecard_id
        axios_params['params']['route'] = "snapshots"
      }


      context.commit("SET_SCORECARD_SNAPSHOT_LOADING_STATUS", "loading");
      axios
        .get(axios_url, axios_params)
        .then(response => {

          context.commit("SET_SCORECARD_SNAPSHOT", response.data);
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_STATUS", "loaded");
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_SCORECARD_SNAPSHOT", []);
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARD_SNAPSHOT_LOADING_ERROR", 'retrievetaskSnapshot(): ' + error_message)
        });
    },

    pushNewScorecardTask(context, payload) {

      let scorecardTaskList = context.getters.scorecardTaskList

      let task = payload.task
      task.task_id = payload.id
      task.last_task_status = 1
      task.new_task = false
      task.is_open = 1
      scorecardTaskList.push(task)
      context.commit("SET_SCORECARD_TASK_LIST", scorecardTaskList);
    },

    updateScorecardTask(context, payload) {
      let scorecardTaskList = context.getters.scorecardTaskList

      let indexOfObject = scorecardTaskList.findIndex(object => {
        return object.task_id == payload.task_id;
      });

      if (indexOfObject > -1) {
        let task = payload.task

        // overdue date is task due date plus 1 day
        let overdueDate = moment(task.due_date).add(1, 'days').format("YYYY-MM-DD")

        if (Date.parse(overdueDate) > Date.parse(moment(new Date()).format("YYYY-MM-DD"))) {
          task.overdue = 0
        } else {
          task.overdue = 1
        }
        scorecardTaskList.splice(indexOfObject, 1, task);
        context.commit("SET_SCORECARD_TASK_LIST", scorecardTaskList);

      }
    },

    updateScorecardTaskIsOpen(context, payload) {
      let scorecardTaskList = context.getters.scorecardTaskList

      let indexOfObject = scorecardTaskList.findIndex(object => {
        return object.task_id == payload.task_id;
      });

      if (indexOfObject > -1) {
        let task = scorecardTaskList[indexOfObject]
        task.is_open = payload.is_open
        scorecardTaskList.splice(indexOfObject, 1, task);
        context.commit("SET_SCORECARD_TASK_LIST", scorecardTaskList);
      }
    },

    deleteTaskFromScorecardList(context, task_id) {
      let scorecardTaskList = context.getters.scorecardTaskList

      let indexOfObject = scorecardTaskList.findIndex(task => {
        return task.task_id == task_id;
      });

      if (indexOfObject > -1) {
        scorecardTaskList.splice(indexOfObject, 1);
        context.commit("SET_SCORECARD_TASK_LIST", scorecardTaskList);
      }
    },

    deleteTaskFromGuidanceList(context, task_id) {
      let guidanceTaskList = context.getters.guidanceTaskList

      let indexOfObject = guidanceTaskList.findIndex(task => {
        return task.task_id == task_id;
      });

      if (indexOfObject > -1) {
        guidanceTaskList.splice(indexOfObject, 1);
        context.commit("SET_GUIDANCE_TASK_LIST", guidanceTaskList);
      }
    },

    updateGuidanceTaskReadReply(context, payload) {
      let guidanceTaskList = context.getters.guidanceTaskList
      let guidanceListIndex = guidanceTaskList.findIndex(object => {
        return object.task_id == payload.task_id;
      });

      if (guidanceListIndex > -1) {
        let task = guidanceTaskList[guidanceListIndex]
        task.last_reply_read_by_coach = payload.read_replies
        guidanceTaskList.splice(guidanceListIndex, 1, task);
        context.commit("SET_GUIDANCE_TASK_LIST", guidanceTaskList);
      }
    },

    updateTaskMessageInStore(context, payload) {
      context.commit("SET_TASK_MESSAGE_IN_STORE", payload);
    },

    updateRepliesRead(context, payload) {
      
      let task_url = process.env.VUE_APP_TASKS + payload.task_id
      return axios.put(task_url, payload)
        .then(response => {

          // splice existing lists
          context.commit("SET_SCORECARD_TASK_READ_REPLY", payload);
          context.commit("SET_GUIDANCE_TASK_READ_REPLY", payload);
          return response.data; // Return the response
        })
        .catch(error => {
          // Error
          return error.response.data.error
        })
    }

  }
}
