<template>
  <v-row v-if="callTranscriptLoadingStatus == 'loaded' && transcriptExists">
    <v-btn-toggle
      class="pl-4"
      group
      dense
      v-model="transcriptView"
      color="blue darken-1"
      @change="transcriptViewChange"
    >
      <v-btn small value="transcript">
        Transcript
      </v-btn>
      <v-btn v-show="summaryIsReady && !summaryIsLoading" small value="summary">
        Summary
      </v-btn>
    </v-btn-toggle>
    <v-spacer></v-spacer>
    <div class="pr-4">
      <v-btn
        v-show="
          userDataLoadingStatus == 'loaded' &&
            canGenerateCallSummaries &&
            !transcriptHasSummary &&
            !selfSigned
        "
        color="warning"
        small
        title="Summary Powered By OpenAI"
        :disabled="summaryIsLoading"
        :loading="summaryIsLoading"
        @click="generateCallSummary()"
      >
        <custom-icon
          name="open-ai-mark"
          style="margin-right: .5rem;"
        ></custom-icon>
        Get Call Summary
      </v-btn>
    </div>
  </v-row>
  <v-row v-else>&nbsp;</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import CustomIcon from "@/components/Icons/CustomIcon.vue";
import DemoHandler from "@/utils/DemoHandler";
import axios from "@/utils/AxiosInstance.js";

export default {
  components: {
    CustomIcon
  },
  data() {
    return {
      transcriptView: "transcript",
      summaryIsLoading: false,
      summaryIsReady: false,
      summaryData: {}
    };
  },
  props: {
    callId: {
      required: true,
      type: Number
    },
    selfSigned: {
      required: false,
      type: Boolean,
      default: false
    },
    mediaType: {
      required: false,
      type: String,
      default: 'Call Recording'
    }
  },
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    ...mapGetters("transcript", [
      "callTranscript",
      "callTranscriptLoadingStatus",
      "callTranscriptLoadingError",
      "callTranscriptLoadingErrorStatus"
    ]),
    transcriptExists() {
      return (
        this.callTranscript.analyses && this.callTranscript.analyses.length > 0
      );
    },
    canGenerateCallSummaries() {
      return (
        this.userDataLoadingStatus == "loaded" &&
        this.userData.settings.generateCallSummaries
      );
    },
    transcriptHasSummary() {
      return this.summaryData && this.summaryData.hasOwnProperty("summary");
    }
  },
  methods: {
    transcriptViewChange() {
      this.$emit("transcriptViewChange", this.transcriptView);
    },
    generateCallSummary() {
      this.summaryIsReady = false;
      this.summaryIsLoading = true;

      let ai_summary_url =
        process.env.VUE_APP_SUMMARIES +
        DemoHandler.determineDemoCall(this.callId, this.mediaType == 'Chat', this.selfSigned);
      let axios_params = { params: {} };

      axios
        .get(ai_summary_url, axios_params)
        .then(response => {
          if (response && response.data) {
            this.summaryData = response.data;
            this.summaryIsReady = true;
            this.transcriptView = "summary";
            this.transcriptViewChange();
          }
        })
        .finally(() => {
          this.summaryIsLoading = false;
          this.setSummaryData();
        });
    },
    setSummaryData() {
      this.$emit("summaryDataRetrieved", {
        data: this.summaryData,
        loading: this.summaryIsLoading,
        ready: this.summaryIsReady
      });
    }
  },
  destroyed() {
    this.summaryData = {};
    this.summaryIsLoading = false;
    this.summaryIsReady = false;
    this.setSummaryData();
  },
  mounted() {
    if (
      this.callTranscriptLoadingStatus == "loaded" &&
      this.callTranscript.hasOwnProperty("ai_summary")
    ) {
      this.summaryData = this.callTranscript.ai_summary;
      this.summaryIsLoading = false;
      this.summaryIsReady = true;
      this.setSummaryData();
    }
  },
  watch: {
    callTranscriptLoadingStatus: function(status) {
      if (
        status == "loaded" &&
        this.callTranscript.hasOwnProperty("ai_summary")
      ) {
        this.summaryData = this.callTranscript.ai_summary;
        this.summaryIsLoading = false;
        this.summaryIsReady = true;
        this.setSummaryData();
      }
    }
  }
};
</script>

<style scoped></style>
