import { store } from "@/store/index.js";

export default {
  determineDemoCall(callId, isChat, selfSigned) {
    
    if (store.getters["users/userDataLoadingStatus"] == "loaded") {
        let userData = store.getters["users/userData"];

        // if the url is self signed, we need to return the call id in order to get a valid reckey, authentication-service will do the rest...
        if (selfSigned) return callId
        
        //user data resources will always have the "accounts" property...
        if (userData.resources.accounts.includes(Number(process.env.VUE_APP_DEMO_ACCOUNT_ID))) {
          return (isChat) ? process.env.VUE_APP_DEMO_CHAT_ID : process.env.VUE_APP_DEMO_CALL_ID
        }

        // just return the call id, not a demo user and not self signed
        return callId;
    }
    //do not want to accidentally show a customer call...
    return 0
  },
  isDemoUser() {
    if (store.getters["users/userDataLoadingStatus"] == "loaded") {
      let userData = store.getters["users/userData"];
      return userData.resources.accounts.includes(Number(process.env.VUE_APP_DEMO_ACCOUNT_ID))
    }
    return true
  }
};
