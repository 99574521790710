<template>
  <div v-show="userDataListLoadingStatus === 'loaded'">
    <loading-status-box
      v-if="taskIsLoading"
      :message="dataLoadingMessage"
      :type="dataLoadingType"
      :messageStyle="'text-wrap: nowrap; font-size:1rem;'"
    ></loading-status-box>
    <div v-else>
    <guidance-task-header :task="task_local_copy" :userData="userData" :agentInfo="agentInfo"></guidance-task-header>
    <v-card-text class="my-2 py-2 scrollable-content" :style="TDSHStyle" ref="taskScrollableContent">
      <v-expansion-panels v-model="task_expansion_panels" multiple>
        <v-expansion-panel key="taskDetails">
          <v-expansion-panel-header class="expansion-panel-header-text">
            Task Details
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="my-0" v-show="canCreate && task_local_copy.is_open && !selfSigned">
              <v-col cols="12" class="py-0 dr-flex justify-center edit-bar">
                <v-btn-toggle small v-model="editing_task" borderless class="small-btn-toggle">
                  <v-btn small :value="false">
                    <span>View</span>
                    <v-icon small right class="mx-1">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn small :value="true">
                    <span>Edit</span>
                    <v-icon small right class="mx-1">mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <template v-if="editing_task && task_local_copy.is_open && !selfSigned" v-show="scorecardCoachingDefListLoadingStatus=='loaded'">
              <v-row class="mt-2">
                <v-col cols="12" class="pt-0">
                  <v-select
                    v-model="selected_guidance_def"
                    :items="scorecardCoachingDefList"
                    item-text="title"
                    item-value="id"
                    label="Select a guidance definition"
                    dense
                    hide-details
                    class="small-select"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6" class="pt-0">
                  <v-combobox v-model="selected_task_title" :items="sortedTaskTitles" item-text="task_title"
                              item-value="task_title" label="Select from list or type a new task name"
                              @change="updateTaskTitles" class="small-combobox mt-2">
                    <template v-slot:item="{ item }">
                      <v-hover v-slot:default="{ isHovering }">
                        <v-list-item :key="item.task_title" :class="{ 'hover-highlight': isHovering }">
                          <v-list-item-content>{{ item.task_title }}</v-list-item-content>
                        </v-list-item>
                      </v-hover>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-menu small v-model="date_menu" :nudge-left="40" transition="scale-transition" offset-y
                          min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="formattedDueDate" clearable label="Due Date (optional)" readonly
                                    v-bind="attrs" v-on="on" @click:clear="clearDueDate"
                                    class="small-text-field"></v-text-field>
                    </template>
                    <v-date-picker
                      small
                      dense
                      v-model="task_local_copy.due_date"
                      @change="updateDueDate"
                      :allowed-dates="allowedDates"
                      locale="en"
                      class="small-date-picker"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-1">
                <v-col cols="12" class="px-3 pt-0">
                  <text-editor :text2Edit="task_local_copy.task_message" injected-class="px-0 mt-0" placeholder-text="Type your task message, here" @input="updateTaskMessage" @keydown.stop></text-editor>
                </v-col>
              </v-row>
              <v-row class="mt-0" v-show="hasObservations">
                <v-col cols="12" class="pt-0">
                  <fieldset class="v-fieldset">
                    <legend class="v-legend">
                      <v-icon color="orange darken-1" small>mdi-creation</v-icon>
                      Observations
                    </legend>

                    <v-row class="mt-0 mb-2" v-for="observation in savedTranscriptObservationData" :key="observation.id">

                        <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                          <v-icon color="grey" small>mdi-message-text</v-icon>
                        </v-col>
                        <v-col v-show="callTranscriptLoadingStatus=='loaded'&&callTranscript.hasOwnProperty('analyses')" cols="2" class="dr-flex align-center px-0 py-0">
                          <span>{{ getOffsetMessage(observation.transcript_seg_id) }}</span>
                        </v-col>
                        <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                          Created by {{getUsersName(observation.user_id)}}
                          <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                        </v-col>
                        <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 6 : 7" class="dr-flex align-center px-0 py-0 pr-2">
                          <v-textarea
                            dense
                            outlined
                            hide-details
                            class="small-textarea mt-0 pa-0" rows="3"
                            v-model="localComments.savedTranscriptComment[observation.transcript_seg_id]"
                            @input="updateSavedTOC(observation.transcript_seg_id)"
                            background-color="grey lighten-5"
                            color="grey"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                          <v-btn icon color="grey lighten-1" @click="removeSavedTranscriptObservation(observation.transcript_seg_id)">
                            <v-icon small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>

                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in savedSubcategoryObservationData" :key="observation.subcategory_id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-order-bool-ascending-variant</v-icon>
                      </v-col>
                      <v-col v-show="scorecardSubcategoryXrefData.length > 0" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{getSubcategoryRow(observation.subcategory_id)}}</span>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 6 : 7" class="dr-flex align-center px-0 py-0 pr-2">
                        <v-textarea
                          dense
                          outlined
                          hide-details
                          class="small-textarea mt-0 pa-0" rows="3"
                          v-model="localComments.savedSubcategoryComment[observation.subcategory_id]"
                          @input="updateSavedSOC(observation.subcategory_id)"
                          background-color="grey lighten-5"
                          color="grey"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-btn icon color="grey lighten-1" @click="removeSavedSubcategoryObservation(observation.subcategory_id)">
                          <v-icon small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in pendingTranscriptObservationData" :key="observation.transcript_seg_id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-message-text</v-icon>
                      </v-col>
                      <v-col v-show="callTranscriptLoadingStatus=='loaded'&&callTranscript.hasOwnProperty('analyses')" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{ getOffsetMessage(observation.transcript_seg_id) }}</span>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 6 : 7" class="dr-flex align-center px-0 py-0 pr-2">
                        <v-textarea
                          dense
                          outlined
                          hide-details
                          class="small-textarea mt-0 pa-0" rows="3"
                          v-model="localComments.pendingTranscriptComment[observation.transcript_seg_id]"
                          @input="updatePendingTOC(observation.transcript_seg_id)"
                          background-color="grey lighten-5"
                          color="grey"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-btn icon color="grey lighten-1" @click="removePendingTranscriptObservation(observation.transcript_seg_id)">
                          <v-icon small>mdi-close-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in pendingSubcategoryObservationData" :key="observation.subcategory_id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-order-bool-ascending-variant</v-icon>
                      </v-col>
                      <v-col v-show="scorecardSubcategoryXrefData.length > 0" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{getSubcategoryRow(observation.subcategory_id)}}</span>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 6 : 7" class="dr-flex align-center px-0 py-0 pr-2">
                        <v-textarea
                          dense
                          outlined
                          hide-details
                          class="small-textarea mt-0 pa-0" rows="3"
                          v-model="localComments.pendingSubcategoryComment[observation.subcategory_id]"
                          @input="updatePendingSOC(observation.subcategory_id)"
                          background-color="grey lighten-5"
                          color="grey"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-btn icon color="grey lighten-1" @click="removePendingSubcategoryObservation(observation.subcategory_id)">
                          <v-icon small>mdi-close-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </fieldset>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12" class="pt-0">
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          icon
                          @click="triggerFileInput"
                          :disabled="attachmentCount > 4"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-paperclip-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>You can upload up to 5 attachments,<br/>with a maximum file size of 50 MB each.</span>
                    </v-tooltip>
                    <input
                      type="file"
                      ref="fileInput"
                      multiple
                      @change="handleFileSelection"
                      style="display: none;"
                    />
                    <span v-if="!hasTaskAttachments && !hasPendingAttachments" class="caption ml-1">No attachments</span>
                    <template v-else>
                      <v-chip
                        small
                        class="ml-1 mr-1"
                        title="Download attachment"
                        v-for="attachment in task_local_copy.attachments"
                        :key="attachment.id"
                        @click="handleAttachmentChipClick(attachment.id)"
                      >{{attachment.file_name}} ({{formatBytes(attachment.file_size)}})
                        <v-icon
                          small
                          class="ml-2"
                          @click="deleteAttachment(attachment.id)"
                          title="Delete attachment"
                          style="cursor: pointer;"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-chip>

                      <v-chip
                        small
                        class="ml-1 mr-1"
                        title="Attachment Pending Upload"
                        v-for="attachment in pending_attachment_uploads"
                        :key="attachment.name"
                      >{{attachment.name}} ({{formatBytes(attachment.size)}})
                        <v-icon
                          small
                          class="ml-2"
                          @click="removeAttachment(attachment.name)"
                          title="Remove Attachment"
                          style="cursor: pointer;"
                        >
                          mdi-close-circle
                        </v-icon>
                      </v-chip>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-else v-show="scorecardCoachingDefListLoadingStatus=='loaded'">
              <v-row class="mt-2">
                <v-col cols="12" class="pt-0">
                  <p><span class="task-label-class">Guidance Definition:</span>  {{ selected_guidance_def_name }}</p>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6" class="py-0">
                  <p><span class="task-label-class">Task Type:</span> {{ selected_task_title }}</p>
                </v-col>
                <v-col cols="6" class="py-0">
                  <p><span class="task-label-class">Due Date:</span>  {{ formattedDueDate }}</p>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0"><span class="task-label-class">Guidance:</span></v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" class="pt-0 ma-0">
                  <div class="pa-2 phbox" v-html="task_local_copy.task_message"></div>
                </v-col>
              </v-row>
              <v-row class="mt-0" v-show="hasObservations">
                <v-col cols="12" class="pt-0">
                  <fieldset class="v-fieldset">
                    <legend class="v-legend">
                      <v-icon color="orange darken-1" small>mdi-creation</v-icon>
                      Observations
                    </legend>


                    <v-row class="mt-0 mb-2" v-for="observation in savedTranscriptObservationData" :key="observation.id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-message-text</v-icon>
                      </v-col>
                      <v-col v-show="callTranscriptLoadingStatus=='loaded'&&callTranscript.hasOwnProperty('analyses')" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{ getOffsetMessage(observation.transcript_seg_id) }}</span>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 7 : 8" class="dr-flex align-center px-0 py-0 pr-4 phbox">
                        <div class="px-2 mr-2">{{observation.comment}}</div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in savedSubcategoryObservationData" :key="observation.id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-order-bool-ascending-variant</v-icon>
                      </v-col>
                      <v-col v-show="scorecardSubcategoryXrefData.length > 0" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{getSubcategoryRow(observation.subcategory_id)}}</span>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 7 : 8" class="dr-flex align-center px-0 py-0 pr-4 phbox">
                        <div class="px-2 mr-2">{{observation.comment}}</div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in pendingTranscriptObservationData" :key="observation.transcript_seg_id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-message-text</v-icon>
                      </v-col>
                      <v-col v-show="callTranscriptLoadingStatus=='loaded'&&callTranscript.hasOwnProperty('analyses')" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{ getOffsetMessage(observation.transcript_seg_id) }}</span>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(callTranscript.hasOwnProperty('analyses')) ? 7 : 8" class="dr-flex align-center px-0 py-0 pr-4 phbox">
                        <div class="px-2 mr-2">{{observation.comment}}</div>
                      </v-col>
                    </v-row>

                    <v-row class="mt-0 mb-2" v-for="observation in pendingSubcategoryObservationData" :key="observation.id">
                      <v-col cols="1" class="dr-flex justify-center align-center px-0 py-0">
                        <v-icon color="grey" small>mdi-order-bool-ascending-variant</v-icon>
                      </v-col>
                      <v-col v-show="scorecardSubcategoryXrefData.length > 0" cols="2" class="dr-flex align-center px-0 py-0">
                        <span>{{getSubcategoryRow(observation.subcategory_id)}}</span>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 2 : 3" class="dr-flex align-center px-0 py-0 caption pr-1">
                        Created by {{getUsersName(observation.user_id)}}
                        <template v-if="observation.last_updated_by"><br/>{{getUsersName(observation.last_updated_by)}}</template><br/>
                      </v-col>
                      <v-col :cols="(scorecardSubcategoryXrefData.length > 0) ? 7 : 8" class="dr-flex align-center px-0 py-0 pr-4 phbox">
                        <div class="px-2 mr-2">{{observation.comment}}</div>
                      </v-col>
                    </v-row>
                  </fieldset>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12" class="pt-0">
                  <div>
                    <v-icon small>mdi-paperclip</v-icon>
                    <span v-if="!hasTaskAttachments && !hasPendingAttachments" class="caption ml-1">No attachments</span>
                    <template v-else>

                      <v-chip
                        small
                        class="ml-1 mr-1"
                        title="download"
                        v-for="attachment in task_local_copy.attachments"
                        @click="handleAttachmentChipClick(attachment.id)"
                        :key="attachment.id">{{attachment.file_name}} ({{formatBytes(attachment.file_size)}})
                      </v-chip>

                      <v-chip
                        small
                        class="ml-1 mr-1"
                        title="download"
                        v-for="attachment in pending_attachment_uploads"
                        :key="attachment.name">{{attachment.name}} ({{formatBytes(attachment.size)}})
                      </v-chip>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel key="conversationDetails" v-show="!(checkProp(task_local_copy,'new_task', true))">
          <v-expansion-panel-header class="expansion-panel-header-text">
            Conversation Details
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-4">

            <v-timeline dense>

              <v-timeline-item v-show="checkProp(task_local_copy,'replies', '.length>0')" small left v-for="(reply, index) in task_local_copy.replies" :key="reply.id" color="light-blue darken-3">
                <v-card>
                  <v-card-title class="light-blue darken-3 py-1 mb-0">
                    <span v-if="!reply.agent_id" class="caption white--text">
                      {{ getUsersName(reply.user_id) }} &mdash; {{ formatDate(reply.updated_at, true) }}
                    </span>
                    <span v-else class="caption white--text">
                      {{ agentInfo.full_name }} &mdash; {{ formatDate(reply.updated_at, true) }}
                    </span>
                  </v-card-title>
                  <v-container>
                    <div v-if="index === task_local_copy.replies.length - 1 && !task_local_copy.is_open" v-html="reply.reply + '<p><em class=\'grey--text\'>No further replies. Task is closed.</em></p>'"/>
                    <div v-else v-html="reply.reply"></div>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item small left v-show="task_local_copy.task_id>0 && task_local_copy.is_open" color="light-blue darken-3">
                <v-card>
                  <v-card-title class="light-blue darken-3 py-1 mb-0">
                    <span v-if="!this.selfSigned" class="caption white--text">
                      {{ getUsersName(userData.id) }} &mdash; Now
                    </span>
                    <span v-else class="caption white--text">
                      {{ agentInfo.full_name }} &mdash; Now
                    </span>
                  </v-card-title>
                  <v-container>
                    <text-editor text2Edit="" placeholder-text="Type your reply, here" @input="updatePendingTaskReply"></text-editor>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item small left v-show="task_local_copy.task_id>0 && task_local_copy.replies.length==0 && !task_local_copy.is_open" color="light-blue darken-3">
                <p class="pt-2"><em class='grey--text'>No conversation replies—looks like we’ve reached the end!</em></p>
              </v-timeline-item>


            </v-timeline>

          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-card-text>
    <action-bar
      ref="actionBar"
      :task="task_local_copy"
      :saving="taskIsSaving"
      :notified_agent="Number(emailAgent)"
      :selfSigned="selfSigned"
      :agentInfo="agentInfo"
      @deleteTask="handleDeleteTask"
      @reopenTask="handleReopenTask"
      @closeTask="handleCloseTask"
      @cancelEdit="handleCancelEdit"
      @saveTask="doSaveOperations"
      @setEmailAgent="handleEmailAgent"
      @sendDispute="handleReply(DISPUTED)"
      @sendReply="handleReply(INPROGRESS)"
      @acknowledge="handleReply(ACKNOWLEDGED)"
    ></action-bar>
    <alert ref="alert"></alert>
  </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import taskIcon from "@/mixins/tasks/taskIcon";
import GuidanceTaskHeader from "@/components/Tasks/GuidanceTaskHeader.vue";
import TextEditor from "@/components/Tasks/TextEditor.vue";
import Alert from '@/components/Widgets/Alert.vue';
import ActionBar from "@/components/Tasks/ActionBar.vue";
import moment from "moment";
import LoadingStatusBox from '@/components/Widgets/LoadingStatusBox.vue';

// This is for the scripts
const DISPUTED = 4
const ACKNOWLEDGED = 3
const INPROGRESS = 2
const NEWTASK = 1

export default {
  components: {
    GuidanceTaskHeader,
    TextEditor,
    Alert,
    ActionBar,
    LoadingStatusBox,
  },
  mixins: [taskIcon],
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    },
    agentInfo: {
      type: Object,
      required: true,
    },
    TDSHStyle: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {

      // these are for the templates
      DISPUTED: 4,
      ACKNOWLEDGED: 3,
      INPROGRESS: 2,
      NEWTASK: 1,

      date_menu: false,
      selected_task_title: "",
      emailAgent: false,
      editing_task: false,
      task_expansion_panels: [0, 1],
      selected_guidance_def: null,
      task_local_copy: {},
      task_attachment_links_local_copy: [],
      pending_task_reply: "",
      pending_attachment_deletes: [], // list of attachment ids that need delete api calls
      pending_attachment_uploads: [],
      saving: false,
      localComments: {
        'savedTranscriptComment': {},
        'pendingTranscriptComment': {},
        'savedSubcategoryComment': {},
        'pendingSubcategoryComment': {},
      },
    }
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {

    ...mapActions("tasks", [
      "retrieveTaskAttachmentData",
      "deleteTaskAttachments",
      "removeTaskAttachment",
      "deleteTask",
      "reopenTask",
      "closeTask",
      "createNewTask",
      "updateTask",
      "uploadTaskAttachments",
      "postTaskReply",
      "postTaskStatus",
      "retrieveTaskData",
    ]),

    ...mapActions("scorecardTasks", [
      "pushNewScorecardTask",
      "updateScorecardTask",
      "updateScorecardTaskIsOpen",
      "deleteTaskFromScorecardList",
      "deleteTaskFromGuidanceList",
      "updateTaskMessageInStore",
      "updateTaskInTaskLists",
      "updateRepliesRead"

    ]),

    ...mapActions("observations", [
      "storeSavedTranscriptObservationData",
      "storeSavedSubcategoryObservationData",

      "storePendingTranscriptObservationData",
      "storePendingSubcategoryObservationData",

      "appendPendingTranscriptObservation",
      "updatePendingTranscriptObservationComment",
      "removePendingTranscriptObservation",
      "appendPendingSubcategoryObservation",
      "updatePendingSubcategoryObservationComment",
      "removePendingSubcategoryObservation",

      "updateSavedTranscriptObservationComment",
      "removeSavedTranscriptObservation",
      "updateSavedSubcategoryObservationComment",
      "removeSavedSubcategoryObservation",

      "resetPendingObservations",
    ]),

    allowedDates(date) {
      const selectedDate = moment(date, "YYYY-MM-DD");
      const today = moment().startOf("day")
      return selectedDate.isSameOrAfter(today, "day");
    },

    scrollToTaskBottom() {
      // Make sure the element is loaded before accessing
      this.$nextTick(() => {
        const el = this.$refs.taskScrollableContent
        if(this.task_local_copy.hasOwnProperty('replies') && this.task_local_copy.replies.length>0) {
          setTimeout(() => {
            el.scrollTop = el.scrollHeight
          }, 50)
        }
      })
    },

    getOffsetMessage(transcript_seg_id) {
      if(this.callTranscript.hasOwnProperty('analyses')) {
        const phrase = this.callTranscript.analyses[0].phrases.find(t => t._id == transcript_seg_id)
        if (phrase) {
          return '@ ' + this.formatHMS(phrase.starts_at)
        } else {
          return 'n/a'
        }
      }
      return 'n/a'
    },

    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },

    updatePendingTOC(transcript_seg_id) { this.updatePendingTranscriptObservationComment({ transcript_seg_id: transcript_seg_id, comment: this.localComments.pendingTranscriptComment[transcript_seg_id], }) },
    updateSavedTOC(transcript_seg_id) { this.updateSavedTranscriptObservationComment({ transcript_seg_id: transcript_seg_id, comment: this.localComments.savedTranscriptComment[transcript_seg_id], }) },
    updatePendingSOC(subcategory_id) { this.updatePendingSubcategoryObservationComment({ subcategory_id: subcategory_id, comment: this.localComments.pendingSubcategoryComment[subcategory_id], }) },
    updateSavedSOC(subcategory_id) { this.updateSavedSubcategoryObservationComment({ subcategory_id: subcategory_id, comment: this.localComments.savedSubcategoryComment[subcategory_id], }) },

    async handleDeleteTask() {
      this.saving = true
      const task_id = this.task_local_copy.task_id
      let payload = { 'task_id': task_id }
      const deleteResponse = await this.deleteTask(payload)
      if(deleteResponse.success) {
        if (!this.task_local_copy.general_task) {
          this.deleteTaskFromScorecardList(this.task_local_copy.task_id)
        } else {
          this.deleteTaskFromGuidanceList(this.task_local_copy.task_id)
        }
      }
      this.doRefreshTask({ action: 'delete_task', task_id: this.task_local_copy.task_id })

    },

    async handleReopenTask() {
      this.saving = true
      let payload = { 'task_id': this.task_local_copy.task_id, 'agent_id': this.task_local_copy.task_agent  }
      const reopenResponse = await this.reopenTask(payload)
      if(reopenResponse.success) {
        if (!this.task_local_copy.general_task) {
          this.updateScorecardTaskIsOpen({task_id: this.task_local_copy.task_id, is_open: 1, task: this.task_local_copy})
          // set new status
          payload.status = NEWTASK
          this.postTaskStatus(payload)
        }
      }
      this.doRefreshTask({ action: 'reopen_task', task_id: this.task_local_copy.task_id })

    },

    async handleCloseTask() {
      this.saving = true
      let payload = { 'task_id': this.task_local_copy.task_id, 'agent_id': this.task_local_copy.task_agent  }
      const closeResponse = await this.closeTask(payload)
      if(closeResponse.success) {
        if (!this.task_local_copy.general_task) {
          this.updateScorecardTaskIsOpen({task_id: this.task_local_copy.task_id, is_open: 0, task: this.task_local_copy})
        }
      }
      this.doRefreshTask({ action: 'close_task', task_id: this.task_local_copy.task_id })

    },

    handleCancelEdit() {
      this.saving = true
      this.doRefreshTask({ action: 'cancel_edits', task_id: this.task_local_copy.task_id })
    },

    handleEmailAgent(emailAgent) {
      this.emailAgent = emailAgent
    },

    validateTaskDefinition() {

      let isNotValid = false
      let messages = []

      // check guidance definition selection
      if(this.selected_guidance_def === "" || this.selected_guidance_def === 0 || this.selected_guidance_def === null) {
        isNotValid = true;
        messages.push("<p>A guidance definition is required. Please select one.</p>");
      }

      // check task_title
      if (this.checkProp(this.task_local_copy, 'task_title', '.trim() === ""')) {
        isNotValid = true;
        messages.push("<p>A task title is required, please type or select one.</p>");
      }

      // check task_message
      if (this.checkProp(this.task_local_copy, 'task_message', '.trim() === ""')) {
        isNotValid = true;
        messages.push("<p>A task message is required, please type one.</p>");
      }

      return { isNotValid: isNotValid, message: messages.join(" "), }

    },

    isEffectivelyEmpty(string) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = string;
      let textOnly = tempDiv.textContent || tempDiv.innerText || '';
      return textOnly.trim().length === 0;
    },

    async handleReply(status) {

      this.saving = true
      let payload = {}
      if(this.isEffectivelyEmpty(this.pending_task_reply)) {
        await this.$refs.alert.open(
          "Task Not Saved",
          'You must include a reply. Try again.',
          { color: "red", isHtml: false, }
        )
        this.saving = false
        return
      }

      payload = {
        task_id: this.task_local_copy.task_id,
        reply: this.pending_task_reply,
        selfSigned: this.selfSigned,
        reckey: this.$route.params.reckey,
        userid: this.$route.params.userid,
        callTask: this.task_local_copy.call_id ? true : false,
        agent_id: this.$route.params.agentid,
        call_id: this.task_local_copy.call_id,
        status: status
      }

      await this.postTaskReply(payload)
      await this.postTaskStatus(payload)

      this.doRefreshTask({ action: 'task_updated', task_id: this.task_local_copy.task_id })

    },

    async doSaveOperations() {

      this.saving = true
      const taskCheck = this.validateTaskDefinition()

      if(taskCheck.isNotValid) {
        let message = taskCheck.message
        await this.$refs.alert.open(
          "Task Not Saved",
          `${message}`,
          { color: "red", isHtml: true, }
        )
        this.saving = false
        return
      }

      if (this.task_local_copy.new_task) {
        // new tasks get created
        const payload = this.packNewTask(this.task_local_copy.general_task)
        const response = await this.createNewTask(payload)

        if(response.hasOwnProperty('success') && response.success) {
          const new_task_id = response.task_id
          this.$emit('setLastTaskId', new_task_id);

          //if not a general task, need to update the scorecard call log icon
          if (!this.task_local_copy.general_task) {
            payload.task_id = new_task_id
            this.pushNewScorecardTask({task: payload, id: new_task_id})
          }

          // do attachment deletes, if any
          if(this.pending_attachment_deletes.length>0) {
            this.deleteTaskAttachments(this.pending_attachment_deletes)
          }

          // do attachment uploads, if any
          if (this.pending_attachment_uploads.length > 0) {
            const formData = new FormData();
            formData.append("task_id", new_task_id); // Assuming `task_id` is the task identifier
            // Append each file separately to `attachments[]`
            this.pending_attachment_uploads.forEach((file) => {
              formData.append("attachments[]", file);
            });
            // Call the upload method
            const uploadResponse = await this.uploadTaskAttachments(formData);
            if(!uploadResponse.hasOwnProperty('success') && !uploadResponse.success) {
              await this.$refs.alert.open(
                "Attachment(s) Failed To Upload",
                `An error occurred while trying to upload your attachments. Please check your files and try again.<br/><br/>(${uploadResponse.status}) ${uploadResponse.message}`,
                { color: "red", isHtml: true, }
              )
              this.pending_attachment_uploads = []
              this.saving = false
              return
            }
          }

          this.doRefreshTask({ action: 'task_created', task_id: new_task_id})

        } else {
            await this.$refs.alert.open(
              "Task Not Saved",
              `An error occurred while trying to save this task. Please check your selections and try again.<br/><br/>(${response.status}) ${response.message}`,
              { color: "red", isHtml: true, }
            )
            this.saving = false
            return
        }

      } else {
        // updating a task is a little different

        // suppress email on reply
        const isReply = (this.pending_task_reply.trim()!=="")
        const payload = this.packUpdatedTask(isReply)
        let packedTask = payload
        const response = await this.updateTask(payload)

        if(response.success) {
          const task_id = response.task_id
          this.$emit('setLastTaskId', task_id);
          this.updateTaskMessageInStore({ task_id: task_id, task_message: payload.task_message } )

          // push the pending reply, if it exists
          if(isReply) {
            const payload = { "task_id": task_id, "reply": this.pending_task_reply }
            await this.postTaskReply(payload)
          }

          //if not a general task, need to update the scorecard call log icon
          if (!this.task_local_copy.general_task) {
            this.handleTaskChangesOnSave()
            let packedTaskLocal = this.task_local_copy
            packedTaskLocal.transcript_segments = packedTask.transcript_segments
            packedTaskLocal.subcategories = packedTask.subcategories
            await this.updateScorecardTask({task: packedTaskLocal, task_id: task_id})
          }

          // do attachment deletes, if any
          if(this.pending_attachment_deletes.length>0) {
            await this.deleteTaskAttachments(this.pending_attachment_deletes);
          }

          // do attachment uploads, if any
          if (this.pending_attachment_uploads.length > 0) {
            const formData = new FormData();
            formData.append("task_id", task_id); // Assuming `task_id` is the task identifier
            // Append each file separately to `attachments[]`
            this.pending_attachment_uploads.forEach((file) => {
              formData.append("attachments[]", file);
            });
            // Call the upload method
            const uploadResponse = await this.uploadTaskAttachments(formData);
            if (!uploadResponse.hasOwnProperty('success') && !uploadResponse.success) {
              await this.$refs.alert.open(
                "Attachment(s) Failed To Upload",
                `An error occurred while trying to upload your attachments. Please check your files and try again.<br/><br/>(${uploadResponse.status}) ${uploadResponse.message}`,
                {color: "red", isHtml: true,}
              )
              this.saving = false
              return
            }
          }

        } else {
          await this.$refs.alert.open(
            "Task Not Updated",
            `An error occurred while trying to update this task. Please check your selections and try again.<br/><br/>(${response.status}) ${response.message}`,
            { color: "red", isHtml: true, }
          )
          this.saving = false
          return
        }

        this.doRefreshTask({ action: 'task_updated', task_id: this.task_local_copy.task_id })

      }
    },
    handleTaskChangesOnSave() {
      //detect v-model task changes
      if (this.task_local_copy.notified_agent != this.emailAgent) {
        this.task_local_copy.notified_agent = this.emailAgent
      }

      if (this.task_local_copy.coaching_definition_id != this.selected_guidance_def) {
        this.task_local_copy.coaching_definition_id = this.selected_guidance_def
      }
    },
    packNewTask(general_task) {
      // todo: we don't need a reply in new tasks, needs a fix in coaching service
      if(general_task) {
        return {
          "coaching_definition_id": this.selected_guidance_def,
          "agent_id": this.task_local_copy.task_agent,
          "task_agent": this.task_local_copy.task_agent,
          "task_user": this.task_local_copy.task_user,
          "due_date": this.task_local_copy.due_date,
          "agent_can_ack_or_dispute": 1,
          "call_id": null,
          "scorecard_id": null,
          "task_title": this.task_local_copy.task_title,
          "transcript_segments": [],
          "subcategories": [],
          "replies": [],
          "task_message": this.task_local_copy.task_message,
          "notified_agent": Number(this.emailAgent),
        }
      } else {
        return {
          "coaching_definition_id": this.selected_guidance_def,
          "agent_id": this.task_local_copy.task_agent,
          "task_agent": this.task_local_copy.task_agent,
          "task_user": this.task_local_copy.task_user,
          "due_date": this.task_local_copy.due_date,
          "agent_can_ack_or_dispute": 1,
          "call_id": this.task_local_copy.call_id,
          "scorecard_id": this.task_local_copy.scorecard_id,
          "task_title": this.task_local_copy.task_title,
          "transcript_segments": this.pendingTranscriptObservationData.map(
            ({ comment, transcript_seg_id, user_id }) => ({ comment, transcript_seg_id, user_id })
          ),
          "subcategories": this.pendingSubcategoryObservationData.map(
            ({ comment, subcategory_id, user_id }) => ({ comment, subcategory_id, user_id } )
          ),
          "replies": [],
          "task_message": this.task_local_copy.task_message,
          "notified_agent": Number(this.emailAgent),
        }
      }

    },

    packUpdatedTask(suppress_email) {

      // start with the existing task
      let packedData = {}
      packedData['task_id'] = this.selfSigned ? this.$route.params.taskid : this.task_local_copy.task_id
      packedData['agent_id'] = this.task_local_copy.task_agent
      packedData['coaching_definition_id'] = this.selected_guidance_def
      packedData['due_date'] = this.task_local_copy.due_date
      packedData['agent_can_ack_or_dispute'] = this.task_local_copy.agent_can_ack_or_dispute
      packedData['task_title'] = this.task_local_copy.task_title
      packedData['is_open'] = this.task_local_copy.is_open
      packedData['notified_agent'] = Number(this.emailAgent)
      packedData['transcript_segments'] = []
      packedData['subcategories'] = []
      packedData['task_message'] = this.task_local_copy.task_message
      packedData['selfSigned'] = this.selfSigned
      packedData['reckey'] = this.$route.params.reckey
      packedData['userid'] = this.$route.params.userid
      packedData['callTask'] = !this.task_local_copy.general_task
      packedData['agent_task_id'] = this.$route.params.agentid
      packedData['suppress_email'] = suppress_email

      // pack in the saved ones (deleted ones are handled separately)
      this.savedTranscriptObservationData.forEach((item) => {
        const newItem = {
          id: item.id,
          transcript_seg_id: item.transcript_seg_id,
          comment: this.localComments.savedTranscriptComment[item.transcript_seg_id],
        };
        packedData.transcript_segments.push(newItem);
      });

      this.savedSubcategoryObservationData.forEach((item) => {
        const newItem = {
          id: item.id,
          subcategory_id: item.subcategory_id,
          comment: this.localComments.savedSubcategoryComment[item.subcategory_id],
        };
        packedData.subcategories.push(newItem);
      });


      // now pack in any pending ones - these don't have ids yet
      this.pendingTranscriptObservationData.forEach((item) => {
        const newItem = {
          transcript_seg_id: item.transcript_seg_id,
          comment: this.localComments.pendingTranscriptComment[item.transcript_seg_id],
          call_id: this.task_local_copy.call_id
        };
        packedData.transcript_segments.push(newItem);
      });

      this.pendingSubcategoryObservationData.forEach((item) => {
        const newItem = {
          subcategory_id: item.subcategory_id,
          comment: this.localComments.pendingSubcategoryComment[item.subcategory_id],
          call_id: this.task_local_copy.call_id
        };
        packedData.subcategories.push(newItem);
      });


      return packedData

    },

    triggerFileInput() {
      // Trigger the hidden file input
      this.$refs.fileInput.click();
    },

    async handleFileSelection(event) {
      // Add selected files to the list
      const files = Array.from(event.target.files);

      const grammar1 = (files.length>1) ? "Files were " : "File was "
      const grammar2 = (files.length>1) ? " smaller files." : " a smaller file."
      const grammar3 = (files.length>1) ? " files were" : "file was"
      const grammar4 = (files.length>1) ? " unsupported extensions:" : "an unsupported extension:"
      const grammar5 = (files.length>1) ? " files" : "file"

      const approvedExtensions = [
        'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx',
        'txt', 'rtf', 'csv', 'jpg', 'jpeg', 'png', 'gif'
      ];

      if ( this.attachmentCount + files.length > 5) {
        await this.$refs.alert.open(
          "File Count Exceeded",
          grammar1 + 'not attached. You may attach up to five to a task. Try again with fewer files.',
          { color: "red", isHtml: false, }
        )
        return;
      }

      if(files.some(file => file.size > 50000000 )) {
        await this.$refs.alert.open(
          "File Size Exceeded",
          grammar1 + 'not attached. Each must be 50MB or less. Try again with' + grammar2,
          { color: "red", isHtml: false, }
        )
        return;
      }

      const invalidFiles = files.filter(file => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return !approvedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length > 0) {
        const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
        await this.$refs.alert.open(
          "Unsupported File Extension",
          `The following ` + grammar3 + ` not attached due to ` + grammar4 + `<br/><b>${invalidFileNames}</b><br/><br/>` +
          `Allowed extensions are:
          ${approvedExtensions.join(', ')}.

          Try again with the correct ` + grammar5 + `.`,
          { color: "red", isHtml: true }
        );
        return;
      }

      this.pending_attachment_uploads = [...this.pending_attachment_uploads, ...files];
      // Clear the file input for subsequent selections
      event.target.value = null;
    },

    handleAttachmentChipClick(id) {
      const item = this.task_attachment_links_local_copy.find(item => item.id === id);
      if (item && item.link) {
        const link = document.createElement("a");
        link.href = item.link;
        link.download = ""; // Optional: specify a default filename
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
      } else {
        // TODO: Do we need to alert on this?
        console.error("Attachment link not found.");
      }
    },

    deleteAttachment(attachment_id) {
      this.pending_attachment_deletes.push(attachment_id);
      this.removeTaskAttachment(attachment_id)
      this.task_local_copy.attachments = this.task_local_copy.attachments.filter(
        (attachment) => attachment.id !== attachment_id
      )
    },

    removeAttachment(name) {
      this.pending_attachment_uploads = this.pending_attachment_uploads.filter(
        (attachment) => attachment.name !== name
      )
    },

    formatBytes(bytes) {
      if (bytes === 0) return "0B";
      const sizes = ["B", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const size = sizes[i] || "TB";
      const value = (bytes / Math.pow(1024, i)).toFixed(1);
      return `${value}${size}`;
    },

    formatDate(date, includeTime = false) {
      if (!date.endsWith('Z')) { date += 'Z' }
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      if (includeTime) { options.hour = 'numeric'; options.minute = '2-digit'; }
      return new Date(date).toLocaleDateString(undefined, options)
    },

    getUsersName(userid) {
      if(!userid) return 'User ID is not defined.'
      let user = this.userDataList.find(user => user.userid === userid);
      if(!user) return "User is not in scope."
      else return user.firstname + ' ' + user.lastname
    },

    getSubcategoryRow(subcategory_id) {
      if(this.scorecardSubcategoryXrefData.length>0) {
        return 'Row ' + this.scorecardSubcategoryXrefData.find(item => item.subcategory_id === subcategory_id).rowNumber
      } else {
        return 'n/a'
      }
    },

    updateTaskMessage(message) {
      this.task_local_copy.task_message = message
    },

    updatePendingTaskReply(message) {
      this.pending_task_reply = message
    },

    setEditTask(value) {
      this.editing_task = value
    },

    // date picker stuff
    clearDueDate() {
      this.task_local_copy.due_date = null;
    },
    updateDueDate(date) {
      this.task_local_copy.due_date = date;
    },

    // task name stuff
    updateTaskTitles(newTaskTitle) {
      if (newTaskTitle) {
        // if it's a new string, make an object out of it
        if (typeof newTaskTitle == 'string') newTaskTitle = {task_title: newTaskTitle}
        // Find the item in the list
        const existingTaskTitle = this.task_titles.find(
          (taskTitle) => taskTitle.task_title === newTaskTitle.task_title
        );
        if (!existingTaskTitle) {
          // If it doesn't exist, add it with an initial usage count of 1 and a random id - doesn't matter what the id is, it won't be used long-term
          this.task_titles.push(newTaskTitle);
        }
        this.selected_task_title = this.task_local_copy.task_title = newTaskTitle.task_title
      }
    },

    updateTaskAttachments() {
      this.$nextTick(() => {
        if (this.taskAttachmentsLoadingStatus=="loaded" && this.taskAttachments) {
          this.task_attachment_links_local_copy = this.taskAttachments.map((o) => { return {'id': o.id, 'link': o.link} } )
        }
      });
    },

    checkProp(root, leaf, condition) {
      if (root.hasOwnProperty(leaf)) {
        const value = root[leaf]
        if (typeof condition === 'boolean') {
          return value === condition;
        }
        if (typeof condition === 'string') {
          try {
            const evaluateCondition = new Function('value', `return value ${condition};`);
            return evaluateCondition(value);
          } catch (error) {
            console.error('Invalid condition:', condition, error);
            return false;
          }
        }
      }
      return false;
    },

    async doRefreshTask(params) {
      // we've updated the task in the backend, now reset the pending stuff
      this.resetPendingObservations()
      this.$refs.actionBar.loadingButton = null
      this.saving = false
      this.pending_task_reply = ""
      this.pending_attachment_deletes = []
      this.pending_attachment_uploads = []
      this.localComments = {
        'savedTranscriptComment': {},
        'pendingTranscriptComment': {},
        'savedSubcategoryComment': {},
        'pendingSubcategoryComment': {}
      }

      // next we need to pull the task back so it updates the UI
      if(params.task_id>0) {

        let payload = {
          task_id: params.task_id,
          selfSigned: this.selfSigned,
          reckey: this.$route.params.reckey,
          userid: this.$route.params.userid,
          callTask: this.task_local_copy.call_id ? true : false,
          agent_id: this.$route.params.agentid,
          call_id: this.task_local_copy.call_id,
          agent_task_id: this.$route.params.agentid
        }
        let results = null

        switch(params.action) {
          case 'task_created':
          case 'task_updated':
          case 'close_task':
          case 'reopen_task':
            results = await this.updateTaskInTaskLists(payload);
            if(results) this.taskSetup(results)
            break
          case 'delete_task':
            this.$emit('resetParent', params);
            break
          case 'cancel_edits':
            if(this.task_local_copy.task_id === -1) {
              this.selected_guidance_def = null
              this.selected_task_title = ""
              this.task_local_copy.task_message = ""
              this.clearDueDate()
            }
            break
        }

      }

    },
    taskSetup(task) {
      this.task_local_copy = task
      this.selected_guidance_def = this.task_local_copy.coaching_definition_id
      this.emailAgent = this.task_local_copy.notified_agent
      this.selected_task_title = this.task_local_copy.task_title
      // existing task - set things up
      if(!this.task_local_copy.new_task && this.checkProp(this.task_local_copy,'task_id', '> 0')) {
        this.retrieveTaskAttachmentData({ callTask: this.task_local_copy.call_id ? true : false, task_id: this.task_local_copy.task_id, selfSigned: this.selfSigned, call_id: this.task_local_copy.call_id, agent_id: this.task_local_copy.task_agent, userid: this.$route.params.userid, reckey: this.$route.params.reckey});
        this.task_local_copy.transcript_segments.forEach((item) => {
          const key = item.transcript_seg_id;
          const value = item.comment;
          if (!item.task_id) item.task_id = this.task_local_copy.task_id
          this.localComments.savedTranscriptComment[key] = value;
        })
        this.task_local_copy.subcategories.forEach((item) => {
          const key = item.subcategory_id;
          const value = item.comment;
          if (!item.task_id) item.task_id = this.task_local_copy.task_id
          this.localComments.savedSubcategoryComment[key] = value;
        })
        this.storeSavedTranscriptObservationData(this.task_local_copy.transcript_segments || [] )
        this.storeSavedSubcategoryObservationData(this.task_local_copy.subcategories || [] )
        this.storePendingTranscriptObservationData([])
        this.storePendingSubcategoryObservationData([])
      }
      if (this.task_local_copy.task_id < 0) {
        this.storePendingTranscriptObservationData([])
        this.storePendingSubcategoryObservationData([])
        this.storeSavedTranscriptObservationData([])
        this.storeSavedSubcategoryObservationData([])
      }

      // if it's a new (created from a stub) task, set edit mode on by default
      if(this.task_local_copy.new_task) {
        this.setEditTask(true)
      }
      // if this is a new (last_task_status == 1) task, and it's open (is_open == 1) and from a selfSigned link then put the task in-progress
      if(this.checkProp(this.task_local_copy,'last_task_status', '== ' + NEWTASK)
        && this.checkProp(this.task_local_copy,'is_open', '== 1')
        && this.selfSigned) {
        const payload = {
          task_id: this.task_local_copy.task_id,
          selfSigned: this.selfSigned,
          reckey: this.$route.params.reckey,
          userid: this.$route.params.userid,
          callTask: this.task_local_copy.call_id ? true : false,
          agent_id: this.$route.params.agentid,
          call_id: this.task_local_copy.call_id,
          status: INPROGRESS
        }
        this.postTaskStatus(payload)
      }

      if(this.checkProp(this.task_local_copy,'last_reply_read_by_coach', '== ' + 0)  && !this.selfSigned) {
        const payload = {
          task_id: this.task_local_copy.task_id,
          read_replies: 1,
        }
        
        this.updateRepliesRead(payload)
      }
    }

  },
  computed: {
    ...mapGetters("users", [
      "userDataList",
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "userDataListLoadingStatus"
    ]),
    ...mapGetters("tasks", [
      "taskAttachments",
      "taskAttachmentsLoadingStatus",
      "taskAttachmentsLoadingErrorStatus",
      "taskAttachmentsLoadingError",
      "taskData",
      "taskDataLoadingStatus",
      "taskDataLoadingError",
      "taskDataLoadingErrorStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "guidanceTaskList",
      "guidanceTaskListLoadingStatus",
      "guidanceTaskListLoadingError",
      "guidanceTaskListLoadingErrorStatus",
      "guidanceTaskTitles",
      "guidanceTaskTitlesLoadingStatus",
      "guidanceTaskTitlesLoadingError",
      "guidanceTaskTitlesLoadingErrorStatus",
    ]),
    ...mapGetters('coachingDefinitions', [
      'coachingDefinitionsList',
      'coachingDefinitionsListLoadingStatus',
      'coachingDefinitionsListLoadingError',
      'coachingDefinitionsListLoadingErrorStatus',
      'scorecardCoachingDefList',
      'scorecardCoachingDefListLoadingStatus'
    ]),
    ...mapGetters('observations', [
      "savedTranscriptObservationData",
      "pendingTranscriptObservationData",
      "savedSubcategoryObservationData",
      "pendingSubcategoryObservationData",
      "scorecardSubcategoryXrefData",
    ]),
    ...mapGetters('transcript', [
      "callTranscriptLoadingStatus",
      "callTranscript",
    ]),
    ...mapGetters("scorecardTasks", [
      "scorecardTaskList",
      "scorecardTaskListLoadingStatus",
      "scorecardTaskListLoadingError",
      "scorecardTaskListLoadingErrorStatus",
      "updatingTaskItemInPlace",
    ]),
    attachmentCount() {
      return this.taskAttachments.length + this.pending_attachment_uploads.length - this.pending_attachment_deletes.length
    },
    taskIsSaving() {
      return this.saving
    },
    taskIsLoading() {
      return (
        this.selfSigned
        || (this.$route.params.hasOwnProperty('taskid') && this.$route.params['taskid'] == this.task['task_id'])
      ) ? (this.taskDataLoadingStatus != 'loaded' || this.updatingTaskItemInPlace) : (this.scorecardTaskListLoadingStatus != 'loaded' || this.updatingTaskItemInPlace)
    },
    dataLoadingMessage() {
      if ((this.selfSigned && this.taskDataLoadingError) || (!this.selfSigned && this.scorecardTaskListLoadingError)) {
        return "There was an error loading task data..."
      }
      return "Task data loading, please wait...";
    },
    dataLoadingType() {
      if ((this.selfSigned && this.taskDataLoadingError) || (!this.selfSigned && this.scorecardTaskListLoadingError)) {
        return "error"
      }
      return "loading";
    },
    hasObservations() {
      return (this.hasSavedTranscriptSegments||this.hasPendingTranscriptSegments||this.hasSavedSubcategories||this.hasPendingSubcategories)
    },
    hasTaskAttachments() {
      return this.checkProp(this.task_local_copy,'attachments', '.length>0')
    },
    hasPendingAttachments() {
      return (this.pending_attachment_uploads.length>0)
    },
    hasSavedTranscriptSegments() {
      return this.checkProp(this.task_local_copy,'transcript_segments', '.length>0')
    },
    hasSavedSubcategories() {
      return this.checkProp(this.task_local_copy,'subcategories', '.length>0')
    },
    hasPendingTranscriptSegments() {
      return (this.pendingTranscriptObservationData.length>0)
    },
    hasPendingSubcategories() {
      return (this.pendingSubcategoryObservationData.length>0)
    },
    selected_guidance_def_name() {
      const foundDef = this.scorecardCoachingDefList.find(o => o.id === this.selected_guidance_def);
      return this.selfSigned
        ? this.task_local_copy.coaching_definition_title
        : (foundDef && foundDef.title) || this.task_local_copy.coaching_definition_title;
    },
    sortedTaskTitles() {
      // Sort items by usage count in descending order
      const taskTitleList = this.task_titles
      return taskTitleList.sort((a, b) => new Intl.Collator('en').compare(a.task_title, b.task_title))
    },
    formattedDueDate() {
      return this.task_local_copy.due_date ? moment(this.task_local_copy.due_date).format("ddd, MMM Do YYYY") : "";
    },
  },
  watch: {
    taskAttachments: "updateTaskAttachments",
    taskDataLoadingStatus: {
      immediate: true,
      deep: true,
      handler(status) {
        if (this.selfSigned && status=='loaded') {
          this.taskSetup(this.taskData)
        }
      }
    },
    task: {
      immediate: true,
      deep: true,
      handler(task) {
        this.taskSetup(this.task_local_copy = (!task) ? {...this.taskData} : {...task})
      }
    },
    pendingSubcategoryObservationData: {
      immediate: true,
      handler() {
        // someone added a subcategory observation so flip to edit mode
        this.setEditTask(true)
      },
    },
    pendingTranscriptObservationData: {
      immediate: true,
      handler() {
        // someone added a transcript observation so flip to edit mode
        this.setEditTask(true)
      },
    },
    guidanceTaskTitles: {
      immediate: true,
      deep: true,
      handler(taskTitles) {
        const originalTaskTitles = taskTitles.map(t => ({task_title: t.title}));

        const stockTaskTitles = [
          {task_title: "Call Review"},
          {task_title: "General Task"},
        ];

        stockTaskTitles.forEach(stock => {
          // Check if the task already exists in the originalTaskTitles
          const existingTask = originalTaskTitles.find(
            item => item.task_title === stock.task_title
          );

          if (!existingTask) {
            // Add the stock title
            originalTaskTitles.push({task_title: stock.task_title});
          }
        });

        // Update this.task_titles
        this.task_titles = originalTaskTitles;
      }
    },
    taskIsLoading: {
      immediate: true,
      handler(status) {
        if(!status) this.scrollToTaskBottom()
      },
    },
  },
}
</script>

<style scoped>

.task-header {
  background-color: rgba(1, 106, 175, 0.05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.dr-flex {
  display: flex;
  flex-direction: row;
}

.v-expansion-panel > .v-expansion-panel-header {
  min-height: 32px;
  background-color: rgba(1, 106, 175, .25);
}

.v-expansion-panel--active > .v-expansion-panel-header {
  background-color: rgba(1, 106, 175, .25);
}

.expansion-panel-header-text {
  color: rgba(1, 106, 175, 100);
}

.scrollable-content {
  overflow-y: auto !important; /* Enables vertical scrolling */
  padding-right: 16px !important; /* Adds padding for better readability when scrolling */
}

/* small select */
.small-select .v-input__control {
  font-size: .9rem; /* Smaller font size */
  padding: 4px 0px; /* Less padding for more compact look */
}

.small-select .v-select__selections {
  min-height: 30px; /* Reduce the height of the combobox */
}

.small-select .v-input__control .v-label {
  font-size: .9rem; /* Reduce font size of the label */
}

.small-select .v-list-item {
  font-size: .9rem; /* Reduce the font size of list items */
  padding-top: 4px;
  padding-bottom: 4px;
}

.small-select .v-list-item-content {
  font-size: .9rem; /* Reduce the font size of list item content */
}

/* small combobox */
.small-combobox .v-select__selections {
  min-height: 30px; /* Reduce the height of the combobox */
}

.small-combobox .v-input__control .v-label {
  font-size: .9rem; /* Reduce font size of the label */
}

.small-combobox .v-list-item {
  font-size: .9rem; /* Reduce the font size of list items */
  padding-top: 4px;
  padding-bottom: 4px;
}

.small-combobox .v-list-item-content {
  font-size: .9rem; /* Reduce the font size of list item content */
}

.small-text-field ::v-deep .v-input__control {
  font-size: .9rem; /* Smaller font size for the text field */
  padding: 4px 8px; /* Less padding for more compact look */
}

.small-text-field ::v-deep .v-label {
  font-size: .9rem; /* Smaller font for the label */
}

.small-text-field ::v-deep .v-input__control .v-input__prepend-outer {
  font-size: .9rem; /* Adjust icon size if necessary */
}

.small-textarea ::v-deep .v-input__control {
  font-size: 0.9rem; /* Smaller font size for textarea content */
  line-height: 1.2rem;
  padding: 0rem; /* Reduce padding for a compact look */
}

.small-textarea ::v-deep .v-label {
  font-size: 0.9rem; /* Smaller font size for the label */
}

.small-textarea ::v-deep textarea {
  font-size: 0.9rem; /* Font size for the text inside the textarea */
  line-height: 1.2rem;
  padding: 4px; /* Reduce padding inside the textarea */
  min-height: 3rem; /* Adjust minimum height if necessary */
}

.small-textarea ::v-deep .v-input--outlined .v-input__control {
  padding-top: 0; /* Adjust padding for outlined border */
  padding-bottom: 0;
}

.v-fieldset {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
}

.v-legend {
  font-weight: normal;
  padding: 0 0.25rem;
  color: #333;
  font-size: 0.75rem;
}

.edit-bar {
  background-color: rgba(1, 106, 175, .05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.v-timeline-item .v-card:hover {
  background-color: transparent !important;
  cursor: default !important; /* Ensure the cursor doesn't change */
}

.task-label-class {
  font-weight: 700;
  color: rgba(0,0,0,.33) !important;
}

.phbox {
  background-color: rgba(230, 230, 230, .2);
  border-radius: 8px;
}


</style>
