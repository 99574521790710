import axios from "@/utils/AxiosInstance.js"

export const tasks = {
  namespaced: true,
  state: {

    taskData: [],
    taskDataLoadingStatus: "notLoading",
    taskDataLoadingErrorStatus: false,
    taskDataLoadingError: "",

    taskAttachments: [],
    taskAttachmentsLoadingStatus: "notLoading",
    taskAttachmentsLoadingErrorStatus: false,
    taskAttachmentsLoadingError: "",

  },
  mutations: {

    SET_TASK_DATA_LOADING_ERROR_STATUS(state, status) {
      state.taskDataLoadingErrorStatus = status
    },
    SET_TASK_DATA_LOADING_ERROR(state, error) {
      state.taskDataLoadingError = error
    },
    SET_TASK_DATA_LOADING_STATUS(state, status) {
      state.taskDataLoadingStatus = status
    },
    SET_TASK_DATA(state, taskData) {
      state.taskData = taskData
    },

    SET_TASK_ATTACHMENTS_LOADING_ERROR_STATUS(state, status) {
      state.taskAttachmentsLoadingErrorStatus = status
    },
    SET_TASK_ATTACHMENTS_LOADING_ERROR(state, error) {
      state.taskAttachmentsLoadingError = error
    },
    SET_TASK_ATTACHMENTS_LOADING_STATUS(state, status) {
      state.taskAttachmentsLoadingStatus = status
    },
    SET_TASK_ATTACHMENTS(state, taskAttachments) {
      state.taskAttachments = taskAttachments
    },

    REMOVE_TASK_ATTACHMENT(state, attachment_id) {
      state.taskAttachments = state.taskAttachments.filter(
        attachment => attachment.id !== attachment_id
      )
    }

  },
  getters: {
    hits: state => {
      return state.transcriptHits;
    },

    taskData: state => state.taskData,
    taskDataLoadingStatus: state => state.taskDataLoadingStatus,
    taskDataLoadingErrorStatus: state => state.taskDataLoadingErrorStatus,
    taskDataLoadingError: state => state.taskDataLoadingError,

    taskAttachments: state => state.taskAttachments,
    taskAttachmentsLoadingStatus: state => state.taskAttachmentsLoadingStatus,
    taskAttachmentsLoadingErrorStatus: state => state.taskAttachmentsLoadingErrorStatus,
    taskAttachmentsLoadingError: state => state.taskAttachmentsLoadingError,

  },
  actions: {

    // agents can do this, thus the additional selfSigned handling
    async retrieveTaskData(context, payload) {
      let axios_params = {params:{}}
      let task_url = process.env.VUE_APP_TASKS + payload.task_id

      if(payload.selfSigned) {
        task_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.task_id
        axios_params['params']['route'] = 'tasks'
        if (payload.callTask) {
          axios_params['params']['call_id'] = payload.call_id
        } else {
          axios_params['params']['agent_id_for_reckey'] = payload.agent_id
        }
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}

        // agent reckey authorization parameters
        axios_params['params']['agent_task_id'] = payload.agent_task_id
      }

      context.commit("SET_TASK_DATA_LOADING_STATUS", "loading");
      return await axios
        .get(task_url, axios_params)
        .then(response => {

          context.commit("SET_TASK_DATA", response.data.data);
          context.commit("SET_TASK_DATA_LOADING_STATUS", "loaded");
          context.commit("SET_TASK_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_TASK_DATA_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_TASK_DATA", []);
          context.commit("SET_TASK_DATA_LOADING_STATUS", "failed");
          context.commit("SET_TASK_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_TASK_DATA_LOADING_ERROR", 'retrieveTaskData(): ' + error_message)
        });
    },

    // agents can do this, thus the additional selfSigned handling
    async retrieveTaskAttachmentData(context, payload) {
      let axios_params = {params:{}}
      let attachment_url = process.env.VUE_APP_TASK_ATTACHMENTS + payload.task_id

      if(payload.selfSigned) {
        attachment_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.task_id
        axios_params['params']['route'] = 'task_attachments'
        if (payload.callTask) {
          axios_params['params']['call_id'] = payload.call_id
        } else {
          axios_params['params']['agent_id_for_reckey'] = payload.agent_id
        }
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}

      }

      context.commit("SET_TASK_ATTACHMENTS_LOADING_STATUS", "loading");
      await axios
        .get(attachment_url, axios_params)
        .then(response => {
          context.commit("SET_TASK_ATTACHMENTS", response.data);
          context.commit("SET_TASK_ATTACHMENTS_LOADING_STATUS", "loaded");
          context.commit("SET_TASK_ATTACHMENTS_LOADING_ERROR_STATUS", false);
          context.commit("SET_TASK_ATTACHMENTS_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_TASK_ATTACHMENTS", []);
          context.commit("SET_TASK_ATTACHMENTS_LOADING_STATUS", "failed");
          context.commit("SET_TASK_ATTACHMENTS_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_TASK_ATTACHMENTS_LOADING_ERROR", 'retrieveTaskData(): ' + error_message)
        });
    },

    async deleteTask(context, payload) {
      let task_url = process.env.VUE_APP_TASKS + payload.task_id
      return await axios.delete(task_url)
        .then(response => {
          return response.data; // Return the response
        })
    },

    async reopenTask(context, payload) {
      const closeProps = { "state_update": true, "is_open": 1 }
      let task_url = process.env.VUE_APP_TASKS + payload.task_id

      return await axios.put(task_url, closeProps)
        .then(response => {
          return response.data; // Return the response
        })
        .catch(error => {
          // Error
          console.error(error)
        })
    },

    removeTaskAttachment(context, attachment_id) {
      context.commit("REMOVE_TASK_ATTACHMENT", attachment_id);
    },

    async deleteTaskAttachments(context, attachment_ids) {
      let attachment_url = process.env.VUE_APP_TASK_ATTACHMENTS + '?'
      let amp = ''
      attachment_ids.forEach(attachment_id => {
        attachment_url += amp + 'attachment_ids[]=' + attachment_id
        amp = '&'
      })
      return await axios.delete(attachment_url)
    },

    async uploadTaskAttachments(context, payload) {
      let attachment_url = process.env.VUE_APP_TASK_ATTACHMENTS

      return await axios.post(attachment_url, payload, {headers: { "Content-Type": "multipart/form-data" }})
        .then( response => {
          return response.data
        })
        .catch(error => {
          // Error
          return error.response.data.error
        })
    },

    // agents can do this, thus the additional selfSigned handling
    async postTaskReply(context, payload) {
      let task_url = process.env.VUE_APP_TASK_REPLIES
      let axios_params = {params:{}}

      if(payload.selfSigned) {
        task_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['params']['route'] = 'task_replies'
        if (payload.callTask) {
          axios_params['params']['call_id'] = payload.call_id
        } else {
          axios_params['params']['agent_id_for_reckey'] = payload.agent_id
        }
        axios_params['params']['reply'] = payload.reply
        axios_params['params']['task_id'] = payload.task_id
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
        axios_params['params']['agent_task_id'] = payload.agent_id

      } else {
        axios_params['params']['reply'] = payload.reply
        axios_params['params']['task_id'] = payload.task_id
      }

      try {
        const response = await axios.post(task_url, axios_params.params, {
          headers: axios_params.headers
        });
        return response.data;
      } catch (error) {
        console.error('postTaskReply ' + error);
      }

    },

    // agents can do this, thus the additional selfSigned handling
    async postTaskStatus(context, payload) {
      let task_url = process.env.VUE_APP_TASK_STATUSES
      let axios_params = {params:{}}

      if(payload.selfSigned) {
        task_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['params']['route'] = 'task_statuses'
        if (payload.callTask) {
          axios_params['params']['call_id'] = payload.call_id
        } else {
          axios_params['params']['agent_id_for_reckey'] = payload.agent_id
        }
        axios_params['params']['status'] = payload.status
        axios_params['params']['task_id'] = payload.task_id
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
        axios_params['params']['agent_task_id'] = payload.agent_id
      } else {
        axios_params['params']['status'] = payload.status
        axios_params['params']['task_id'] = payload.task_id
      }

      try {
        const response = await axios.post(task_url, axios_params.params, {
          headers: axios_params.headers
        });
        return response.data;
      } catch (error) {
        console.error('postTaskStatus ' + error);
      }

    },

    async closeTask(context, payload) {
      const closeProps = { "state_update": true, "is_open": 0}
      let task_url = process.env.VUE_APP_TASKS + payload.task_id

      return await axios.put(task_url, closeProps)
        .then(response => {
          return response.data; // Return the response
        })
        .catch(error => {
          // Error
          console.error(error)
        })
    },

    async createNewTask(context, payload) {
      let task_url = process.env.VUE_APP_TASKS
      return await axios.post(task_url, payload)
        .then(response => {
          return response.data; // Return the response
        })
        .catch(error => {
          // Error
          return error.response.data.error
        })
    },

    async updateTask(context, payload) {
      let task_url = process.env.VUE_APP_TASKS + payload.task_id
      return await axios.put(task_url, payload)
        .then(response => {
          return response.data; // Return the response
        })
        .catch(error => {
          // Error
          return error.response.data.error
        })
    },

   

  }
};
