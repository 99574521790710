<template>
  <div :style="customStyle" v-if="recordingPresent">
    <v-row dense>
      <v-alert v-if="errormessage" type="error" class="alert-message">
        {{ errormessage }}
      </v-alert>
      <div v-else :id="id" class="audio-waveform">
      </div>
    </v-row>
    <v-row dense>
      <v-col v-if="percentloaded >= 100" cols="6" class="primary text-center">
        <v-icon dark @click="skipBackward" title="Skip Backward 10 seconds" class="mr-2">mdi-rewind-10</v-icon>
        <v-icon dark @click="playpause" :title=" this.paused ? 'Play Audio' : 'Pause Playback'">{{ getIcon() }}</v-icon>
        <v-icon dark @click="skipForward" title="Skip Forward 10 seconds" class="ml-2">mdi-fast-forward-10</v-icon>

        <v-icon dark @click="updatePlaybackRate(.6)" title="60%" :color=" (this.pbr!=.6) ? 'blue lighten-2' : 'white'" class="ml-8">mdi-tortoise</v-icon>
        <v-icon dark @click="updatePlaybackRate(1)" title="100%" :color=" (this.pbr!=1) ? 'blue lighten-2' : 'white'">mdi-chevron-right</v-icon>
        <v-icon dark @click="updatePlaybackRate(1.4)" title="140%" :color=" (this.pbr!=1.4) ? 'blue lighten-2' : 'white'">mdi-chevron-double-right</v-icon>
        <v-icon dark @click="updatePlaybackRate(1.8)" title="180%" :color=" (this.pbr!=1.8) ? 'blue lighten-2' : 'white'">mdi-chevron-triple-right</v-icon>

        <span class="white--text ml-4" v-if="this.zoom>10">{{this.zoom - 10}}x</span><span class="white--text ml-6" v-else>Normal</span>
        <v-icon dark @click="zoomOut" title="Zoom out" class="ml-2">mdi-magnify-minus-outline</v-icon>
        <v-icon dark @click="zoomReset" title="Reset zoom" class="ml-2">mdi-magnify-remove-outline</v-icon>
        <v-icon dark @click="zoomIn" title="Zoom in" class="ml-2">mdi-magnify-plus-outline</v-icon>
      </v-col>
      <v-col v-if="percentloaded >= 100" cols="3" class="primary text-center">
        <span class="white--text">Current Time: {{ currenttime }}</span>
      </v-col>
      <v-col v-if="percentloaded >= 100" cols="3" class="primary text-center">
        <span class="white--text">Talk Time: {{ talktime }}</span>
      </v-col>
      <v-col v-if="percentloaded < 100" cols="12" class="primary text-center">
        <span class="white--text">loading audio: {{ percentloaded }}%</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "@/utils/AxiosInstance.js";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions"
import moment from "moment";
import DemoHandler from "../utils/DemoHandler";
import CacheHandler from "@/utils/CacheHandler";

export default {
  name: "audio-waveform",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    WaveSurfer
  },
  props: {
    showAudio: {
      type: Boolean,
    },
    callId: {
      type: String,
      required: true
    },
    displayedRegions: {
      type: Array,
      default: () => [],
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    playFromPosition: {
      type: Number,
      default: 0
    },
    setPosition: {
      type: Number,
      default: 0
    },
    offset: {
      type: Number,
      default: null
    },
    transcriptBtnCounter: {
      type: Number,
      default: null
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalView: {
      type: Boolean,
      required: false,
      default: false
    },
    overlayPresent: {
      type: Boolean,
      required: false,
      default: false
    },
    transcriptDisplayedRegions: {
      type: Array,
      required: false,
      default() {
          return []
      },
    },
    customStyle: {
      type: String,
      required: false,
      default: ''
    },

  },
  data() {
    return {
      id: null,
      wavesurfer: null,
      st: null,
      buffer: null,
      filter: null,
      length: null,
      seekingPos: null,
      seekingDiff: null,
      audiourl: "",
      loadingaudio: false,
      percentloaded: 0,
      errormessage: "",
      pbr: 1,
      paused: true,
      talktime: "00:00:00",
      currenttime: "00:00:00",
      zoom: 10, // pixel per second,
      isLoaded: false,
      recordingPresent: true,
      callDataId: this.callId,
      channelCount: 2,
    }
  },
  created() {

    // TODO: We need to make the player only pay attention to the spacebar when the player component is in focus
    // window.addEventListener('keypress', this.spacePlayPause);

    if (this.callId) {
      // get the demo call if if the user is tied to a demo account
      this.callDataId = DemoHandler.determineDemoCall(this.callId, false, this.selfSignedTranscript);
      let self = this;

      this.loadingaudio = true;
      let call_streams_url = process.env.VUE_APP_CALL_STREAMS + this.callDataId;
      let axios_params = {
        params: {}
      }

      if(this.selfSignedTranscript) {
        //self signed urls use the regular call id because that call id has the reckey generated...
        call_streams_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
        axios_params['params']['route'] = 'call-streams'
      }

      axios
        .get(call_streams_url,axios_params)
        .then(response => {
          // we have the recording
          self.loadingaudio = false
          self.audiourl = response.data.url
          self.wavesurfer.load(self.audiourl)
        })
        .catch(error => {
          self.loadingaudio = false
          if(this.selfSignedTranscript && this.safeGet(error,'response.data.error.message') && error.response.data.error.message == 'Invalid reckey') {
            // either the reckey was expired or invalid. either way just display that the transcript link expired
            self.errormessage =
              "The transcript link has expired. Please request a new link or contact support for assistance."
          } else if (error.response && error.response.status == 404) {
            // no recording - just hide waveform
            self.recordingPresent = false
          } else {
            // generic error (excluding 404s, in which case we will just hide the whole waveform)
            self.errormessage =
              "There was an issue accessing the recording. Please try again or contact support for assistance."
          }
          this.$emit("audioHasError", true);
        });
      this.id = "wsplayer-" + this._uid;
    }
    this.$root.$on("KILL_AUDIO", this.stop);
  },
  destroyed() {
    window.removeEventListener('keypress', this.spacePlayPause);
    if(!this.paused) {
      this.playpause()
    }
  },
  mounted() {

    this.pbr = CacheHandler.getItem('playback_rate') || 1

    this.callDataId = DemoHandler.determineDemoCall(this.callId, false, this.selfSignedTranscript);

    // channel count rigmarole
    let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callDataId
    let axios_params = {
      params: {
        fields:
          "callin.channel_count"
      }
    }

    if(this.selfSignedTranscript) {
      //self signed urls use the regular call id because that call id has the reckey generated...
      call_records_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
      axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
      axios_params['params']['route'] = 'call-records'
    }

    axios.get(call_records_url, axios_params)
      .then(response => {
        if (response && response.data) {
          this.channelCount = Number(response.data.callin.channel_count);
          if(this.channelCount==1) this.setPlayerHeight(120)
        }
      })


    // wait a tick on updating the dom
    this.$nextTick(() => {
      // create the audio object
      this.wavesurfer = WaveSurfer.create({
        backend: 'MediaElement',
        container: "#" + this.id,
        waveColor: "rgba(250,250,255,.25)",
        progressColor: "rgba(255,255,255,1)",
        cursorColor: "rgb(51,51,51)",
        backgroundColor: "rgba(1,87,155,1)",
        responsive: true,
        splitChannels: true,
        barHeight: .9,
        height: 60,
        plugins: [
          RegionsPlugin.create({
            'dragSelection': false,
          })
        ]
      });

      this.wavesurfer.on("ready", () => {

        this.updatePlaybackRate(this.pbr)
        this.talktime = this.formatHMS(this.wavesurfer.getDuration())
        this.length = this.wavesurfer.backend.length;
        this.seekingPos = this.playHeadPosition;
        this.seekingDiff = 0;

        this.wavesurfer.zoom(this.zoom);

        this.updateDisplayedRegions(this.displayedRegions)

      });

      this.wavesurfer.on('play', () => {
        this.seekingPos = ~~(this.wavesurfer.backend.getPlayedPercents() * this.length);
      });

      this.wavesurfer.on('seek', () => {
        this.seekingPos = ~~(this.wavesurfer.backend.getPlayedPercents() * this.length);
      });

      this.wavesurfer.on("loading", progress => {
        this.percentloaded = progress;
      });

      this.wavesurfer.on("error", event => {
        this.errormessage += "unable to load audio: " + event;
      });

      // update the current time property
      this.wavesurfer.on("audioprocess", position => {

        // if position is >= audio length, flip the pause button
        if (position >= this.wavesurfer.backend.getDuration())  this.paused = true;

        this.updateCurrentTime(position);
        this.$emit('update-play-head-position', position);
      });
      this.wavesurfer.on("seek", offset => {
        let position = offset * this.wavesurfer.getDuration();
        this.$emit('update-play-head-position', position);
        this.updateCurrentTime(position);
      });

    });

    this.isLoaded = true;

  },
  methods: {
    setPlayerHeight(y) {
      this.wavesurfer.setHeight(y);
    },
    spacePlayPause(e) {

      // if in call scorecards, only play the call id that matches the hash
      if(this.$route.name != 'scorecards-v2-calls' || this.$route.hash == "#call-scorecard-" + this.callDataId) {
        let cmd = String.fromCharCode(e.keyCode).toLowerCase();
        if(cmd == " " && e.target.tagName.toUpperCase() !== 'INPUT') {
          // if the audio player is in the modal or in the main view
          if(this.modalView && e.target.tagName.toUpperCase() === 'DIV') {
            this.playpause()
            e.preventDefault();
          } else if(!this.modalView && e.target.tagName.toUpperCase() === 'BODY') {
            this.playpause()
            e.preventDefault();
          } else if(!this.paused && !this.overlayPresent) {
            // if they switch views and the player is still playing when they hit space it will turn off
            // except if there is an overlay present
            this.playpause()
            e.preventDefault();
          }
        }
      }
    },
    safeGet(variable, fieldToGet) {
      // get a subobject field or return false if it doesnt exist without crashing anything
      let fieldArray = fieldToGet.split('.');
      let rtn_val = ""
      for(let i = 0; i < fieldArray.length; i++) {
          if (variable[fieldArray[i]] == undefined) {
              return false
          } else {
              rtn_val = variable[fieldArray[i]]
              variable = variable[fieldArray[i]]
          }
      }
      return rtn_val
    },
    zoomIn() {
      this.zoom = (this.zoom<110) ? ((this.zoom!=1) ? (this.zoom += 10) : 10) : 110
    },
    zoomOut() {
      this.zoom = (this.zoom>10) ? (this.zoom -= 10) : 10
    },
    zoomReset() {
      this.zoom = 10
    },
    playpause() {
      this.paused = !this.paused;
      this.wavesurfer.playPause();
    },
    stop() {
      this.wavesurfer.stop();
      this.paused = true;
    },
    getIcon() {
      if(this.paused) return 'mdi-play'
      else return 'mdi-pause'
    },
    skipForward() {
      this.wavesurfer.skipForward(10);
      if(this.paused) {
        this.updateCurrentTime(this.wavesurfer.getCurrentTime());
        this.$emit('update-play-head-position', this.wavesurfer.getCurrentTime());
      }
    },
    skipBackward() {
      this.wavesurfer.skipBackward(10);
    },
    updateCurrentTime(position) {
      this.currenttime = this.formatHMS(position)
    },
    updatePlaybackRate(r) {
      this.pbr = r
      CacheHandler.setItem('playback_rate', r)
      this.wavesurfer.setPlaybackRate(this.pbr)
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
    },
    setOffset(v) {
      this.wavesurfer.seekTo(v / this.wavesurfer.getDuration());
      this.playpause();
    },
    updateTranscriptRegions(v) {
      if(this.wavesurfer != null) {
        this.wavesurfer.regions.clear();
        const duration = this.wavesurfer.getDuration();
        v.forEach( r => {
          let start = Number(r.offset) - 1
          let end = Number(r.offset) + 1
          this.wavesurfer.regions.add({
            start: (start<0) ? 0 : start,
            end: (end>duration) ? duration : end,
            loop: false,
            drag: false,
            resize: false,
            color: 'rgba(255, 255, 0, 0.2)',
            attributes: {
              label: r.title
            }
          })
        });
        if (v.length > 0) {
          var lastOffsetSeek = (v[v.length - 1].offset / this.wavesurfer.getDuration());
          if(lastOffsetSeek > 0 && lastOffsetSeek < 1) {
            this.wavesurfer.seekAndCenter(lastOffsetSeek);
          }
        }

        // update the title of the region using wavesurfer:
        if(this.wavesurfer.regions) {
          for (var region in this.wavesurfer.regions.list) {
            // make sure nothing is going to crash.
            if(this.wavesurfer.regions.list[region] && this.wavesurfer.regions.list[region].element && this.wavesurfer.regions.list[region].attributes) {
              this.wavesurfer.regions.list[region].element.title = this.wavesurfer.regions.list[region].attributes.label + "\n" + this.wavesurfer.regions.list[region].element.title
            }
          }
        }
      }
    },
    updateDisplayedRegions(v) {
      if(this.wavesurfer != null) {
        //console.log('displayedRegions was updated',v)
        // clear all regions (would be nice if there was a discrete delete)
        this.wavesurfer.regions.clear();
        const duration = this.wavesurfer.getDuration();
        v.forEach( r => {
          r.offsets.forEach( o => {
            let start = Number(o.offset) - 1
            let end = Number(o.offset) + 1

            this.wavesurfer.regions.add({
              'start': (start<0) ? 0 : start,
              'end': (end>duration) ? duration : end,
              'loop': false,
              'drag': false,
              'resize': false,
              'color': (r.isCategory ? 'rgba(226, 127, 32, 0.5)' : 'rgba(255, 255, 0, 0.2)'),
            })
          })
        });
        // navigate to last added offset
        if (v.length > 0) {
          var lastOffsetSeek = (v[v.length - 1].offsets[0].offset / this.wavesurfer.getDuration());
          this.wavesurfer.seekAndCenter(lastOffsetSeek);
        }
      }
    }
  },
  watch: {
    zoom: function(val) {
      this.wavesurfer.zoom(val);
    },
    displayedRegions: function(v) {
      this.updateDisplayedRegions(v)

      this.$nextTick(() => {
        // have to wait for the wavesurfer to be ready before trying to add in the regions
        if(this.wavesurfer && v.length > 0) {
          // if modal we need to wait for the wavesurfer to be ready
          this.wavesurfer.on("ready", () => {
            this.updateDisplayedRegions(v)
          })
        } else {
          this.updateDisplayedRegions(v)
        }
      })

    },
    transcriptDisplayedRegions: function(v) {
      this.updateTranscriptRegions(v)

      this.$nextTick(() => {
        // have to wait for the wavesurfer to be ready before trying to add in the regions
        if(this.wavesurfer && v.length > 0) {
          // if modal we need to wait for the wavesurfer to be ready
          this.wavesurfer.on("ready", () => {
            this.updateTranscriptRegions(v)
          })
        } else {
          this.updateTranscriptRegions(v)
        }
      })

    },
    transcriptBtnCounter: function() {
      this.wavesurfer.seekTo(this.offset / this.wavesurfer.getDuration());
      this.playpause();
    },
    isLoaded() {
      if(this.transcriptBtnCounter === 1 && this.isLoaded) {
        setTimeout(() => {
          this.wavesurfer.seekTo(this.offset / this.wavesurfer.getDuration());
          this.playpause();
        }, 5000)
      }
    },
    playFromPosition: function(v) {
      this.wavesurfer.seekTo(v / this.wavesurfer.getDuration())
      if (!this.wavesurfer.isPlaying()) this.playpause()
    },
    setPosition: function(v) {
      this.wavesurfer.seekTo(v / this.wavesurfer.getDuration())
    },
  },
};
</script>

<style scoped>

  .audio-waveform {
    width: 100vw;
  }

  .alert-message {
    width: 100%;
    margin-bottom: 0 !important;
  }


</style>
