<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  404
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Page Not Found
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              The requested resource (e.g., scorecard, insight, or call recording) could not be found.  It has most likely been deleted. <br/>
              If you were trying to access a call and received this error, the associated call date was most likely beyond the available storage period.
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Dashboard</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
