<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  403
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Forbidden
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              You don’t have permission to access this shared link or resource under your login credentials.<br/> 
              Please contact your administrator to update your access roles or profiles.<br/>
              Alternatively, you can logout before making any additional attempts to access the shared link.
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Homepage</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
