<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-simple-table dense class="elevation-1">
          <thead>
          <tr>
            <th class="guidance_task_stats_header text-left">Agent</th>
            <th class="guidance_task_stats_header text-center">New</th>
            <th class="guidance_task_stats_header text-center">In Progress</th>
            <th class="guidance_task_stats_header text-center">Disputed</th>
            <th class="guidance_task_stats_header text-center">Overdue</th>
            <th class="guidance_task_stats_header text-center">Closed</th>
            <th class="guidance_task_stats_header text-center">Guidance Sessions</th>
            <th class="guidance_task_stats_header text-left">Date of Last Session</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="guidanceSessionListLoadingStatus=='loaded' && guidanceTaskListLoadingStatus=='loaded'">
            <td class="text-left guidance_task_stats_cell">
              <span :title="'Agent ID: ' + agentInfo.id">{{ agentInfo.full_name }}</span>
            </td>
            <td class="text-center guidance_task_stats_cell"><a class="font-weight-bold" @click="guidance_task_search_text = 'New'">{{ guidance_task_stats.new }}</a></td>
            <td class="text-center guidance_task_stats_cell"><a class="font-weight-bold" @click="guidance_task_search_text = 'In Progress'">{{ guidance_task_stats.in_progress }}</a></td>
            <td class="text-center guidance_task_stats_cell"><a class="font-weight-bold" @click="guidance_task_search_text = 'Disputed'">{{ guidance_task_stats.disputed }}</a></td>
            <td class="text-center guidance_task_stats_cell"><a class="font-weight-bold" @click="guidance_task_search_text = 'Overdue'">{{ guidance_task_stats.overdue }}</a></td>
            <td class="text-center guidance_task_stats_cell"><a class="font-weight-bold" @click="guidance_task_search_text = 'Closed'">{{ guidance_task_stats.closed }}</a></td>
            <td class="text-center guidance_task_stats_cell">{{ guidance_session_stats.session_count }}</td>
            <td class="text-left guidance_task_stats_cell">{{ formatDate(guidance_session_stats.last_session, true) }}</td>
          </tr>
          <tr v-else>
            <td colspan="8">
              <loading-status-box
                :message="dataLoadingMessage"
                :type="dataLoadingType"
                :messageStyle="'text-wrap: nowrap; font-size:1rem;'"
                :padding="0"
              ></loading-status-box>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="sessionListColumns" v-show="!hideSessionList">
        <v-card :style="blockHeightStyle">
          <v-app-bar class="app-bar-background" dense>
            <v-toolbar-title class="subtitle-1 app-bar-text pl-0">
              Guidance Sessions
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small class="app-bar-text" text @click="createGuidanceSession">+ new session</v-btn>
            <v-btn class="app-bar-text" icon @click="toggleColumns('sessionList')">
              <v-icon>{{ sessionListIcon }}</v-icon>
            </v-btn>
          </v-app-bar>
          <v-container>
            <loading-status-box
              v-if="guidanceSessionListLoadingStatus!='loaded' || (this.guidanceSessionListLoadingStatus=='loaded' && this.guidanceSessionList.length < 1)"
              :message="guidanceSessionsLoadingMessage"
              :type="guidanceSessionsLoadingType"
              :messageStyle="sessionListColumns>3 ? 'text-wrap: nowrap; font-size:1rem': ''"
              :padding="0"
            ></loading-status-box>
            <v-text-field
              v-else
              v-model="guidance_session_search_text"
              placeholder="Filter list by..."
              hide-details
              dense
              clearable
              append-icon="mdi-filter-variant"
            ></v-text-field>
          </v-container>
          <v-card-text v-if="guidanceSessionListLoadingStatus == 'loaded'" class="scrollable-content" :style="sessionListScrollHeightStyle">
            <div class="timeline">
              <v-row v-for="session in filtered_guidance_sessions" :key="'session-' + session.id" class="timeline-row">

                <v-col cols="2" class="flex-shrink-1 session-col pt-0">
                  <div class="session-icon-container">
                    <v-icon class="session-icon blue--text text--darken-2">mdi-compass-rose</v-icon>
                  </div>
                </v-col>
                <v-col cols="2" class="flex-shrink-1">
                  <span class="session-date">{{ formatDate(session.created_at, false) }}</span>
                </v-col>
                <v-col cols="7" class="flex-grow-1">
                  <template v-if="session.editing">
                  <inline-session-editor :id="session.id" :title="session.title" :note="session.note" :todos="session.todos" @sessionValid="sessionValid"></inline-session-editor>
                </template>
                <template v-else>
                  <strong>{{ session.title }}</strong>
                  <div v-show="sessionListColumns!=3" class="text-caption">
                    <template v-if="!expanded_sessions.includes(session.id)">
                      <p v-html="truncateText(session.note, 50)"> </p>
                    </template>
                    <template v-else>
                      <p>
                        <p v-html="session.note"></p>
                        <ol v-if="session.to_do_arr && session.to_do_arr.length>0">
                          <li v-for="to_do in session.to_do_arr" style="font-size: .8rem" v-html="to_do.text"> </li>
                        </ol>
                      </p>
                    </template>
                  </div>
                  <div v-show="sessionListColumns!=3 && (session.note.length >= 50 || session.to_do_arr.length > 0)">
                          <span v-if="!expanded_sessions.includes(session.id)">
                            <v-btn style="margin-left: -.5rem;" color="primary" icon small
                                   @click="toggleExpand(session.id)"><v-icon dark
                                                                             color="primary">mdi-chevron-down</v-icon></v-btn>
                          </span>
                    <span v-else>
                            <v-btn style="margin-left: -.5rem;" color="primary" icon small
                                   @click="toggleExpand(session.id)"><v-icon dark
                                                                             color="primary">mdi-chevron-up</v-icon></v-btn>
                          </span>
                  </div>
                </template>
                </v-col>
                <v-col cols="1" class="flex-shrink-1">
                  <v-btn v-if="!session.editing" icon color="blue darken-1" @click="editGuidanceSession(session.id, true, true)" title="edit session">
                    <v-icon small>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                  <template v-else>
                    <v-btn :disabled="sessionSaveDisabled || !editsMadeToSession(session)" icon color="green darken-1" @click="saveSession(session)" title="save edits">
                      <v-icon small>mdi-checkbox-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="red darken-1"  @click="editGuidanceSession(session.id, false, false)" title="discard edits">
                      <v-icon small>mdi-close-box-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="!session.new" @click="deleteSession(session)" icon title="delete session">
                      <v-icon small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>

                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="taskListColumns">
        <v-card :style="blockHeightStyle">
          <v-app-bar class="app-bar-background" dense>
            <v-toolbar-title class="subtitle-1 app-bar-text pl-0">
              Task List
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small class="app-bar-text" text @click="createNewTask">+ new task</v-btn>
            <v-btn class="app-bar-text" icon @click="toggleColumns('taskList')" v-show="(hideSessionList && this.selected_task.hasOwnProperty('task_id'))||!hideSessionList">
              <v-icon>{{ taskListIcon }}</v-icon>
            </v-btn>
          </v-app-bar>
          <v-container>
            <loading-status-box
              v-if="guidanceTaskListLoadingStatus!='loaded' || (guidanceTaskListLoadingStatus=='loaded' && guidanceTaskList.length < 1)"
              :message="guidanceTaskListLoadingMessage"
              :type="guidanceTaskListLoadingType"
              :messageStyle="taskListColumns>3 ? 'text-wrap: nowrap; font-size:1rem': ''"
              :padding="0"
            ></loading-status-box>
            <v-text-field
              v-else
                placeholder="Filter list by..."
                hide-details
                dense
                clearable
                append-icon="mdi-filter-variant"
                v-model="guidance_task_search_text"
            ></v-text-field>
          </v-container>
          <v-card-text class="scrollable-content" :style="taskListScrollHeightStyle">

            <!-- header row -->
            <v-row class="header-row" v-if="guidanceTaskListLoadingStatus=='loaded' && guidanceTaskList.length > 0">
              <v-col cols="2" class="flex-shrink-1 task-col py-2">
                &nbsp;
              </v-col>
              <v-col class="dr-flex justify-start flex-shrink-1 align-center py-2">
                <span class="task-date">Updated</span>
              </v-col>
              <v-col class="dr-flex flex-grow-1 align-center py-2">
                <span class="task-date">Title</span>
              </v-col>
              <v-col class="dr-flex justify-start flex-shrink-1 align-center py-2">
                <span class="task-date">Media</span>
              </v-col>
            </v-row>

            <!-- data rows -->
            <div class="timeline2" ref="timeline2" v-show="guidanceTaskListLoadingStatus=='loaded'">
              <v-row v-for="task in filtered_guidance_task_list" :key="'task-' + task.task_id" :class="'timeline2-row ' + isSelectedClass(task.task_id) + ' ' + wasSelectedClass(task.task_id)">
                <v-col cols="2" class="flex-shrink-1 task-col pt-2">
                      <the-task-icon v-if="task.call_id" :task="task" class="task-icon-container" :emitClick="'open-modal'" @open-modal="openCallModal(task)"></the-task-icon>
                      <the-task-icon v-else :task="task" class="task-icon-container" :emitClick="'open-task'" @open-task="openExistingTask"></the-task-icon>
                </v-col>
                <v-col class="dr-flex justify-start flex-shrink-1 align-center">
                  <span class="task-date">{{ formatDate( (task.task_updated_at) ? task.task_updated_at : task.task_created_at, true) }}</span>
                </v-col>
                <v-col class="dr-flex flex-grow-1 align-center">
                  <span class="task-date">
                    <strong>{{ task.task_title }} </strong>
                  </span>
                  <v-icon v-if="task.attachments.length>0" left small class="mr-0">mdi-paperclip</v-icon>
                  <span v-if="task.attachments.length>0" class="blue-grey--text caption">{{task.attachments.length}}</span>
                </v-col>
                <v-col class="dr-flex justify-start flex-shrink-1 align-center">
                  <v-chip small color="primary" dark link v-if="task.call_id" @click="openCallModal(task)">
                    <v-icon left small v-if="task.mediaType=='Call Recording'" :title="task.call_id">mdi-headset</v-icon>
                    <v-icon left small v-else-if="task.mediaType=='Chat'">mdi-chat-outline</v-icon>
                    <strong>{{ task.call_id }}</strong>
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="taskDetailColumns">
        <v-card :style="blockHeightStyle">
          <v-app-bar class="app-bar-background" dense>
            <v-toolbar-title class="subtitle-1 app-bar-text pl-0">
              <v-icon>{{this.taskIconHelper(filtered_guidance_task_list.find(o=> o.task_id==this.selected_task.task_id)).icon}}</v-icon> Task &mdash; {{ this.taskIconHelper(filtered_guidance_task_list.find(o=> o.task_id==this.selected_task.task_id)).title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="app-bar-text" icon @click="toggleColumns('taskDetail')" v-show="selected_task.hasOwnProperty('task_id')">
              <v-icon>{{ taskDetailIcon }}</v-icon>
            </v-btn>
          </v-app-bar>
          <task-container
            v-if="selected_task.hasOwnProperty('task_id')"
            ref="theTaskContainer"
            :TDSHStyle="taskDetailScrollHeightStyle"
            :task="selected_task"
            :agentInfo="agentInfo"
            :canCreate="true"
            :key="childKey"
            @resetParent="resetUserInterface"
            @setLastTaskId="setLastTaskId"
          ></task-container>
          <v-card-text v-else class="scrollable-content" :style="taskDetailScrollHeightStyle">
            <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%;">
              <v-row class="justify-center align-center">
                <v-col cols="auto" class="d-flex align-center">
                  <strong><v-icon class="mr-1">mdi-arrow-left-circle-outline</v-icon> Select a task,<br/>or create a new one&hellip;</strong>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 caption">
        <span v-show="hideSessionList">Guidance sessions are hidden. Click <v-btn class="app-bar-text" icon @click="sessionListHide()"><v-icon>{{sessionToggleIcon}}</v-icon></v-btn> to reveal.</span>
        <span v-show="!hideSessionList">Guidance Sessions are shown (but not visible to agents). Click <v-btn class="app-bar-text" icon @click="sessionListHide()"><v-icon>{{sessionToggleIcon}}</v-icon></v-btn> to hide.</span>
        <span> All information shown is inclusive of the last 12 months. Selected Date Range not applicable.</span>
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import Vue from "vue";
import {mapActions, mapGetters} from "vuex";
import taskIcon from "@/mixins/tasks/taskIcon";
import TheTaskIcon from "@/components/Widgets/Tasks/TheTaskIcon.vue";
import TaskContainer from "@/components/Tasks/TaskContainer.vue";
import moment from "moment";
import InlineSessionEditor from '@/components/Widgets/Guidance/InlineSessionEditor.vue';
import Confirm from '@/components/Widgets/Confirm.vue';
import regexChecks from "@/mixins/regexChecks";
import LoadingStatusBox from '@/components/Widgets/LoadingStatusBox.vue';
import CacheHandler from "@/utils/CacheHandler";

export default {
  components: {
    TheTaskIcon,
    TaskContainer,
    InlineSessionEditor,
    Confirm,
    LoadingStatusBox,
  },
  mixins: [taskIcon, regexChecks],
  props: {},
  data() {
    return {
      hideSessionList: CacheHandler.getItem("hide_session_list") === "true",
      childKey: 0,
      deleted_tasks: [],
      debounceTimeout: null,
      blockHeight: 0,
      blockHeightStyle: '',
      sessionListScrollHeight: 0,
      sessionListScrollHeightStyle: '',
      taskListScrollHeight: 0,
      taskListScrollHeightStyle: '',
      taskDetailScrollHeight: 0,
      taskDetailScrollHeightStyle: '',
      guidance_session_search_text: "",
      guidance_task_search_text: "",
      expanded_sessions: [],
      selected_task: {},
      sessionListColumns: 4,
      taskListColumns: 4,
      taskDetailColumns: 4,
      sessionListIcon: 'mdi-unfold-more-vertical',
      taskListIcon: 'mdi-unfold-more-vertical',
      taskDetailIcon: 'mdi-unfold-more-vertical',
      sessionSaveDisabled: false,
      general_task_stub: {
        "task_id": -1,                            // needed to a ctivate task panel
        "coaching_definition_id": null,           // post coaching_definition_id
        "task_agent": this.$route.params.agentId, // post agent_id
        "task_user": null,                        // needed later for UI purposes
        "agent_can_ack_or_dispute": 1,            // post agent_can_ack_or_dispute
        "notified_agent": (CacheHandler.hasOwnProperty("email_agent")) ? Number(CacheHandler.getItem("email_agent")) : 1,
        "task_title": "General Task",             // post task_title
        "due_date": moment()
          .add(7, 'days')
          .format('YYYY-MM-DD'),           // post due_date (default to one week from now)
        "task_message": "",
        "subcategories": [],
        "transcript_segments": [],
        "attachments": [],
        "replies": [],
        "created_at": moment().toISOString(),
        "updated_at": moment().toISOString(),
        "new_task": true,
        "general_task": true,                     // needed later for UI purposes
      },
      mounting: false,
      lastTaskId: null,
    }
  },
  mounted() {
    this.computeColumnWidths()

    // handle block sizing and resizing
    this.setHeights();
    window.addEventListener('resize', this.debouncedResize);

    let payload = {scorecard_id: this.$route.params.scorecardId, agent_id: this.$route.params.agentId}
    this.retrieveGuidanceTaskTitles()
    this.retrieveGuidanceTaskList(payload)
    this.retrieveGuidanceSessionList(payload)
    this.retrieveAgentData()
    this.retrieveCoachingDefinitionsList()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  },
  methods: {
    ...mapActions({
      appendTranscriptObservation: "observations/appendTranscriptObservation",
      appendSubcategoryObservation: "observations/appendSubcategoryObservation",
    }),
    ...mapActions({
      retrieveGuidanceTaskTitles: "scorecardTasks/retrieveGuidanceTaskTitles",
      retrieveGuidanceTaskList: "scorecardTasks/retrieveGuidanceTaskList",
      retrieveGuidanceSessionList: "guidance/retrieveGuidanceSessionList",
      setGuidanceEditState: "guidance/setGuidanceEditState",
      createNewGuidanceSession: "guidance/createNewGuidanceSession",
      saveGuidanceSession: "guidance/saveGuidanceSession",
      deleteGuidanceSession: "guidance/deleteGuidanceSession",
      retrieveAgentData: 'resources/retrieveAgentData',
      retrieveCoachingDefinitionsList: "coachingDefinitions/retrieveCoachingDefinitionsList",
    }),
    setLastTaskId(task_id) {
      this.lastTaskId = task_id;
    },
    resetUserInterface() {
      let payload = {scorecard_id: this.$route.params.scorecardId, agent_id: this.$route.params.agentId}
      this.selected_task = {}
      this.childKey += 1; // Increment the key to force re-render
      this.computeColumnWidths()
      this.retrieveGuidanceTaskList(payload)
    },
    setHeights() {
      this.blockHeight = Math.max(window.innerHeight - 520, 470)
      this.blockHeightStyle = 'height: ' + this.blockHeight + 'px !important;'

      this.sessionListScrollHeight = Math.max(this.blockHeight - 120, 254)
      this.sessionListScrollHeightStyle = 'height: ' + this.sessionListScrollHeight + 'px !important;'

      this.taskListScrollHeight = Math.max(this.blockHeight - 120, 254)
      this.taskListScrollHeightStyle = 'height: ' + this.taskListScrollHeight + 'px !important;'

      this.taskDetailScrollHeight = Math.max(this.blockHeight - 222, 242)
      this.taskDetailScrollHeightStyle = 'height: ' + this.taskDetailScrollHeight + 'px !important;'
    },
    debouncedResize() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setHeights();
      }, 500);
    },
    sessionListHide(action) {
      this.hideSessionList = !this.hideSessionList
      CacheHandler.setItem("hide_session_list", this.hideSessionList ? 'true' : 'false' )
      this.computeColumnWidths()
    },
    computeColumnWidths() {
      if (this.selected_task.hasOwnProperty('task_id')) {
        if(this.hideSessionList) {
          this.sessionListColumns = 0
          this.taskListColumns = 6
          this.taskDetailColumns = 6
        } else {
          this.sessionListColumns = 4
          this.taskListColumns = 4
          this.taskDetailColumns = 4
        }
      } else {
        if(this.hideSessionList) {
          this.sessionListColumns = 0
          this.taskListColumns = 10
          this.taskDetailColumns = 2
        } else {
          this.sessionListColumns = 5
          this.taskListColumns = 5
          this.taskDetailColumns = 2
        }
      }
    },
    toggleColumns(block) {

      // Update icons based on the target block.
      const updateIcons = (targetBlock) => {
        switch (targetBlock) {
          case 'sessionList':
            this.sessionListIcon = 'mdi-unfold-less-vertical';
            break;
          case 'taskList':
            this.taskListIcon = 'mdi-unfold-less-vertical';
            break;
          case 'taskDetail':
            this.taskDetailIcon = this.selected_task.hasOwnProperty('task_id')
              ? 'mdi-unfold-less-vertical'
              : '';
            break;
        }
      };

      // Reset all icons to their default state.
      const resetIcons = () => {
        this.sessionListIcon = 'mdi-unfold-more-vertical';
        this.taskListIcon = 'mdi-unfold-more-vertical';
        this.taskDetailIcon = this.selected_task.hasOwnProperty('task_id')
          ? 'mdi-unfold-more-vertical'
          : '';
      };

      // Stepper toggle -- if current value is equal to max, returns defaultVal, else max.
      const toggleValue = (current, max, defaultVal) => {
        return (current === max) ? defaultVal : max;
      };

      // Always reset icons at the start.
      resetIcons();

      // Option 1 of 2: When the session list is hidden.
      if (this.hideSessionList) {
        if (this.selected_task.hasOwnProperty('task_id')) {
          // Toggling logic when a task is selected.
          if (block === 'taskList') {
            if (this.taskListColumns === 6) {
              this.taskListColumns = 7;
              this.taskDetailColumns = 5;
              this.taskListIcon = 'mdi-unfold-less-vertical';
              this.taskDetailIcon = 'mdi-unfold-more-vertical';
            } else if (this.taskListColumns === 7) {
              this.taskListColumns = 5;
              this.taskDetailColumns = 7;
              this.taskListIcon = 'mdi-unfold-more-vertical';
              this.taskDetailIcon = 'mdi-unfold-less-vertical';
            } else {
              // Default state
              this.taskListColumns = 6;
              this.taskDetailColumns = 6;
              this.taskListIcon = 'mdi-unfold-more-vertical';
              this.taskDetailIcon = 'mdi-unfold-more-vertical';
            }
          } else if (block === 'taskDetail') {
            if (this.taskDetailColumns === 6) {
              this.taskDetailColumns = 7;
              this.taskListColumns = 5;
              this.taskDetailIcon = 'mdi-unfold-less-vertical';
              this.taskListIcon = 'mdi-unfold-more-vertical';
            } else if (this.taskDetailColumns === 7) {
              this.taskDetailColumns = 5;
              this.taskListColumns = 7;
              this.taskDetailIcon = 'mdi-unfold-more-vertical';
              this.taskListIcon = 'mdi-unfold-less-vertical';
            } else {
              // Default state
              this.taskDetailColumns = 6;
              this.taskListColumns = 6;
              this.taskDetailIcon = 'mdi-unfold-more-vertical';
              this.taskListIcon = 'mdi-unfold-more-vertical';
            }
          }
        } else {
          // When no task is selected and the session list is hidden.
          // For both 'sessionList' and 'taskList' blocks, set fixed column sizes.
          if (block === 'sessionList' || block === 'taskList') {
            this.taskListColumns = 10;
            this.taskDetailColumns = 2;
            updateIcons(block);
          }
        }
      }
      // Option 2/2: When the session list is visible.
      else {
        if (this.selected_task.hasOwnProperty('task_id')) {
          // Toggling logic when a task is selected.
          // Here we toggle between two sets of values: max (6) and a default (4)
          const maxValue = 6;
          const defaultVal = 4;
          if (block === 'sessionList') {
            this.sessionListColumns = toggleValue(this.sessionListColumns, maxValue, defaultVal);
            this.taskListColumns = (this.sessionListColumns === maxValue) ? 3 : 4;
            this.taskDetailColumns = (this.sessionListColumns === maxValue) ? 3 : 4;
            (this.sessionListColumns === defaultVal) ? resetIcons() : updateIcons(block);
          } else if (block === 'taskList') {
            this.taskListColumns = toggleValue(this.taskListColumns, maxValue, defaultVal);
            this.sessionListColumns = (this.taskListColumns === maxValue) ? 3 : 4;
            this.taskDetailColumns = (this.taskListColumns === maxValue) ? 3 : 4;
            (this.taskListColumns === defaultVal) ? resetIcons() : updateIcons(block);
          } else if (block === 'taskDetail') {
            this.taskDetailColumns = toggleValue(this.taskDetailColumns, maxValue, defaultVal);
            this.sessionListColumns = (this.taskDetailColumns === maxValue) ? 3 : 4;
            this.taskListColumns = (this.taskDetailColumns === maxValue) ? 3 : 4;
            (this.taskDetailColumns === defaultVal) ? resetIcons() : updateIcons(block);
          }
        } else {
          // Toggling logic when no task is selected.
          // Here we toggle between max (7) and a default (5)
          const maxValue = 7;
          const defaultVal = 5;
          if (block === 'sessionList') {
            this.sessionListColumns = toggleValue(this.sessionListColumns, maxValue, defaultVal);
            this.taskListColumns = (this.sessionListColumns === maxValue) ? 3 : 5;
            this.taskDetailColumns = 2;
            (this.sessionListColumns === defaultVal) ? resetIcons() : updateIcons(block);
          } else if (block === 'taskList') {
            this.taskListColumns = toggleValue(this.taskListColumns, maxValue, defaultVal);
            this.sessionListColumns = (this.taskListColumns === maxValue) ? 3 : 5;
            this.taskDetailColumns = 2;
            (this.taskListColumns === defaultVal) ? resetIcons() : updateIcons(block);
          }
        }
      }
    },
    openExistingTask(task) {
      // this is an existing task
      this.updateTaskDetailPanel(task)
    },
    createNewTask() {
      // new tasks from the guidance page only create general tasks...
      let taskDetail = this.general_task_stub
      taskDetail.is_open = 1
      this.updateTaskDetailPanel(taskDetail)
    },
    updateTaskDetailPanel(task) {
      // TODO: need to check if the user can edit the task and setEditTask appropriately
      this.selected_task = task
      // Ensure this runs after the component is ready
      this.$nextTick(() => {
        if (this.$refs.theTaskContainer) {
          this.$refs.theTaskContainer.setEditTask(true);
        }
      });
      this.computeColumnWidths()
    },

    formatDate(date, includeTime = false) {
      if(!date) return 'n/a'
      if (!date.endsWith('Z')) { date += 'Z' }
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      if (includeTime) { options.hour = 'numeric'; options.minute = '2-digit'; }
      const result = new Date(date).toLocaleDateString(undefined, options)
      return result
    },

    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    toggleExpand(sessionId) {
      const index = this.expanded_sessions.indexOf(sessionId);
      if (index > -1) {
        this.expanded_sessions.splice(index, 1);
      } else {
        this.expanded_sessions.push(sessionId);
      }
    },
    isSelectedClass(task_id) {
      return (this.selected_task.hasOwnProperty('task_id') && this.selected_task.task_id === task_id) ? "task-selected" : ""
    },
    wasSelectedClass(task_id) {
      return (this.lastTaskId == task_id) ? "task-was-selected" : ""
    },
    openCallModal(task) {
      this.updateTaskDetailPanel(task)
      this.$emit("openCallModal", {
        callId: task.call_id,
        clickedFrom: "agent-guidance",
        selectedTask: task,
      });
    },
    generateDateFormats(date) {
      const momentDate = moment(date);
      return [
        momentDate.format('YYYY-MM-DD'), // e.g., 2024-08-05
        momentDate.format('MMM D'), // e.g., Aug 5
        momentDate.format('MMMM Do'), // e.g., August 5th
        momentDate.format('MMMM D'), // e.g., August 5
        momentDate.format('D'), // e.g., 5
        momentDate.format('Do'), // e.g., 5th
        momentDate.format('YYYY'), // e.g., 2024
        momentDate.format('YY') // e.g., 24
      ]
    },
    async editGuidanceSession(sessionId, editing, loading) {
      let sessionParams = {id: sessionId, is_editing: editing}
      if (!editing && !loading) {
        if (
          await this.$refs.confirm.open(
            "Cancel Edits?",
            "About to cancel edits or remove guidance session. This action cannot be undone. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          this.setGuidanceEditState(sessionParams)
        }
      } else {
        this.setGuidanceEditState(sessionParams)
      }
    },
    saveSession(session) {
      let params = session
      params.agent_id = this.$route.params.agentId
      this.saveGuidanceSession(session)
    },
    async deleteSession(session) {
      if (
        await this.$refs.confirm.open(
          "Delete Session?",
          "About to delete guidance session. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        let params = session
        params.agent_id = this.$route.params.agentId
        this.deleteGuidanceSession(params)
      }
    },
    createGuidanceSession() {
      this.createNewGuidanceSession()
    },
    sessionValid(isValid) {
      this.sessionSaveDisabled = !isValid
    },
    editsMadeToSession(session) {
      return session.title != session.editableTitle || session.note != session.editableNote || session.todos != session.editableTodos || session.new
    },
  },
  computed: {
    ...mapGetters("users", [
      "userDataList",
      "userData",
      "userDataLoadingStatus",
      "cfOnlyUser",
      "administratorUser",
      "userDataListLoadingStatus"
    ]),
    ...mapGetters("scorecardTasks", [
      "guidanceTaskList",
      "guidanceTaskListLoadingStatus",
      "guidanceTaskListLoadingError",
      "guidanceTaskListLoadingErrorStatus",
      "guidanceTaskTitles",
      "guidanceTaskTitlesLoadingStatus",
      "guidanceTaskTitlesLoadingError",
      "guidanceTaskTitlesLoadingErrorStatus",
    ]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",
    ]),
    ...mapGetters("guidance", [
      "guidanceSessionList",
      "guidanceSessionListLoadingStatus",
      "guidanceSessionListLoadingError",
      "guidanceSessionListLoadingErrorStatus",
    ]),
    ...mapGetters('resources', [
      "agentData",
      "agentDataLoadingStatus",
      "agentDataLoadingError",
      "agentDataLoadingErrorStatus",
    ]),
    ...mapGetters('observations', [
      "savedTranscriptObservationData",
      "pendingTranscriptObservationData",
      "savedSubcategoryObservationData",
      "pendingSubcategoryObservationData",
    ]),
    sessionToggleIcon() {
      return (this.hideSessionList) ? 'mdi-eye' : 'mdi-eye-off'
    },
    agentInfo() {
      if(this.agentDataLoadingStatus=="loaded") {
        return this.agentData.find(o => o.id == this.$route.params.agentId)
      } else {
        return { name: '', id: '' }
      }
    },
    filtered_guidance_sessions() {
      if (!this.guidance_session_search_text) {
        return this.guidanceSessionList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      } else {
        const searchLower = this.guidance_session_search_text.toLowerCase()
        return this.guidanceSessionList.filter(session => {
          // Prepare created_at/updated_at in multiple formats for matching
          const createdAtFormats = this.generateDateFormats(session.created_at)
          const updatedAtFormats = this.generateDateFormats(session.updated_at)
          const dateFormats = [...createdAtFormats, ...updatedAtFormats]
          // Check if any of the date formats match the search text
          const matchesDate = dateFormats.some(date =>
            date.toLowerCase().includes(searchLower)
          )
          // Check subject, session_notes, and to_dos
          const matchesTextFields =
            session.title.toLowerCase().includes(searchLower) ||
            session.note.toLowerCase().includes(searchLower)
          const matchesTodos = session.to_do_arr.some(todo =>
            todo.text.toLowerCase().includes(searchLower)
          )
          return matchesDate || matchesTextFields || matchesTodos
        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    },

    filtered_guidance_task_list() {
      // return this.guidanceTaskList
      const searchLower = this.guidance_task_search_text.toLowerCase()
      if (!this.guidance_task_search_text) {
        return this.guidanceTaskList
          .map(task => ({
            ...task,
            mediaType: this.scorecardV2Data.mediaType,
            taskIconTitle: this.taskIconHelper(task).title,
          }))
          .sort((a, b) => new Date(b.task_updated_at) - new Date(a.task_updated_at))
      } else {
        return this.guidanceTaskList
          .map(task => ({
            ...task,
            mediaType: this.scorecardV2Data.mediaType,
            taskIconTitle: this.taskIconHelper(task).title,
          }))
          .filter(task => {
            // Prepare created_at in multiple formats for matching
            const createdAtFormats = this.generateDateFormats(task.task_created_at)
            const updatedAtFormats = this.generateDateFormats(task.task_updated_at)
            const dateFormats = [...createdAtFormats, ...updatedAtFormats]
            // Check if any of the date formats match the search text
            const matchesDate = dateFormats.some(date =>
              date.toLowerCase().includes(searchLower)
            )
            // Check subject, session_notes, and to_dos
            const matchesTextFields =
              task.task_title.toLowerCase().includes(searchLower) ||
              task.taskIconTitle.toLowerCase().includes(searchLower) ||
              task.task_message.toLowerCase().includes(searchLower)
            const matchesReplies = task.replies.some(reply =>
              reply.reply.toLowerCase().includes(searchLower)
            )
            const matchesTranscriptComments = task.transcript_segments.some(transcript_segment =>
              transcript_segment.comment.toLowerCase().includes(searchLower)
            )
            const matchesSubcategoryComments = task.subcategories.some(transcript =>
              transcript.comment.toLowerCase().includes(searchLower)
            )
            return matchesDate || matchesTextFields || matchesReplies || matchesTranscriptComments || matchesSubcategoryComments
          })
          .sort((a, b) => new Date(b.task_updated_at) - new Date(a.task_updated_at))
      }
    },

    guidance_session_stats() {
      if (!this.guidanceSessionList || this.guidanceSessionList.length === 0) {
        return {
          session_count: 0,
          last_session: null
        };
      } else {
        const sessionCount = this.guidanceSessionList.length;

        const lastSessionDate = this.guidanceSessionList
          .map(session => new Date(session.created_at)) // Convert created_at to Date objects
          .sort((a, b) => b - a)[0] // Get the latest date by sorting in descending order
          .toISOString(); // Convert back to ISO string format

        return {
          session_count: sessionCount,
          last_session: lastSessionDate
        };
      }
    },
    guidance_task_stats() {
      const taskTypes = {
        new: 0,
        in_progress: 0,
        disputed: 0,
        overdue: 0,
        closed: 0
      };

      const taskCounts = this.guidanceTaskList
      .map(task =>
        this.taskIconHelper(task).title.toLowerCase().replace(/\s+/g, '_') // Convert to lowercase and replace spaces
      )
      .reduce((acc, title) => {
        acc[title] = (acc[title] || 0) + 1; // Increment the count or initialize to 1
        return acc;
      }, {});

      // Merge the task counts into the base task types object
      return { ...taskTypes, ...taskCounts };

    },

    dataLoadingMessage() {
      if (this.guidanceSessionListLoadingError || this.guidanceTaskListLoadingError)
        return "There was an error loading guidance data.";
      else if (this.guidanceSessionListLoadingStatus=='loaded' && this.guidanceSessionList.length < 1 && this.guidanceTaskListLoadingStatus=='loaded' && this.guidanceTaskList.length < 1)
        return "There is currently no guidance data available, yet."
      else return "Guidance data loading, please wait...";
    },
    dataLoadingType() {
      if (this.guidanceSessionListLoadingError || this.guidanceTaskListLoadingError)
        return "error";
      else if (this.guidanceSessionListLoadingStatus=='loaded' && this.guidanceSessionList.length < 1 && this.guidanceTaskListLoadingStatus=='loaded' && this.guidanceTaskList.length < 1)
        return "nodata"
      else return "loading";
    },
    guidanceSessionsLoadingMessage() {
      if (this.guidanceSessionListLoadingError)
        return "There was an error loading guidance sessions.";
      else if (this.guidanceSessionListLoadingStatus=='loaded' && this.guidanceSessionList.length < 1)
        return "There are currently no guidance sessions created."
      else return "Guidance sessions loading, please wait...";
    },
    guidanceSessionsLoadingType() {
      if (this.guidanceSessionListLoadingError)
        return "error";
      else if (this.guidanceSessionListLoadingStatus=='loaded' && this.guidanceSessionList.length < 1)
        return "nodata"
      else return "loading";
    },
    guidanceTaskListLoadingMessage() {
      if (this.guidanceTaskListLoadingError)
        return "There was an error loading the task list.";
      else if (this.guidanceTaskListLoadingStatus=='loaded' && this.guidanceTaskList.length < 1)
        return "There are currently no tasks created."
      else return "Task list loading, please wait...";
    },
    guidanceTaskListLoadingType() {
      if (this.guidanceTaskListLoadingError)
        return "error";
      else if (this.guidanceTaskListLoadingStatus=='loaded' && this.guidanceTaskList.length < 1)
        return "nodata"
      else return "loading";
    }
  },
  watch: {
    userDataLoadingStatus: {
      immediate: true,
      deep: true,
      handler(status) {
        if(status == 'loaded') {
          this.general_task_stub.task_user = this.userData.id
        }
      }
    },
    guidanceTaskListLoadingStatus: {
      handler(status) {
        if(status == 'loaded') {
          const timeline = this.$refs.timeline2;
          if (timeline) {
            timeline.scrollTop = 0;
          }
        }
      }
    },
  },
}
</script>

<style scoped>

.guidance_task_stats_header {
  background-color: rgba(1, 106, 175, .15);
  padding: .75rem;
}

.guidance_task_stats_cell {
  padding: .75rem;
}

.no-uppercase {
  text-transform: none;
}


.session-date {
  font-size: .75rem;
  font-weight: 900;
}

.task-date {
  font-size: .75rem;
  font-weight: 900;
}

.session-subject {
  font-size: .95rem;
  font-weight: 900;
}

.session-note {
  font-size: .9rem;
  font-weight: 400;
}

.session-icon-container::before {
  content: '';
  position: absolute;
  left: -2px;
  top: 24px;
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
  /*background-color: #069; /* Same as timeline */
  border-radius: 50%; /* Circular marker */
  z-index: 1; /* Bring the container above the pseudo-element */
}

.session-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust the size to your preference */
  height: 48px;
  background-color: #fff; /* Blue background */
  border-radius: 50%; /* Circle shape */
  position: relative; /* Ensure icon stays in context */
  z-index: 2; /* Bring the container above the pseudo-element */
}

.session-icon {
  font-size: 24px; /* Adjust the icon size */
  margin-left: -12px;
  position: relative;
  z-index: 3; /* Ensure the icon is above the marker */
}

.timeline {
  position: relative;
  padding-left: 4px; /* Space for the timeline line */
}

.timeline::before {
  content: '';
  position: absolute;
  left: 20px; /* Adjust to align with session icons */
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #eee; /* Timeline color */
  z-index: 1
}

.timeline-row {
  position: relative;
  margin-bottom: 0; /* Spacing between rows */
}

.timeline2 {
  position: relative;
  padding-left: 4px; /* Space for the timeline line */
}

.v-badge {
  margin-left: -12px;
}

.timeline2::before {
  content: '';
  position: absolute;
  left: 20px; /* Adjust to align with session icons */
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #eee; /* Timeline color */
  z-index: 1
}

.timeline2-row {
  position: relative;
  margin-bottom: .1rem; /* Spacing between rows */
}

.task-icon-container::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 24px;
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
  border-radius: 50%; /* Circular marker */
  z-index: 2; /* Bring the container above the pseudo-element */
}

.task-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust the size to your preference */
  height: 48px;
  background-color: #fff; /* Blue background */
  border-radius: 50%; /* Circle shape */
  position: relative; /* Ensure icon stays in context */
  left: -6px;
  z-index: 1; /* Bring the container above the pseudo-element */
}

h1 {
  font-size: 2.2rem;
}

span {
  font-size: 1rem;
}

p {
  font-size: .8rem;
}

.center {
  text-align: center;
}

.fill-height {
  height: 100%;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.dr-flex {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.dr-flex:has(v-icon) {

}

.coach {
  font-weight: bold;
  font-size: 18px;
}

.team {
  font-weight: bold;
  font-size: 16px;
  padding-left: 20px;
}

.goal {
  font-weight: bold;
  font-size: 14px;
  padding-left: 40px;
}

.agent {
  font-weight: normal;
  font-size: 12px;
  padding-left: 60px;
}

.app-bar-background {
  background-color: rgba(1, 106, 175, .25);
}

.app-bar-text {
  color: #016aaf;
}

.v-timeline-item {
  margin-bottom: .5rem;
  padding-bottom: 0 !important;
}

.v-timeline-item .v-icon {
  color: red;
}

.private-notes {
  background-color: #f5f5f5;
  border-left: .5rem solid #fb8c00;
  padding: 1rem;
  padding-right: 1.5rem;
  margin-top: .75rem;
}

div.text-caption {
  margin-top: .5rem;
}

div.text-caption p {
  margin: .5rem 0 .5rem 0;
}

div.text-caption ol {
  margin-top: .5rem;
}

.scrollable-content {
  overflow-y: auto !important; /* Enables vertical scrolling */
  padding-right: 16px !important; /* Adds padding for better readability when scrolling */
}

.bottom-border {
  border-bottom: 1px dotted rgba(0, 0, 0, .25); /* Customize the border as needed */
}

.th-content {
  display: flex;
  justify-content: space-between; /* Distributes space between elements */
  align-items: center; /* Vertically centers the elements */
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.custom-select .v-input__control {
  border-bottom: none;
}

.custom-select .v-input__control:before {
  border-bottom: 1px solid lightgray;
}

.custom-select .v-input__control:after {
  border-bottom: none; /* To prevent the blue line when focused */
}

.task-selected {
  background-color: rgba(1, 106, 175, .25);
  border-radius: .5rem;
}

.task-was-selected {
  background-color: rgba(1, 106, 175, .05);
  border-radius: 0.5rem;
}

.header-row {
  background-color: rgba(1, 106, 175, .1);
  border-radius: 0.5rem;
}

</style>
