import axios from "@/utils/AxiosInstance.js"

export const transcript = {
  namespaced: true,
  state: {
    Hits: [],

    callTranscriptLoadingErrorStatus: false,
    callTranscriptLoadingError: "",
    callTranscriptLoadingStatus: "notLoading",
    callTranscript: [],
  },
  mutations: {
    change(state, transcriptHits) {
      state.transcriptHits = transcriptHits;
    },

    SET_CALL_TRANSCRIPT_LOADING_ERROR_STATUS(state, status) {
      state.callTranscriptLoadingErrorStatus = status
    },
    SET_CALL_TRANSCRIPT_LOADING_ERROR(state, error) {
      state.callTranscriptLoadingError = error
    },
    SET_CALL_TRANSCRIPT_LOADING_STATUS(state, status) {
      state.callTranscriptLoadingStatus = status
    },
    SET_CALL_TRANSCRIPT(state, callTranscript) {
      state.callTranscript = callTranscript
    },
  },
  getters: {
    hits: state => {
      return state.transcriptHits;
    },

    callTranscriptLoadingErrorStatus: state => state.callTranscriptLoadingErrorStatus,
    callTranscriptLoadingError: state => state.callTranscriptLoadingError,
    callTranscriptLoadingStatus: state => state.callTranscriptLoadingStatus,
    callTranscript: state => state.callTranscript,
  },
  actions: {

    retrieveCallTranscript(context, payload) {
      let axios_params = {params:{}}
      let transcript_url = process.env.VUE_APP_TRANSCRIPTS + payload.call_id

      if(payload.selfSigned) {
        transcript_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.call_id
        axios_params['params']['route'] = 'transcripts'
        axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}

        if (payload.is_chat) {
          axios_params['params']['is_chat'] = payload.is_chat
        }
      }

      context.commit("SET_CALL_TRANSCRIPT_LOADING_STATUS", "loading");
      axios
        .get(transcript_url, axios_params)
        .then(response => {

          let transcript = response.data
        
          context.commit("SET_CALL_TRANSCRIPT", transcript);
          context.commit("SET_CALL_TRANSCRIPT_LOADING_STATUS", "loaded");
          context.commit("SET_CALL_TRANSCRIPT_LOADING_ERROR_STATUS", false);
          context.commit("SET_CALL_TRANSCRIPT_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_CALL_TRANSCRIPT", []);
          context.commit("SET_CALL_TRANSCRIPT_LOADING_STATUS", "failed");
          context.commit("SET_CALL_TRANSCRIPT_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_CALL_TRANSCRIPT_LOADING_ERROR", 'retrieveCallTranscript(): ' + error_message)
        });
    },
  }
};
