<template>
  <v-container fluid>
    <v-dialog
      v-model="showModal"
      hide-overlay
      fullscreen
      transition="scroll-y-reverse-transition"
    >
      <div v-if="showModal" class="modal-div">
        <loading-status-box
          v-if="callDataLoading"
          :message="callLoadingMessage"
          :type="callLoadingType"
          :allowClose="!selfSigned"
          @HIDE_MODAL="hideModalMethod"
        >
        </loading-status-box>

        <template v-else>
          <!-- unified call header -->
          <unified-call-header
            :callId="callId"
            :callData="callData"
            :selfSigned="selfSigned"
            @update-call-note="updateCallNoteListener"
            @update-call-has-tags="updateCallHasTagsListener"
            @HIDE_MODAL="hideModalMethod"
          ></unified-call-header>

          <!-- unified call view -->
          <v-card class="px-4 py-4 mt-0" :style="cardHeightStyle">
            <!-- audio -->
            <unified-call-audio
              v-if="!isChat && !audioHasError"
              :callId="callId"
              :displayedRegions="displayedRegions"
              :callData="callData"
              :component="'audio recording'"
              :playFromPosition="playFromPosition"
              :audioExpanded="audioExpanded"
              :selfSigned="selfSigned"
              @audioExpandTrigger="audioExpandTrigger"
              @update-play-head-position="updatePlayHeadPosition"
              @audioHasError="setAudioError"
            ></unified-call-audio>

            <v-row class="modal-row" ref="vRowHeight" :style="rowHeightStyle">
              <!-- transcript -->
              <v-col :cols="transcriptCols" class="d-flex fill-height">
                <unified-call-component
                  ref="unifiedCallOne"
                  :component="'transcript'"
                  :callId="callId"
                  :callData="callData"
                  :expandIcon="getExpandIcon(transcriptCols)"
                  :audioExpanded="audioExpanded"
                  :rootHeight="rowHeight"
                  :playHeadPosition="playHeadPosition"
                  :selfSigned="selfSigned"
                  :showTask="showTask"
                  @update-play-from-position="updatePlayFromPosition"
                  @toggleCols="toggleCols"
                ></unified-call-component>
              </v-col>

              <!-- scorecard -->
              <v-col :cols="scorecardCols" class="d-flex fill-height">
                <unified-call-component
                  :component="'scorecard'"
                  :callId="callId"
                  :callData="callData"
                  :expandIcon="getExpandIcon(scorecardCols)"
                  :audioExpanded="audioExpanded"
                  :rootHeight="rowHeight"
                  :displayedRegions="displayedRegions"
                  :pageQueryData="pageQueryData"
                  :closedTaskExists="closedTaskExists"
                  :selfSigned="selfSigned"
                  :showTask="showTask"
                  @spliceDisplayedRegions="spliceDisplayedRegions"
                  @setDisplayedRegions="setDisplayedRegions"
                  @toggleCols="toggleCols"
                ></unified-call-component>
              </v-col>

              <!-- task -->
              <v-col
                v-if="showTask"
                :cols="taskCols"
                class="d-flex fill-height"
              >
                <unified-call-component
                  :component="'task'"
                  :callId="callId"
                  :callData="callData"
                  :expandIcon="getExpandIcon(taskCols)"
                  :audioExpanded="audioExpanded"
                  :rootHeight="rowHeight"
                  :selfSigned="selfSigned"
                  :selectedTask="conditionTaskData"
                  @toggleCols="toggleCols"
                  @resetParent="resetUserInterface"
                ></unified-call-component>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import UnifiedCallAudio from "./Component/UnifiedCallAudio.vue";
import UnifiedCallComponent from "./Component/UnifiedCallComponent.vue";
import UnifiedCallHeader from "./Header/UnifiedCallHeader.vue";
import LoadingStatusBox from "@/components/Widgets/LoadingStatusBox.vue";
import DemoHandler from "@/utils/DemoHandler";

const THREE_COLS = 3;
const FOUR_COLS = 4;
const FIVE_COLS = 5;
const SIX_COLS = 6;
const EIGHT_COLS = 8;

export default {
  components: {
    UnifiedCallHeader,
    UnifiedCallAudio,
    UnifiedCallComponent,
    LoadingStatusBox
  },
  data() {
    return {
      transcriptCols: !this.showTask ? SIX_COLS : THREE_COLS,
      scorecardCols: !this.showTask ? SIX_COLS : FIVE_COLS,
      taskCols: FOUR_COLS,
      callDataLoading: true,
      callData: null,
      audioExpanded: true,
      rowHeight: 0,
      rowHeightStyle: "",
      cardHeight: 0,
      cardHeightStyle: "",
      debounceTimeout: null,
      playHeadPosition: 0,
      playFromPosition: 0,
      displayedRegions: [],
      audioHasError: false,
      callReckey: ""
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    callId: {
      type: Number
    },
    clickedFrom: {
      type: String,
      required: false,
      default: "scorecard"
    },
    pageQueryData: {
      type: Object,
      required: false,
      default: null
    },
    selfSigned: {
      type: Boolean,
      required: false,
      default: false
    },
    lastWindowHash: {
      type: String,
      required: false,
      default: null
    },
    selectedTask: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus"
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataList",
      "userDataLoadingStatus",
      "adminUsers",

      "userDataListLoadingStatus",
      "userDataListLoadingError",
      "userDataListLoadingErrorStatus",
      "userDataList"
    ]),
    ...mapGetters("scorecardTasks", [
      "scorecardTaskList",
      "scorecardTaskListLoadingStatus"
    ]),
    ...mapGetters("scorecardTasks", ["taskSnapshotLoadingStatus"]),
    ...mapGetters("tasks", [
      "taskData",
      "taskDataLoadingStatus",
      "taskDataLoadingError",
      "taskDataLoadingErrorStatus"
    ]),
    ...mapGetters("resources", [
      "agentsOnTeamData",
      "agentsOnTeamDataLoadingStatus",

      "agentData",
      "agentDataLoadingStatus",
      "agentDataLoadingError",
      "agentDataLoadingErrorStatus"
    ]),
    ...mapGetters("transcript", [
      "callTranscript",
      "callTranscriptLoadingStatus",
      "callTranscriptLoadingError",
      "callTranscriptLoadingErrorStatus"
    ]),
    ...mapGetters("coachingDefinitions", [
      "scorecardCoachingDefList",
      "scorecardCoachingDefListLoadingStatus"
    ]),
    closedTaskExists() {
      let closedTask = this.scorecardTaskList.find(
        task => task.call_id == this.callId && task.is_open == 0
      );

      return closedTask || (this.taskData && this.taskData.is_open == 0)
        ? true
        : false;
    },
    callLoadingType() {
      if (this.callScorecardDataV2LoadingError) return "error";
      else return "loading";
    },
    callLoadingMessage() {
      if (this.callScorecardDataV2LoadingError)
        return "There was an error loading this call...";
      else return "Data loading, please wait...";
    },
    isChat() {
      return this.callData && this.callData.media_type == "Chat";
    },
    determineDemoCallId() {
      return DemoHandler.determineDemoCall(
        this.callId,
        this.isChat,
        this.selfSigned
      );
    },
    showNoAudio() {
      return this.isChat || this.audioHasError;
    },
    showTask() {
      // if call agent id is on the user team, can show task OR if the link is selfSigned
      // AND if the selectedTask has a task_id
      if (this.callId && !this.callDataLoading) {
        // if the user is an admin and the scorecard has an active coaching definition OR the user has the agent on their team and scorecard has an active coaching definition OR the view is self signed
        return (
          (this.coachingDefForSc &&
            (this.agentsOnTeamData.includes(Number(this.callData.agentId)) ||
              this.userIsAdmin)) ||
          this.selfSigned
        );
      }
      return false;
    },
    coachingDefForSc() {
      //scorecard should have at least one active coaching definition tied to it
      return (
        this.scorecardCoachingDefList.length > 0 &&
        this.scorecardCoachingDefList.filter(e => e.status == 1).length > 0
      );
    },
    userIsAdmin() {
      return this.adminUsers.includes(this.userData.groupid);
    },
    canRetrieveTaskSnapshot() {
      return (
        this.showTask &&
        ((this.selfSigned &&
          this.taskDataLoadingStatus == "loaded" &&
          this.taskData.is_open == 0) ||
          (this.scorecardTaskListLoadingStatus == "loaded" &&
            this.callId &&
            this.closedTaskExists))
      );
    },
    conditionTaskData() {
      //if not self signed, get the selected task passed in, otherwise get the task data retrieved
      let applicableTask = !this.selfSigned ? this.selectedTask : this.taskData;

      // the task agent should always be the agent associated to the call id
      applicableTask.task_agent = this.callData.agentId;

      return applicableTask;
    }
  },
  methods: {
    ...mapActions({
      retrieveCallScorecardDataV2:
        "scorecardsV2Calls/retrieveCallScorecardDataV2",
      retrieveCallTranscript: "transcript/retrieveCallTranscript",
      retrieveTaskSnapshotUrl: "scorecardTasks/retrieveTaskSnapshotUrl",
      generateCallReckey: "reckeys/generateCallReckey",
      generateAgentReckey: "reckeys/generateAgentReckey",
      retrieveTaskData: "tasks/retrieveTaskData",
      resetObservations: "observations/resetObservations",
      retrieveAuthorizedUserListData: "users/retrieveAuthorizedUserListData",
      retrieveAgentData: "resources/retrieveAgentData"
    }),
    componentIsNewTaskFrom(where) {
      return (
        this.selectedTask.hasOwnProperty("new_task") &&
        this.selectedTask.new_task &&
        this.selectedTask.hasOwnProperty("new_task_from") &&
        this.selectedTask.new_task_from == where
      );
    },
    hideModalMethod(params) {
      // remove the hash from the url without refreshing the page...
      let uri = window.location.toString();

      if (uri.indexOf("#") > 0) {
        let clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);

        location.hash =
          this.lastWindowHash &&
          this.lastWindowHash != "#call-scorecard-" + this.callId
            ? this.lastWindowHash
            : "#call-score-data-grid";
      }

      // if self-signed, reload the task, because there's nothing to hide...
      if (this.selfSigned) {
        let ssParams = this.requestParams();
        this.doSelfSignedActions(ssParams);
      } else {
        this.$emit("HIDE_MODAL", params);
      }
    },
    resetUserInterface(params) {
      this.$emit("resetParent", params);
    },
    toggleCols(section) {
      switch (section) {
        case "transcript":
          if (!this.showTask) {
            if (this.transcriptCols == SIX_COLS) {
              this.transcriptCols = EIGHT_COLS;
              this.scorecardCols = FOUR_COLS;
            } else {
              this.scorecardCols = SIX_COLS;
              this.transcriptCols = SIX_COLS;
            }
          } else {
            if (
              this.transcriptCols == THREE_COLS ||
              this.transcriptCols == FOUR_COLS
            ) {
              this.transcriptCols = FIVE_COLS;
              this.scorecardCols = FOUR_COLS;
              this.taskCols = THREE_COLS;
            } else {
              this.setDefaultColSize();
            }
          }
          break;
        case "scorecard":
          if (!this.showTask) {
            if (this.scorecardCols == SIX_COLS) {
              this.scorecardCols = EIGHT_COLS;
              this.transcriptCols = FOUR_COLS;
            } else {
              this.transcriptCols = SIX_COLS;
              this.scorecardCols = SIX_COLS;
            }
          } else {
            if (
              this.scorecardCols == THREE_COLS ||
              this.scorecardCols == FOUR_COLS
            ) {
              this.transcriptCols = THREE_COLS;
              this.scorecardCols = FIVE_COLS;
              this.taskCols = FOUR_COLS;
            } else {
              this.setDefaultColSize();
            }
          }
          break;
        case "task":
          if (this.taskCols == THREE_COLS || this.taskCols == FOUR_COLS) {
            this.transcriptCols = THREE_COLS;
            this.scorecardCols = FOUR_COLS;
            this.taskCols = FIVE_COLS;
          } else {
            this.setDefaultColSize();
          }
          break;
      }
    },
    setDefaultColSize() {
      this.transcriptCols = FOUR_COLS;
      this.scorecardCols = FOUR_COLS;
      this.taskCols = FOUR_COLS;
    },
    getExpandIcon(cols) {
      let iconColCountRel = this.showTask ? 4 : 6;

      return cols < iconColCountRel
        ? "mdi-unfold-less-vertical"
        : "mdi-unfold-more-vertical";
    },
    audioExpandTrigger(expanded) {
      this.audioExpanded = expanded;
      this.setHeights();
    },
    callFinishedLoading() {
      this.callDataLoading = false;
      this.shouldLoadTranscript();
      this.setHeights();
    },
    shouldLoadTranscript() {
      if (
        this.callId &&
        ((this.callTranscriptLoadingStatus == "loaded" &&
          this.callId != this.callTranscript.in_call_id) ||
          this.callTranscriptLoadingStatus != "loaded")
      ) {
        this.retrieveCallTranscript(this.transcriptReqParams());
      }
    },
    clickedFromCols() {
      //handle clicked from section expansion
      switch (this.clickedFrom) {
        case "task":
          this.scorecardCols = FOUR_COLS;
          this.taskCols = FIVE_COLS;
          this.transcriptCols = THREE_COLS;
          break;
        default:
          if (!this.showTask) {
            this.scorecardCols = SIX_COLS;
            this.transcriptCols = SIX_COLS;
          } else {
            this.scorecardCols = FIVE_COLS;
            this.taskCols = FOUR_COLS;
            this.transcriptCols = THREE_COLS;
          }
          break;
      }
    },
    setHeights() {
      if (!this.callDataLoading && !this.selfSigned && this.showNoAudio) {
        this.cardHeight = this.getHeightVal(125, 200);
        this.rowHeight = Math.max(window.innerHeight + 5, 200);
      } else if (!this.callDataLoading && this.selfSigned && this.showNoAudio) {
        this.cardHeight = this.getHeightVal(125, 200);
        this.rowHeight = Math.max(window.innerHeight + 50, 200);
      } else if (!this.callDataLoading && this.selfSigned && !this.isChat) {
        this.cardHeight = this.audioExpanded
          ? this.getHeightVal(180, 0)
          : this.getHeightVal(200, 100);

        this.rowHeight = this.audioExpanded
          ? this.getHeightVal(150, 175)
          : this.getHeightVal(0, 20);
      } else {
        this.cardHeight = this.audioExpanded
          ? this.getHeightVal(180, 0)
          : this.getHeightVal(200, 100);

        this.rowHeight = this.audioExpanded
          ? this.getHeightVal(200, 175)
          : this.getHeightVal(45, 20);
      }
      this.cardHeightStyle = "height: " + this.cardHeight + "px !important;";
      this.rowHeightStyle = "height: " + this.rowHeight + "px !important;";
    },
    getHeightVal(valOne, valTwo) {
      return Math.max(window.innerHeight - valOne, valTwo);
    },
    debouncedResize() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setHeights();
      }, 0);
    },
    updatePlayHeadPosition(e) {
      this.playHeadPosition = e;
    },
    updatePlayFromPosition(e) {
      // if the user triggered the "playFromPosition" function via a button, the audio player is automatically opened
      if (!this.audioExpanded && e.buttonPress) {
        this.audioExpanded = true;
        this.setHeights();
      }
      this.playFromPosition = e.position;
    },
    resetData() {
      this.playFromPosition = 0;
      this.playHeadPosition = 0;
      this.audioExpanded = true;
      this.displayedRegions = [];
      this.audioHasError = false;
    },
    setDisplayedRegions(regions) {
      if (!this.audioExpanded) {
        this.audioExpanded = true;
        this.setHeights();
      }
      this.displayedRegions.push({
        id: regions.id,
        offsets: regions.offsets,
        isCategory: regions.isCategory
      });
    },
    spliceDisplayedRegions(index) {
      this.displayedRegions.splice(index, 1);
    },
    retrieveSnapshotUrl() {
      if (this.canRetrieveTaskSnapshot) {
        this.retrieveTaskSnapshotUrl({
          call_id: this.callId,
          scorecard_id: this.$route.params.scorecardId,
          selfSigned: this.selfSigned,
          reckey: this.$route.params.reckey,
          userid: this.$route.params.userid
        });
      }
    },
    setAudioError(error) {
      this.audioHasError = error;
    },
    getReckeys() {
      if (!this.selfSigned && this.callId) {
        let params = {
          agent_id_for_reckey: this.callData.agentId,
          call_id: this.callId,
          expire: true
        };
        this.generateCallReckey(params);
        this.generateAgentReckey(params);
      }
    },
    agentCoachingCheck() {
      if (!this.showTask) {
        this.transcriptCols = SIX_COLS;
        this.scorecardCols = SIX_COLS;
      } else {
        this.clickedFromCols();
      }
      this.shouldRetrieveSnapshot();
    },
    shouldRetrieveSnapshot() {
      if (this.closedTaskExists) {
        this.retrieveSnapshotUrl();
      }
    },
    transcriptReqParams() {
      return {
        call_id: this.determineDemoCallId,
        selfSigned: this.selfSigned,
        reckey: this.$route.params.reckey,
        userid: this.$route.params.userid,
        is_chat: this.isChat
      };
    },
    requestParams() {
      return {
        scorecard_id: this.$route.params.scorecardId,
        dynalabel_id: this.$route.params.dynalabelId,
        agent_id: this.$route.params.agentId,
        call_id: this.callId,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        users: this.userDataList,
        call_type: this.$store.getters["filters/scorecardAdjustments"] || "all",
        selfSigned: this.selfSigned,
        reckey: this.$route.params.reckey,
        userid: this.$route.params.userid,
        callTask: true,
        agent_task_id: this.$route.params.agentid,
        task_id: this.selfSigned ? this.$route.params.taskid : null,
        user_id: this.userData.id
      };
    },
    doSelfSignedActions(params) {
      this.retrieveTaskData(params);
      if (this.userDataListLoadingStatus != "loaded") {
        this.retrieveAuthorizedUserListData(params);
      }
      if (this.agentDataLoadingStatus != "loaded") {
        params.agent_id = this.$route.params.agentid;
        this.retrieveAgentData(params);
      }
    },
    updateCallNoteListener(e) {
      this.$emit("update-call-note", e);
    },
    updateCallHasTagsListener(e) {
      this.$emit("update-call-has-tags", e);
    }
  },
  beforeUnmount() {
    this.resetObservations;
    window.removeEventListener("resize", this.debouncedResize);
  },
  mounted() {
    this.setHeights();
    window.addEventListener("resize", this.debouncedResize);

    if (this.callScorecardDataV2LoadingStatus == "loaded") {
      this.callData = Object.assign({}, this.callScorecardDataV2[this.callId]);
      this.callFinishedLoading();
      this.getReckeys();
    }

    let params = this.requestParams();
    if (this.callId) {
      this.retrieveCallScorecardDataV2(params);
      this.shouldRetrieveSnapshot();

      // get self signed data
      if (this.selfSigned) {
        this.doSelfSignedActions(params);
      }
    }
  },
  watch: {
    callScorecardDataV2LoadingStatus: function(status) {
      if (status == "loaded") {
        if (this.callScorecardDataV2[this.callId] != undefined) {
          this.callData = Object.assign(
            {},
            this.callScorecardDataV2[this.callId]
          );
          this.callFinishedLoading();
          this.getReckeys();
        }
      }
    },
    callId: function(callId) {
      if (callId) {
        //retrieve call data if the data is not set...
        this.callDataLoading = true;
        this.retrieveCallScorecardDataV2(this.requestParams());

        if (this.closedTaskExists) {
          this.retrieveSnapshotUrl();
        }
        this.resetData();
      }
    },
    taskDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.retrieveSnapshotUrl();
      }
    },
    scorecardTaskListLoadingStatus: "retrieveSnapshotUrl",
    clickedFrom: "clickedFromCols",
    showTask: "agentCoachingCheck"
  }
};
</script>
<style scoped>
.d-flex {
  display: flex;
  flex-direction: column;
}

.fill-height {
  height: 100% !important;
}

.modal-div {
  height: calc(100vh - 60px);
  background-color: #fff;
  width: 100%;
}

.modal-row {
  background-color: #fff;
}
</style>
