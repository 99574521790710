<template>
    <v-content style="height: 100vh; max-height: calc(100%);">

        <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
          <template v-if="this.isLegacy">
            <v-container v-if="this.loading" fill-height fluid>
              <v-row align="center" justify="center">
                <v-col style="text-align: center;">
                  <v-progress-circular
                    class="align-self-center mb-4"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else fluid pa-2 pt-10 style="height: 100vh; max-height: calc(100%);">
              <TranscriptHeader
                slot="header"
                :agent="agentName"
                :agentCode="agentCode"
                :callerNumber="callerNumber"
                :calledNumber="calledNumber"
                :callDate="callDate"
                :callDirection="callDirection"
                :accountNumber="accountNumber"
                :accountName="accountName"
                :insights="insights"
                :sentiment="sentiment"
                :callId="callId"
                :isLegacy="isLegacy"
                :playHeadPosition="playFromPosition"
                :playFromPosition="playFromPosition"
                :setPosition="setPosition"
                :menuLocation="menuLocation"
                :note_is_blank="note_is_blank"
                :clientCallId="inAsteriskId"
                @update-play-head-position="updatePlayHeadPosition"
                @update-set-position="updateSetPosition"
              ></TranscriptHeader>
              <template>
                <v-tabs v-model="activeTab" background-color="primary" dark>
                  <v-tab :key="'transcript'" :href="'#tab-transcript'">Transcript</v-tab>
                  <v-tab :key="'summary'" :href="'#tab-summary'" v-show="this.summaryIsReady&&!this.summaryIsLoading">Summary</v-tab>
                  <template>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-show="userDataLoadingStatus=='loaded' && this.canGenerateCallSummaries && !this.transcriptHasSummary && !selfSignedTranscript"
                      style="margin-top: .66rem; margin-right: .66rem;"
                      color="warning"
                      small
                      title="Summary Powered By OpenAI"
                      :disabled="summaryIsLoading"
                      :loading="summaryIsLoading"
                      @click="generateCallSummary()"
                    >
                      <custom-icon name='open-ai-mark' style="margin-right: .5rem;"></custom-icon>
                      Get Call Summary
                    </v-btn>
                  </template>
                </v-tabs>
                <v-tabs-items v-model="activeTab">
                  <v-tab-item :key="'transcript'" :value="'tab-transcript'" style="height: 45vh;">
                    <transcript style="height: 45vh; overflow:scroll;"
                      v-if="transcriptExists"
                      :transcript="transcriptData"
                      :callIn="callIn"
                      :callId="callId"
                      :eecNameXref="eecNameXref"
                      :custProf="custProf"
                      :playHeadPosition="playHeadPosition"
                      :class="'transcript-modal'"
                      @update-play-from-position="updatePlayFromPosition"
                      @update-set-position="updateSetPosition"
                    ></transcript>
                  </v-tab-item>
                  <v-tab-item :key="'summary'" :value="'tab-summary'" >
                    <transcript-summary style="height: 45vh; overflow:scroll;" :summaryData="summaryData" :summaryIsLoading="summaryIsLoading" :summaryIsReady="summaryIsReady"></transcript-summary>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </v-container>
          </template>

          <template v-else>
              <v-row>
                <v-col style="height: 100vh; max-height: calc(100%); padding: 0; position: relative;">
                  <Filters feature="transcripts"></Filters>
                  <v-container fluid style="height: 100vh; max-height: calc(100%);">
                    <v-row>
                      <v-col>
                        <Search @SEARCH_RESULTS_EXIST="setResultsExistance"></Search>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            <TranscriptModal
              :showModal="showTheModal"
              :callId="selectedCallId"
              :key="selectedCallId"
              :selfSignedTranscript="selfSignedTranscript"
              :menuLocation="String(menuLocation)"
              @HIDE_MODAL="hideModal"
            ></TranscriptModal>
          </template>
        </v-container>

    </v-content>
</template>

<style></style>

<script>
import axios from "@/utils/AxiosInstance.js";
import Filters from "@/components/Filters.vue";
import Search from "@/components/Transcripts/Search.vue";
import Transcript from "@/components/Transcripts/Transcript";
import TranscriptModal from "@/components/Transcripts/Modal";
import TranscriptHeader from "@/components/Transcripts/Header";
import {mapGetters} from "vuex";
import DemoHandler from "@/utils/DemoHandler";
import TranscriptSummary from "@/components/Transcripts/TranscriptSummary"


export default {
  components: {
    Filters,
    Search,
    Transcript,
    TranscriptModal,
    TranscriptHeader,
    TranscriptSummary,
  },
  data() {
    return {
      activeTab: 'tab-transcript',
      summaryIsReady: false,
      summaryIsLoading: false,
      summaryData: {},
      showTheModal: false,
      note_is_blank: 1,
      selectedCallId: this.$route.params.id,
      callId: this.$route.params.id,
      transcriptData: {},
      eecNameXref: {
        name: "",
        code: 0
      },
      callIn: {
        adjusteddatetime: "",
        orignmbr: "",
        acctnmbr: "",
        inasteriskid: "",
      },
      custProf: {
        name: "",
        insights: "",
      },
      callOut: "",
      searchResultsExist: false,
      loading: true,
      playHeadPosition: 0,
      playFromPosition: 0,
      setPosition: 0,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuLocation: {
      type: String,
      required: false,
      default: "transcripts",
    },
  },
  created() {
    if(this.callIdPresent) {
      this.showTheModal = this.showModal
      this.loadModal(this.selectedCallId)
    }
  },
  computed: {
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    canGenerateCallSummaries() {
      return (this.userDataLoadingStatus == 'loaded' && this.userData.settings.generateCallSummaries)
    },
    transcriptHasSummary() {
      return (this.summaryData.hasOwnProperty('summary'))
    },
    transcriptExists() {
      return (
        this.transcriptData.analyses && this.transcriptData.analyses.length > 0
      );
    },
    callIdPresent() {
      return this.$route.params.id > 0;
    },
    isLegacy() {
      return this.$route.name === "transcripts-lg";
    },
    // header
    agentName() {
      let name = "Agent";
      if (this.eecNameXref) {
        name = this.eecNameXref.name;
      }
      return name;
    },
    agentCode() {
      let code = 0;
      if (this.eecNameXref) {
        code = this.eecNameXref.code;
      }
      return code;
    },
    callerNumber() {
      return this.callIn.orignmbr || "";
    },
    calledNumber() {
      return this.callOut.pots || "";
    },
    callDate() {
      return this.callIn.adjusteddatetime || "";
    },
    callDirection() {
      return this.callIn.call_direction || "";
    },
    accountName() {
      return this.custProf.name || "";
    },
    accountNumber() {
      return Number(this.callIn.acctnmbr) || 0;
    },
    insights() {
      return Boolean(this.custProf.insights == 1) || false;
    },
    sentiment() {
      return this.transcriptData.analyses[0].sentiment || "";
    },
    inAsteriskId() {
      return this.callIn.inasteriskid || "";
    }
  },
  methods: {
    generateCallSummary() {
      this.summaryIsReady = false
      this.summaryIsLoading = true
      let ai_summary_url = process.env.VUE_APP_SUMMARIES + this.callId
      let axios_params = {params:{}}
      axios.get(ai_summary_url,axios_params)
        .then(response => {
          if (response && response.data) {
            this.summaryData = response.data
            this.summaryIsReady = true
            this.activeTab = 'tab-summary'
          }
        }).finally(() => {
        this.summaryIsLoading = false
      })
    },
    getTranscript() {
      if (this.callId) {
        // double axios madness what the hell??????
        this.loading = true;
        let self = this;

        let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callId
        let axios_params1 = {
          params: {
            fields:
              "eecnamexref.name,eecnamexref.code,callin.channel_count,callin.orignmbr,callin.adjusteddatetime,callin.call_direction,callin.acctnmbr,custprof.name,custprof.insights,callout.pots,callin.inasteriskid,note.note,callin.media_type"
          }
        }

        if(this.selfSignedTranscript) {
          call_records_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
          axios_params1['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
          axios_params1['params']['route'] = 'call-records'
        }

        axios.get(call_records_url, axios_params1)
          .then(response => {
            if (response && response.data) {
              self.callIn = response.data.callin;
              self.eecNameXref = response.data.eecnamexref;
              self.custProf = response.data.custprof;
              self.note_is_blank = (!response.data.note || !response.data.note.note) ? 1 : 0

              let isChat = self.callIn.media_type == 1
              let callTranscriptId = DemoHandler.determineDemoCall(this.callId, isChat, this.selfSignedTranscript)
              let transcript_url = process.env.VUE_APP_TRANSCRIPTS + callTranscriptId
              let axios_params2 = {params:{}}
              if(this.selfSignedTranscript) {
                transcript_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + callTranscriptId
                axios_params2['params']['route'] = 'transcripts'
                axios_params2['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey}
              }

              axios.get(transcript_url,axios_params2)
                .then(response => {
                  if (response && response.data) {
                    self.transcriptData = response.data;
                    if (this.transcriptData.hasOwnProperty('ai_summary')) {
                      this.summaryData = this.transcriptData.ai_summary
                      this.summaryIsReady = true
                    }
                    self.loading = false;
                  }
              });
            }
          });
      }
    },
    loadModal(callId) {
      this.selectedCallId = callId;
      this.showTheModal = true;
    },
    hideModal(status) {
      this.showTheModal = status;
    },
    setResultsExistance(value) {
      this.searchResultsExist = value;
    },
    transcriptExists() {
      return (
        this.transcriptData.analyses && this.transcriptData.analyses.length > 0
      );
    },
    updatePlayHeadPosition(e) {
      this.playHeadPosition = e;
    },
    updatePlayFromPosition(e) {
      this.playFromPosition = e;
    },
    updateSetPosition(e) {
      this.setPosition = e;
    },
    loadTranscriptData() {
      if (this.userDataLoadingStatus=='loaded' && this.callIdPresent) {

        this.loadModal(this.selectedCallId)
        if(this.isLegacy) this.getTranscript()
      }
    },
  },
  mounted() {
    this.loadTranscriptData()
  },
  watch: {
    userDataLoadingStatus:"loadTranscriptData"
  },
};
</script>

<style>

</style>
