<template>
    <v-app v-if="$route.meta.type==='single-page-report' || $route.meta.type==='printable-scorecard-v2' || $route.meta.type==='pop-up' || $route.meta.type==='sign-in' || $route.meta.type==='no-auth' || $route.meta.signedPage">
      <router-view></router-view>
    </v-app>
    <v-app v-else id="callfinder">
      <v-app-bar app clipped-left dark class="cf-background nav-element">
        <v-toolbar-title class="nav-element">
          <router-link :to="{ name: firstVisibleNamedRoute, query: $route.query }">
            <img src="@/assets/cf-logo.png" alt="">
          </router-link>
          <span class="app-title pl-8 ml-8">{{ $route.meta.title }}</span>
          <span style="display: none">{{ $vuetify.breakpoint.name }}</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="this.loading" id="app-loading-message-area">
          <v-progress-circular class="ml-8 mr-2 align-self-center"
           indeterminate
           color="green"
          ></v-progress-circular>
        </div>
        <scheduled-exports-list v-if="userDataLoadingStatus=='loaded'"></scheduled-exports-list>

        <notification-alerts v-if="userDataLoadingStatus=='loaded'"></notification-alerts>

        <proxy-r3 v-if="userDataLoadingStatus=='loaded'" :isOpen="proxyOpen"></proxy-r3>

        <user-info :userData="userData"></user-info>

        <v-btn fab icon @click="this.toSignin">
          <v-tooltip bottom v-if="userData.username">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-logout-variant</v-icon>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
          <v-icon v-else>mdi-account</v-icon>
        </v-btn>

      <!-- TODO: bring this back when we start showing/hiding based on screen size
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      // -->

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
        app
        permanent
        expand-on-hover
        clipped
        class="nav-element"
      >
      <v-list
        nav
        dense
      >
        <v-list-item-group v-model="menuItem" color="primary">
          <v-list-item
            v-for="(menuItem, i) in visibleMenuItems"
            :key="i"
            :inactive="menuItem.disabled"
            :disabled="menuItem.disabled"
            :class="(menuItem.disabled) ? 'dim-item' : ''"
            :to="menuTo(menuItem.namedRoute)"
          >
            <v-list-item-action>
              <v-icon v-text="menuItem.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="menuItem.label"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- adds divider and link to return to open legacy R2 site in new tab -->
          <v-divider v-if="accessToR2"></v-divider>
          <v-list-item v-if="accessToR2" @click="this.goToR2" target="_blank" rel="noopener" title="Go to Reports, Searches and Scorecard Builder">
            <v-list-item-action><v-icon v-text="'mdi-table-large'"></v-icon></v-list-item-action>
            <v-list-item-title>Searches</v-list-item-title>
            <v-list-item-icon class="pt-1"><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
          </v-list-item>

        </v-list-item-group>

      </v-list>

      <template v-slot:append>
        <v-list nav
        dense>
            <v-list-item link target="_blank" rel="noopener" href="/faq">
            <v-list-item-action>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      </template>

    </v-navigation-drawer>

        <router-view></router-view>

        <v-footer app class="nav-element" id="appFooter">
            <v-row>
                <v-col cols="12">
                    <v-row no-gutters align="center" justify="center">
                        <span class="caption mx-4">Copyright &copy; {{copyrightYear}}, CallFinder® 1-800-639-1700</span>
                        <v-btn class="mx-2" color="white" small text rounded
                               href="/privacy-policy">Privacy Policy
                        </v-btn>
                        <v-btn class="mx-2" color="white" small text rounded
                               href="mailto:customerservice@mycallfinder.com">Email Customer Service
                        </v-btn>
                        <v-btn class="mx-2" color="white" small text rounded
                               href="https://findmycalls.com/software_license.html">Software License Agreement
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-footer>

      <v-bottom-sheet v-model="appStatusSheet">
        <v-sheet class="text-center warning lighten-1 dark" height="25vh">
          <h1 class="heading white--text pt-8" v-html="appStatus.message"></h1>
          <v-btn class="mt-6 mb-6" dark color="primary" @click="ackAppStatus">close</v-btn>
        </v-sheet>
      </v-bottom-sheet>

    </v-app>
</template>

<script>

    import {mapGetters, mapActions} from "vuex";
    import UserInfo from "@/components/UserInfo.vue";
    import NotificationAlerts from './components/NotificationAlerts.vue';
    import ProxyR3 from './components/ProxyR3.vue';
    import CookieHelper from "@/utils/CookieHelper.js";
    import ScheduledExportsList from "@/components/Widgets/ScheduledExportsList.vue";
    import moment from "moment";

    export default {
      components: {
        ScheduledExportsList,
        UserInfo,
        NotificationAlerts,
        ProxyR3,
      },
      data: () => ({
        appStatusPolling: null,
        appStatusSheet: false,
        appStatusAck: false,
        drawer: null,
        drawerRight: null,
        right: false,
        left: false,
        menuItems: [
          {icon: 'mdi-order-bool-ascending-variant', label: 'Scorecards', namedRoute: 'scorecards-v2', disabled: false, visible: false},
          {icon: 'mdi-lock-pattern', label: 'Insights', namedRoute: 'insights-dashboard', disabled: false, visible: true},
          {icon: 'mdi-cog', label: 'Setup', namedRoute: 'setup', disabled: false, visible: false},
        ],
        menuItem: 0,
        hasScorecardsAccess: false,
        hasTranscriptsAccess: false,
        proxyOpen: false,
      }),
      computed: {
        firstVisibleNamedRoute() {
          return this.menuItems.find(o => o.visible).namedRoute
        },
        ...mapGetters({
          userData: 'users/userData',
          userDataLoadingStatus: 'users/userDataLoadingStatus',
          userDataLoadingError: 'users/userDataLoadingError',
          userDataLoadingErrorStatus: 'users/userDataLoadingErrorStatus',
          isAuthenticated: "authentication/isAuthenticated",
          proxyLookupFinished: "users/proxyLookupFinished",
          appStatus: "authentication/appStatus",
          appStatusLoadingStatus: "authentication/appStatusLoadingStatus",
          cfOnlyUser: 'users/cfOnlyUser',
          administratorUser: 'users/administratorUser',
          standardUser: 'users/standardUser',
          powerUser: 'users/powerUser',
          powerPlusUser: 'users/powerPlusUser',
          hasSetupAccess: 'users/hasSetupAccess',
          canCreateScorecards: 'users/canCreateScorecards',
          newScorecardsListCount: 'scorecardsV2/newScorecardsListCount',
          newScorecardsListCountLoadingStatus: 'scorecardsV2/newScorecardsListCountLoadingStatus',
        }),
        copyrightYear() {
          return new Date().getFullYear()
        },
        currentRouteName() {
          return this.$route.name;
        },
        loading() {
          return (this.newScorecardsListCountLoadingStatus=='loading')
        },
        visibleMenuItems() {
          return this.menuItems.filter(x => x.visible)
        },
        accessToR2() {
          if(this.userData.hasOwnProperty('id')) {
            return this.userData.privileges.callFinderManageSearches
          } else {
            return false;
          }
        }
      },
      methods: {
        ...mapActions({
          retrieveUserData: "users/retrieveUserData",
          getAppStatus: "authentication/getAppStatus",
          getNewScorecardsListCount: "scorecardsV2/getScorecardsListCount",
          retrieveTeamData: "resources/retrieveTeamData"
        }),
        loadData() {
          if(this.userDataLoadingStatus!='loaded' && !(this.$route.path==='/invalidtoken' || this.$route.meta.type==='sign-in' || this.$route.meta.type==='transcript-view-signed' || this.$route.meta.type==='printable-call-scorecard-v2-signed' || this.$route.meta.type==='unified-call-scorecard-v2-signed' || this.$route.meta.type==='tasks-signed')){
            if(!localStorage.getItem("bearer")) {
              this.toSignin()
            } else if (localStorage.getItem("bearer") === ""){
              this.toSignin()
            } else {
              this.retrieveUserData()
              this.getNewScorecardsListCount({
                "starts_at": moment().format("YYYY-MM-DD"),
                "ends_at": moment().format("YYYY-MM-DD"),
                "grouping": 'day'
              })
            }
          }
        },
        updateMenus() {
          if(!this.userData.hasOwnProperty('id')) {
            return
          }
          this.hasScorecardsAccess = this.userData.features.scorecards
          this.hasTranscriptsAccess = this.userData.features.transcriptions
          this.hasInsightsAccess = this.userData.features.transcriptions // insights access is tied to transcriptions
          let menuItem = {}

          if(this.newScorecardsListCount>0){
            menuItem = this.menuItems.find(o => o.namedRoute === 'scorecards-v2')
            menuItem.visible = true
          }

          menuItem = this.menuItems.find(o => o.namedRoute === 'insights-dashboard')
          menuItem.disabled = !this.hasInsightsAccess

          if(this.hasSetupAccess) {
            menuItem = this.menuItems.find(o => o.namedRoute === 'setup')
            menuItem.visible = true
          }

          // if the user doesnt have access try to open the proxy page. will only open if the user has access to it
          if(this.userData.id == this.userData.csrep_userid && this.userData.privileges.proxyAccess) {
            this.proxyOpen = true;
          }

        },
        menuTo(namedRoute) {
          let query = {...this.$route.query}
          // remove specific items not needed in general navigation
          delete query.speaker
          delete query.phrase
          return { name: namedRoute, query: query }
        },
        goToR2() {
          CookieHelper.setCookie(process.env.VUE_APP_R2_AUTH_COOKIE, localStorage.getItem("bearer"));
          if(this.userData.id == this.userData.csrep_userid) {
            // opens r2 in a new tab
            window.open(process.env.VUE_APP_AUTH_TO_R2);
          } else {
            // if currently proxying as someone
            window.open(process.env.VUE_APP_AUTH_TO_R2 + "?csrep_username=" + this.userData.username + "&csrep_userid=" + this.userData.csrep_userid);
          }
        },
        toSignin() {
          let route = '';
          route = String(window.location.pathname + window.location.search + window.location.hash);
          window.location.href = "/signin" + "?prev_path=" + encodeURIComponent(route);
        },
        resetUserData() {
          window.location.href = "/insights";
        },
        startAppStatusPolling () {
          this.appStatusPolling = setInterval(() => {
            this.getAppStatus()
          }, process.env.VUE_APP_STATUS_CHECK_INTERVAL)
        },
        ackAppStatus() {
          this.appStatusAck = true
          this.appStatusSheet = false
        }
      },
      created() {
        this.getAppStatus()
        this.startAppStatusPolling()
      },
      mounted() {
        this.loadData()
      },
      watch: {
        appStatus: function(appStatus) {
          if(this.currentRouteName!='offline'&&appStatus.status=='disabled') {
            this.$nextTick(() => {
              window.location.href="/offline"
            });
          } else if(this.currentRouteName=='offline'&&appStatus.status=='active') {
            this.$nextTick(() => {
              window.location.href="/"
            });
          } else if(appStatus.status=='scheduled'&&!this.appStatusAck) {
            this.appStatusSheet = true
          }
        },
        userDataLoadingStatus: function(status) {
          if(status=='loaded' && !this.$route.meta.signedPage) {
            this.getNewScorecardsListCount({
              "starts_at": moment().format("YYYY-MM-DD"),
              "ends_at": moment().format("YYYY-MM-DD"),
              "grouping": 'day'
            })
            this.retrieveTeamData()
            this.updateMenus()
          }
        },
        newScorecardsListCountLoadingStatus: function(status) {
          if(status=='loaded') {
            this.updateMenus()
          }
        },
        proxyLookupFinished: 'resetUserData',
      }
    }
</script>

<style>

    .app-title {
      position: relative;
      border-left: 2px solid rgba(255,255,255,.5);
      top: -.3rem;
    }

    /* suppress anchor tag underlines */
    .v-list-item__action > a,
    .v-list-item__content > a {
        text-decoration: none;
    }

    /* footer links */
    .v-footer {
        background-color: #083a81 !important;
    }

    .v-footer span {
        color: rgba(255, 255, 255, .5);
    }

    .footer-link {
        text-decoration: none;
        color: rgba(255, 255, 255, .5);
    }

    .footer-link:hover {
        text-decoration: underline;
        color: rgba(255, 255, 255, 1);
    }

    /* style guide - corporate background color */
    .cf-background {
        background-color: #083a81 !important;
    }

  #app-loading-message-area {
    font-size: .8em;
  }

  .dim-item {
    opacity: .25;
  }

</style>
