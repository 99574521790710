<template>
  <div>
    <v-simple-table v-if="isDataReady">
      <template v-slot:default>
        <thead>
          <tr :class="'header-row ' + headerRowLevel">
            <th colspan="5">
              <h2 v-html="getHead()"></h2>
            </th>
            <th colspan="5">
              <h2 v-if="isPrintable" class="header-score">
                Score: {{ formatScore(callData.callScore) }}
              </h2>
              <!-- im keeping the icon soup name but using a kebab sue me -->
              <br>
              <call-scorecard-icon-soup class="text-right no-print"
                :scorecardId="scorecardId"
                :callId="callId"
                :dynalabelId="dynalabelId"
                :agentId="agentId"
                :callData="callData"
                :transcriptions="transcriptions"
                :selfSigned="selfSigned"
                :menuLocation="menuLocation"
                :displaySoup="false"
                @loadModal="loadTranscriptModal"
                @update-call-note="updateCallNoteListener"
                @update-call-has-tags="updateCallHasTagsListener"
                @overlay-present="overlayPresent"
              ></call-scorecard-icon-soup>
            </th>
          </tr>

          <tr class="header-row bottom" v-if="!isPrintable">

            <th colspan="4" >
              <h2 >
                {{ getAccountInfo }}
              </h2>

            </th>
            <th colspan="4" >
              <h2 class="header-score">
                Score: {{ formatScore(callData.callScore) }}
              </h2>
            </th>
          </tr>
          <tr
            v-if="showExclude"
            class="header-row bottom"
          >
            <th colspan="2">
              <template>
                <v-btn
                  @click="deleteAppliedOverrides"
                  color="blue-grey"
                  x-small
                  dark
                  :disabled="countOfCurrentOverrides < 1"
                >
                  Remove ({{ countOfCurrentOverrides }}) Applied Changes</v-btn
                >
                <confirm ref="confirm"> </confirm>
              </template>
            </th>
            <th colspan="3">
              <div class="switch-center-label ">
                <v-switch
                  inset
                  color="error lighten-2 !important"
                  :class="!excludeCall ? 'switch-inactive': '' "
                  :disabled="typeOfExclusion == 1"
                  v-model="excludeCall"
                  :label="`${excludeCall ? 'Call Excluded' : 'Call Included'}`"
                ></v-switch>
              </div>
            </th>
            <th class="text-right" colspan="3">
              <template>
                <v-btn
                  color="blue"
                  dark
                  :disabled="applyOrRemove"
                  x-small
                  @click="changeCallOverrides()"
                  >Apply ({{ totalUpdates }}) Pending Changes</v-btn
                >
                <v-btn
                  color="orange"
                  dark
                  :disabled="applyOrRemove"
                  x-small
                  class="ml-1"
                  @click="cancelOverrides"
                  >Remove Changes</v-btn
                >
                <confirm ref="confirm"> </confirm>
              </template>
            </th>
          </tr>
        </thead>
        <thead>
          <tr class="cols">
            <th colspan="2" class="pl-2">Category</th>
            <th class="text-center no-print">Category Hit</th>
            <th colspan="2" class="pl-0">Subcategory</th>
            <th class="text-center no-print">Subcategory Hit</th>
            <th class="align-text-print" :colspan="scoreColSpan">Score</th>
            <th class="text-center no-print">Transcript</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="row in callScorecardRows">
            <tr class="group-separator">
              <td class="text-center icon-col pl-0 pr-0">
                <call-scorecard-icon
                  v-if="row.primary"
                  :format="format"
                  :callId="callId"
                  :type="'category'"
                  :key="row.catId"
                  :classType="pendingCatOverride(row)"
                  :iconColor="getCatColor(row, excludeCall)"
                  :iconType="getCatIcon(row)"
                  :titleText="getCatText(row)"
                  :row="row"
                  :applicable="categoryIsApplicable(row)"
                  :actionsDisabled="iconsDisabled"
                  @updateCallData="updateCallData"
                  @updateCat="updateCat"
                ></call-scorecard-icon>
              </td>
              <td class="pl-1">
                <span v-if="row.primary">{{ row.catTitle }}</span>
              </td>

              <!-- this is the new category hit -->
              <td class="no-print">
                <v-layout
                  v-if="!isChat && !searchDataHasError"
                  row
                  wrap
                  justify-center
                  class="ml-1"
                >
                  <v-progress-circular
                    v-if="searchDataLoading"
                    indeterminate
                    color="primary"
                    size="24"
                  ></v-progress-circular>
                  <v-switch
                    v-else-if="categoryShowHitEnabled(row)"
                    inset
                    class="white--text switch-inactive"
                    color="rgba(226, 127, 32, 0.5) !important"
                    v-model="checkedRegions"
                    :value="row.catId"
                    @change="updateDisplayedRegions(row.catId)"
                  ></v-switch>
                  <v-switch v-else-if="categoryShowHitDisabled(row)"
                    class="switch-disabled"
                    color="disabled"
                    :disabled="true"
                    inset
                  ></v-switch>
                </v-layout>
                <span v-else>&nbsp;</span>

              </td>
              <td class="text-center icon-col pl-0 pr-0">
                <call-scorecard-icon
                  @updateCallData="updateCallData"
                  :callId="callId"
                  :format="format"
                  :type="'subcategory'"
                  :classType="pendingSubcatOverride(row)"
                  :iconColor="getSubCatColor(row, excludeCall)"
                  :iconType="getSubCatIcon(row)"
                  :titleText="getSubCatText(row)"
                  :row="row"
                  :applicable="categoryIsApplicable(row)"
                  :actionsDisabled="iconsDisabled"
                  @updateSubcat="updateSubcat"
                ></call-scorecard-icon>
              </td>
              <td class="pl-1">
                {{ ampersandReplacement(row.subcatTitle) }}
              </td>

              <td class="no-print">
                <v-layout
                  v-if="!isChat && !searchDataHasError"
                  row
                  wrap
                  justify-center
                  class="ml-1"
                >
                  <v-progress-circular
                    v-if="searchDataLoading"
                    indeterminate
                    color="primary"
                    size="24"
                  ></v-progress-circular>
                  <v-switch
                    v-else-if="subcategoryShowHitEnabled(row)"
                    inset
                    class="white--text"
                    :class="!checkedRegions.includes(row.subcatId)? 'switch-inactive': ''"
                    color="green lighten-2"
                    v-model="checkedRegions"
                    :value="row.subcatId"
                    @change="updateDisplayedRegions(row.subcatId)"
                  ></v-switch>
                  <v-switch
                    v-else-if="subcategoryShowHitDisabled(row)"
                    class="switch-disabled"
                    :disabled="true"
                    inset
                  ></v-switch>
                </v-layout>
                <span v-else>&nbsp;</span>

              </td>
              <td :colspan="scoreColSpan" class="align-text-print" v-if="row.primary && row.showScore">
                {{ formatScore(Number(row.catScore)) }}
              </td>
              <td :colspan="scoreColSpan" v-else>&nbsp;</td>
              <td class="text-center no-print" >
                <div v-if="!searchDataHasError">
                  <v-progress-circular
                    v-if="searchDataLoading"
                    indeterminate
                    color="primary"
                    size="24"
                  ></v-progress-circular>
                  <div v-else>
                    <template>
                      <v-icon v-if="subcatHasSearchHits(row)"
                        :disabled = "!transcriptions || !categoryIsApplicable(row)"
                        title="view transcript"
                        style="cursor: pointer;"
                        @click.native.stop="loadModal(row.subcatId)"
                        >mdi-message-text
                      </v-icon>
                    </template>
                  </div>
                </div>
                <span v-else>&nbsp;</span>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-row>
      <v-spacer></v-spacer>
      <v-col class="no-print" cols="8" >
        <call-scorecard-control-info
        ></call-scorecard-control-info>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <TranscriptModal
      :showModal="showModal"
      :scorecardId="scorecardId"
      :callId="callId"
      :key="Number(callId)"
      :has_tags="callData.has_tags"
      :note_id="callData.note_id"
      :note_is_blank="callData.note_is_blank"
      :transcriptions="transcriptions"
      :scorecardName="callData.scorecardTitle"
      :selfSignedTranscript="Boolean(selfSigned)"
      :displayedRegions="transcriptRegions"
      :menuLocation="menuLocation"
      :context="transcriptContext"
      :contextId="parseInt(this.scorecardId)"
      :contextDynalabelId="parseInt(this.dynalabelId)"
      :contextAgentId="parseInt(this.agentId)"
      :crumbDefHeader="callData.scorecardTitle"
      :crumbDynaHeader="callData.dynalabelTitle"
      :crumbAgentHeader="callData.agentName"
      :crumbSingleDyna="callData.dynalabelCount < 2"
      @HIDE_MODAL="hideModal"
      @update-call-note="updateCallNoteListener"
      @update-call-has-tags="updateCallHasTagsListener"
    ></TranscriptModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Confirm from "../Widgets/Confirm.vue";
import CallScorecardIcon from "./CallScorecardIcon.vue";
import callSubcategoryIndex from "@/mixins/callSubcategoryIndex";
import callCategoryIndex from "@/mixins/callCategoryIndex";
import TranscriptModal from "@/components/Transcripts/Modal";
import CallScorecardIconSoup from "@/components/Widgets/CallScorecardIconSoup.vue";
import CallScorecardControlInfo from "./CallScorecardControlInfo.vue";
import DemoHandler from '../../utils/DemoHandler';

export default {
  mixins: [callSubcategoryIndex, callCategoryIndex],
  components: {
    Confirm,
    CallScorecardIcon,
    TranscriptModal,
    CallScorecardIconSoup,
    CallScorecardControlInfo
  },
  props: {
    callId: String,
    scorecardId: String,
    dynalabelId: String,
    agentId: String,
    callData: Object,
    displayedRegions: Array,
    format: String,
    pageSize: Number,
    pageNum: Number,
    callSearch: String,
    selfSigned: Boolean,
    menuLocation: String,
    singleDynaLabel: Boolean,
  },
  data() {
    return {
      has_tags: false,
      note_id: 0,
      note_is_blank: 1,
      excludeCall: false,
      excludeCallVar: false,
      typeOfExclusion: null,
      applyChange: false,
      transcriptions: false,
      checkedRegions: [],
      showModal: false,
      payload: {},
      buttonsDisabled: false,
      removedOverrides: false,
      callScorecardRows: [],
      searchIds: [],
      transcriptRegions: [],
      transcriptContext: "",
      callDataId: DemoHandler.determineDemoCall(this.callId, this.isChat, this.selfSigned)
    };
  },

  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "callScorecardDataV2",
      "callScorecardDataV2LoadingStatus",
      "callScorecardDataV2LoadingError",
      "callScorecardDataV2LoadingErrorStatus",

      "callSearchHits",
      "callSearchHitsLoadingStatus",
      "callSearchHitsLoadingError",
      "callSearchHitsLoadingErrorStatus"
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingErrorStatus",
      "userDataList",
      "cfOnlyUser",
      "administratorUser",
      "standardUser",
      "manualScoringAccess"
    ]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("dynaLabels",[
      "dynalabelHitsForCall",
      "dynalabelHitsForCallLoadingStatus",
      "dynalabelHitsForCallLoadingError",
      "dynalabelHitsForCallLoadingErrorStatus",
    ]),

    categorySearches() {
      // get all of the searches from the category dynalabels
      let rtn = {}
      rtn[this.callDataId] = []

      if(this.dynalabelHitsForCallLoadingStatus == 'loaded') {
        // get the search ids from categories that have a dynalabel hit
        this.callData.categories.forEach(category => {
          category.dynalabels.forEach(dynalabel => {
            dynalabel.searches.forEach(search => {
              rtn[this.callDataId].push(search)
            })
          });
        });
        return rtn
      } else {
        return rtn
      }
    },
    showExclude() {
      return this.manualScoringAccess && this.displayCallHitsInfo
    },
    subcatSearchPhrases() {
      let subcatsWithPhrase = [];
      //push all of the subcategory ids that have a search phrase that exists
      if (
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("offsets") &&
        this.callData.hasOwnProperty("categories")
      ) {
        this.callData.categories.forEach(category => {
          category.sub_cats.forEach(subcat => {
            subcat.searches.forEach(search => {
              //subcategory has a search phrase when search ids match and the phrase_group_id and phrase id are set
              this.callSearchHits[this.callDataId].offsets.forEach(searchHit => {
                if (
                  searchHit.fkey_phrase_group_id &&
                  searchHit.fkey_phrase_id &&
                  searchHit.fkey_search_id == search &&
                  !subcatsWithPhrase.includes(subcat.id)
                ) {
                  subcatsWithPhrase.push(subcat.id);
                }
              });
            });
          });
        });
      }
      return subcatsWithPhrase;
    },
    categorySearchPhrases() {
      let categoriesWithPhrase = [];
      // push all of the category ids that have a search phrase that exists
      if (
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("offsets") &&
        this.callData.hasOwnProperty("categories")
      ) {
        this.callData.categories.forEach(category => {
          category.dynalabels.forEach(dynalabel => {
            dynalabel.searches.forEach(search => {
              // category has a search phrase when search ids match and the phrase_group_id and phrase id are set
              this.callSearchHits[this.callDataId].offsets.forEach(searchHit => {
                if (
                  searchHit.fkey_phrase_group_id &&
                  searchHit.fkey_phrase_id &&
                  searchHit.fkey_search_id == search &&
                  !categoriesWithPhrase.includes(category.id)
                ) {
                  categoriesWithPhrase.push(category.id);
                }
              });
            });
          });
        });
      }
      return categoriesWithPhrase;
    },
    searchDataHasError() {
      if (this.callSearchHitsLoadingStatus == "failed") {
        return true;
      } else {
        return this.callSearchHits[this.callDataId] != undefined &&
          this.callSearchHits[this.callDataId].hasOwnProperty("error")
          ? this.callSearchHits[this.callDataId].error
          : false;
      }
    },
    searchDataLoading() {
      if (this.searchIds.length < 1) {
        // no searches to load data for
        return false
      } else if (
        this.callSearchHitsLoadingStatus != "failed" &&
        this.callSearchHits[this.callDataId] != undefined &&
        this.callSearchHits[this.callDataId].hasOwnProperty("loading")
      ) {
        return this.callSearchHits[this.callDataId].loading;
      } else {
        return true;
      }
    },
    headerRowLevel() {
      return this.isPrintable ? "top" : "bottom";
    },
    isPrintable() {
      return this.format == "printingCallScorecard";
    },
    displayCallHitsInfo() {
      return this.format == "callScorecard";
    },
    scoreColSpan() {
      return !this.displayCallHitsInfo ? "2" : "";
    },
    scoreColText() {
      return this.isPrintable ? "text-center" : "text-right";
    },
    countOfCurrentOverrides() {
      if (this.callData.excludedCall && this.callData.exclusionType == 0) {
        return (
          this.callData.subcategoryManualOverrides +
          this.callData.categoryManualOverrides +
          1
        );
      }
      return (
        this.callData.subcategoryManualOverrides +
        this.callData.categoryManualOverrides
      );
    },
    iconsDisabled() {
      if (this.format == "printingCallScorecard") return true;
      if (!this.isDataReady) return false;
      return this.excludeCall && this.typeOfExclusion != 1;
    },
    getAccountInfo() {
      if (!this.isDataReady) return "";
      if (this.callData.displayHandleTime == 1) {
        return (
          this.callData.accountName +
          " (" +
          this.callData.accountId +
          ") - Handle Time: " +
          this.formatHMS(this.callData.handleTime) +
          "*"
        );
      } else {
        return this.callData.accountName + " (" + this.callData.accountId + ")";
      }
    },
    totalUpdates() {
      let count = 0;
      if (this.isDataReady) {
        this.callData.categories.forEach((category, index) => {
          if (category.updated) {
            count++;
          }
          category.sub_cats.forEach((subcategory, index) => {
            if (subcategory.updated) {
              count++;
            }
          });
        });
      }
      if (
        this.excludeCall != this.callData.excludedCall &&
        this.typeOfExclusion != 1 &&
        this.callData.exclusionType != 1
      ) {
        count++;
      }
      return count;
    },
    allCategoriesNotApplicable() {
      if (!this.isDataReady) return false;
      return (
        this.callData.categories.filter(
          category => !this.catApplicable(category)
        ).length == this.callData.categories.length
      );
    },
    callScorecardRegions: function() {
      if (!this.isDataReady) return;
      let regions = {category: {}, subCategory: {}}
      regions['subCategory'] = this.callData.categories.flatMap(category => {
        return category.sub_cats.flatMap(subcategory => {
          return { id: subcategory.id, search_ids: subcategory.searches };
        });
      });

      regions['category'] = this.callData.categories.flatMap(category => {
        return { id: category.id, search_ids: category.dynalabels.map(dynalabel => dynalabel.searches).flat() }
      });


      return regions
    },
    isDataReady() {
      return !this.callData.loading;
    },
    applyOrRemove() {
      return (
        (this.totalUpdates < 1 || this.buttonsDisabled) && !this.applyChange
      );
    },
    isChat() {
      return this.scorecardV2Data && this.scorecardV2Data.mediaType == 'Chat';
    },
  },
  methods: {
    ...mapActions({
      retrieveScorecard: "scorecardsV2Calls/retrieveScorecard",
      retrieveCallScorecardDataV2:
        "scorecardsV2Calls/retrieveCallScorecardDataV2",
      clearOverrides: "scorecardsV2Calls/clearOverrides",
      removeAppliedOverrides: "scorecardsV2Calls/removeAppliedOverrides",
      updateCallOverrides: "scorecardsV2Calls/updateCallOverrides",
      createExcludedCall: "scorecardsV2Calls/createExcludedCall",
      retrieveSearchHits: "scorecardsV2Calls/retrieveSearchHits",
      getDynalabelCallHit: "dynaLabels/getDynalabelCallHit",
      setDynalabelHitsForCallLoadingStatus: "dynaLabels/setDynalabelHitsForCallLoadingStatus",
    }),
    categoryShowHitEnabled(row) {
      return this.categoryHasSearchHits(row) && row.primary && this.categoryIsApplicable(row)
    },
    categoryShowHitDisabled(row) {
      return this.categoryHasSearchHits(row) && row.primary && !this.categoryIsApplicable(row)
    },
    subcategoryShowHitEnabled(row) {
      return this.subcatHasSearchHits(row) && this.categoryIsApplicable(row)
    },
    subcategoryShowHitDisabled(row) {
      return this.subcatHasSearchHits(row) && !this.categoryIsApplicable(row)
    },
    updateCat(categoryId) {
      this.callScorecardRows.forEach(row => {
        if (row.catId == categoryId) {
          row.catUpdated = !row.catUpdated;
        }
      });
    },
    subcatHasSearchHits(row) {
      // show "Search Hit"/enable transcript button if category is automatic && subcategory has a search phrase hit
      return (
        row.catManualScoring == 0 &&
        this.subcatSearchPhrases.includes(row.subcatId)
      );
    },
    categoryHasSearchHits(row) {
      // show hit for category
      if(this.dynalabelHitsForCallLoadingStatus == "loaded") {
        return (
          this.categorySearchPhrases.includes(row.catId)
        );
      } else {
        return false
      }

    },
    updateSubcat(subcategoryId) {
      this.callScorecardRows.forEach(row => {
        if (row.subcatId == subcategoryId) {
          row.subcatUpdated = !row.subcatUpdated;
        }
      });
    },
    updateCallData() {
      this.$emit("callDataChanged");
    },
    checkCatHandling(category) {
      if (!this.categoryAppHandling(category)) {
        return this.callData.nonApplicableCategoryHandling == 1;
      } else return true;
    },
    categoryIsManualApp(category) {
      return (
        category.manual_applicable != undefined &&
        category.manual_applicable != null
      );
    },
    categoryAppHandling(category) {
      if (this.categoryIsManualApp(category)) {
        return category.manual_applicable == 1;
      } else {
        return category.applicable == 1;
      }
    },
    catApplicable(category) {
      if (category.updated) {
        if (this.categoryIsManualApp(category)) {
          return (category.applicable == 1) ? true : false
        } else {
          return category.applicable != 1;
        }
      } else {
        if (this.categoryIsManualApp(category)) {
          return category.manual_applicable == 1;
        } else {
          return category.applicable == 1;
        }
      }
    },
    changeCallOverrides() {
      this.$emit("updateDataLoading", true);
      this.payload.excluded_call = this.excludeCall;
      this.payload.exclusion_type = this.typeOfExclusion;
      if (
        this.callData.exclusionType != this.typeOfExclusion &&
        this.typeOfExclusion != null
      ) {
        if (this.typeOfExclusion == 1) {
          this.payload.exclusion_type = 1;
          //just need to update overrides because call will become exluded automatically when reprocessed with the new category applicable-ness
          this.updateCallOverrides(this.payload);
        } else {
          //create an excluded call - no need to reprocess the call when it is not excluded by selecting categories
          this.payload.exclusion_type = 0;
          this.createExcludedCall(this.payload);
        }
      } else {
        // just update the overrides
        this.updateCallOverrides(this.payload);
      }
      this.payload.excluded_call = null;
      this.payload.exclusion_type = null;
    },
    updateCallNoteListener(e) {
      this.note_id = e.noteId;
      this.note_is_blank = Number(e.note_is_blank)
      this.callData.note_is_blank = Number(e.note_is_blank)
    },
    updateCallHasTagsListener(e) {
      this.has_tags = e.has_tags
      this.callData.has_tags = e.has_tags
    },
    overlayPresent(e) {
      this.$emit("overlayPresent", e)
    },
    callInfo: function() {
      if (!this.isDataReady) return;
      let call = this.callData;
      let msg = "";
      if (call.direction == "in") {
        msg = "Inbound call from " + this.formatPhoneNumber(call.ani);
      } else {
        // out
        msg = "Outbound call to " + this.formatPhoneNumber(call.dns);
      }
      return msg;
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    getHead() {
      if (!this.isDataReady) return;
      let infoString = "";
      if (!this.displayCallHitsInfo) {
        infoString =
          "<span>" +
          this.callInfo() +
          " - (" +
          this.callId +
          ")</span><br><span>" +
          this.formatDTTM(this.callData.adjustedDateTime) + "<br>" +
          this.getClientId() +
          "</span><br>" +
          "<span>" +
          this.getAccountInfo +
          "</span>";
      } else {
        infoString =
          "<span>" +
          this.convertToUpperCase(this.callData.agentName) + "<br><h4>" +
          this.callInfo() +
          " - (" +
          this.callId +
          ")</span><br><span>" +
          this.formatDTTM(this.callData.adjustedDateTime) + "<br>" +
          this.getClientId() +
          "</h4></span>";
      }
      return infoString;
    },
    getClientId() {
      let rtn_str =  "Client Call Identifier: "
      if(this.callData.in_asterisk_id == null) {
        return rtn_str
      } else {
        return rtn_str + this.callData.in_asterisk_id
      }

    },
    convertToUpperCase(name) {
      return (name) ? name.toUpperCase() : name
    },
    formatDTTM(date) {
      return moment(date).format("dddd, MMMM D, YYYY [at] h:mmA");
    },
    pendingCatOverride(row) {
      return row.catUpdated ? "pending-override" : "";
    },
    pendingSubcatOverride(row) {
      return row.subcatUpdated ? "pending-override" : "";
    },
    formatPhoneNumber(number) {
      return number.length == 10
        ? "(" +
            number.slice(0, 3) +
            ") " +
            number.slice(3, 6) +
            "-" +
            number.slice(6)
        : number;
    },
    async deleteAppliedOverrides() {
      if (
        await this.$refs.confirm.open(
          "Delete Applied Overrides?",
          "About to delete all overrides for call. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        this.removeAppliedOverrides(this.payload);
        this.callScorecardRows.forEach(row => {
          row.catUpdated = false;
          row.subcatUpdated = false;
        });
        this.$emit("updateDataLoading", true);
      }
    },
    async cancelOverrides() {
      if (
        await this.$refs.confirm.open(
          "Clear Overrides?",
          "About to clear all overrides for call. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        if (this.excludeCall != this.callData.excludedCall) {
          this.excludeCall = !this.excludeCall;
        }
        if (this.typeOfExclusion != this.callData.exclusionType) {
          this.typeOfExclusion = this.callData.exclusionType;
        }
        this.removedOverrides = true;
        this.applyChange = false;
        this.clearAppliedChanges();
      }
    },
    ampersandReplacement(replacement) {
      return replacement.replace(/&amp;/g, "&");
    },
    formatScore(score) {
      score = score ? score : 0; // prevents NaN
      return Math.round(score * 100) + "%";
    },
    clearAppliedChanges() {
      this.callScorecardRows.forEach(row => {
        row.catUpdated = false;
        row.subcatUpdated = false;
      });
      this.clearOverrides(this.payload);
    },
    showApplyChange() {
      if (this.isDataReady) {
        // if the exclusion type is different than the one currently set in the scorecard
        // and there are some categories applicable OR exclusion is not set by flipping switch or no exclusion is set
        if (
          this.typeOfExclusion != this.callData.exclusionType &&
          (!this.allCategoriesNotApplicable ||
            (this.typeOfExclusion && this.typeOfExclusion != 0))
        ) {
          this.applyChange = true;
        } else {
          this.applyChange = false;
        }
      }
    },
    checkExclusion() {
      //only need to update buttons when overrides werent removed - otherwise set them back to original state
      if (!this.removedOverrides) {
        if (!this.allCategoriesNotApplicable) {
          this.typeOfExclusion = this.excludeCall ? 0 : null;
        }
        this.buttonsDisabled = this.excludeCall ? true : false;
        if (this.typeOfExclusion == 0) {
          this.clearAppliedChanges();
        }
        this.showApplyChange();
      } else {
        this.removedOverrides = false;
        this.buttonsDisabled = false;
      }
    },
    getCallOffsets(isCategory, record) {
      let offsets = [];
        if (
          this.callSearchHits[this.callDataId] != undefined &&
          this.callSearchHits[this.callDataId].hasOwnProperty("offsets")
        ) {
          this.callSearchHits[this.callDataId].offsets.forEach(offset => {
            if (record.search_ids.includes(offset.fkey_search_id)) {
              if(!isCategory || this.categorySearches[this.callDataId].includes(offset.fkey_search_id)) {
                offsets.push(offset);
              }
            }
          });
        }
        return offsets
    },
    loadSubcatTranscriptHits(subcatId) {
      if (subcatId) {
        // this subcategory transcript modal was selected, get the subcategory record
        let record = this.callScorecardRegions.subCategory.find(
          entry => entry.id == subcatId
        );

        if(record) {
          let offsets = this.getCallOffsets(false, record);
          if (Array.isArray(offsets)) this.transcriptRegions.push({ id: subcatId, offsets: offsets, isCategory: false });
        }
      } else {
        // ok, this just got unchecked, so remove the region from the display
        let indx = this.transcriptRegions.findIndex(o => o.id === subcatId);
        if (indx >= 0) this.transcriptRegions.splice(indx, 1);
      }
    },
    updateDisplayedRegions(id) {
      let region_id = this.checkedRegions.find(entry => entry == id);
      if (region_id) {
        // ok, this just got checked, so add the region to the display
        // check both the subcategories and categories now
        let record = this.callScorecardRegions.subCategory.find(
          entry => entry.id == region_id
        );
        let isCategory = false;
        if(record == undefined) {
          record = this.callScorecardRegions.category.find(
            entry => entry.id == region_id
          );
          isCategory = true
        }
        let offsets = this.getCallOffsets(isCategory, record);
        if (Array.isArray(offsets)) this.$emit("setDisplayedRegions", { id: id, offsets: offsets, isCategory: isCategory });
      } else {
        // ok, this just got unchecked, so remove the region from the display
        let indx = this.displayedRegions.findIndex(o => o.id === id);
        if (indx >= 0) this.$emit("spliceDisplayedRegions", indx);
      }
    },
    loadModal(id) {
      this.transcriptContext = "scorecard_subcategories"
      this.loadSubcatTranscriptHits(id)
      this.showModal = true;
    },
    loadTranscriptModal() {
      this.transcriptContext = "scorecards"
      this.showModal = true;
    },
    hideModal(hideModal) {
      this.transcriptRegions = []
      this.showModal = hideModal;
    },
    checkExcludedCallType() {
      //only need to check excluded call type when the overrides were not removed
      if (!this.removedOverrides) {
        if (this.allCategoriesNotApplicable) {
          this.typeOfExclusion = 1;
          this.excludeCall = true;
        } else {
          this.typeOfExclusion = null;
          this.excludeCall = false;
        }
      } else {
        this.removedOverrides = false;
        this.buttonsDisabled = false;
      }
    },
    getSearchIds() {
      let searches = [];
      if (this.callData.hasOwnProperty("categories")) {
        this.callData.categories.forEach(category => {
          if (category.manual_scoring == 0) {
            category.dynalabels.forEach(dynalabel => {
              dynalabel.searches.forEach(search => {
                if (!searches.includes(search)) searches.push(search);
              })
            })
            category.sub_cats.forEach(sub_cat => {
              sub_cat.searches.forEach(search => {
                if (!searches.includes(search)) searches.push(search);
              });
            });
          }
        });
      }
      return searches;
    },
    updatePageSize() {
      this.payload.page_size = this.pageSize
    },
    updatePageNum() {
      this.payload.page = this.pageNum
    },
    updateCallSearch() {
      this.payload.search_calls = this.callSearch ? this.callSearch.trim() : this.callSearch
    },
    createCallScorecardRows() {
      let lastCategoryName = "";
      let primary = false;
      if (this.callData.hasOwnProperty("categories")) {
        this.callData.categories.forEach(category => {
          category.sub_cats.forEach(subcategory => {
            if (lastCategoryName != category.title) {
              lastCategoryName = category.title;
              primary = true;
            } else {
              primary = false;
            }
            
            this.callScorecardRows.push({
              catId: category.id,
              primary: primary,
              catScore: category.score,
              catTitle: category.title,
              catManualApplicable: category.manual_applicable,
              categoryOverriddenBy: category.username,
              catApplicable: category.applicable,
              catScoringRule: category.scoring_rule,
              catManualScoring: category.manual_scoring,
              catOvverrideDate: category.modified_on,
              threshold_passfail: category.threshold_passfail,
              threshold_count: category.threshold_count,
              showScore: this.checkCatHandling(category),
              catWeight: category.weight,
              catContribution: category.contribution,
              catUpdated: false,
              subcatUpdated: false,
              subcatId: subcategory.id,
              subcatTitle: subcategory.title,
              subcatHit: subcategory.hit,
              subcatFail: subcategory.fail,
              subcatManualPass: subcategory.manual_pass,
              subcatManualFail: subcategory.manual_fail,
              subcatOverriddenBy: subcategory.username,
              subcatOverrideDate: subcategory.override_datetime,
              subcatSearches: subcategory.searches,
              totalSubcatWeight: category.totalSubcatWeight,
              subcatWeight: subcategory.weight
            });
          });
        });
      }
    },
    callGetDynalabelCallHit() {
      let dynalabel_ids = []
      this.callData.categories.forEach(category => {
        category.dynalabels.forEach(dynalabel => {
          dynalabel_ids.push(dynalabel.id)
        });
      });
      let params = {'call_id': this.callDataId, 'dynalabel_ids': dynalabel_ids, 'selfSigned': this.selfSigned}
      if(this.selfSigned) {
        params['userid'] = this.$route.params.userid
        params['reckey'] = this.$route.params.reckey
      }
      this.getDynalabelCallHit(params);

    },
    getParams() {
      let paramObj = {
        scorecard_id: this.$route.params.scorecardId,
        dynalabel_id: this.$route.params.dynalabelId,
        agent_id: this.$route.params.agentId,
        grouping: this.getUnit,
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        distributions: this.$route.params.agentId == 0 ? "team" : "agent",
        page: this.pageNum,
        page_size: this.pageSize,
        search_calls: this.search ? this.search.trim() : this.search,
        order_by: this.orderBy,
        order_direction: this.orderDirection,
        cat_sort_id: this.categorySortId,
        users: this.userDataList,
        call_type: this.$store.getters["filters/scorecardAdjustments"] || "all"
      }

      // include audit info if user is proxying
      if(this.userData.id != this.userData.csrep_userid && this.userData.privileges.callFinderManageSearches) {
        paramObj.get_audit_info = true
      }

      return paramObj
    },
    setCallDataId() {
      this.callDataId = this.determineDemoCall(this.callId, this.isChat, this.selfSigned)
    }
  },
  mounted() {
    this.setDynalabelHitsForCallLoadingStatus('notLoading');
    this.createCallScorecardRows();
    if (this.userDataLoadingStatus == "loaded") {
      this.transcriptions = this.userData.features.transcriptions;
    }
    this.payload = {
      scorecard_id: this.scorecardId,
      dynalabel_id: this.dynalabelId,
      agent_id: this.agentId,
      call_id: this.callId,
      start_date: moment(this.start_date).format("YYYY-MM-DD"),
      end_date: moment(this.end_date).format("YYYY-MM-DD"),
      call_type: this.$store.getters["filters/scorecardAdjustments"] || "all",
      users: this.userDataList,
      page: this.pageNum,
      page_size: this.pageSize,
      search_calls: this.callSearch ? this.callSearch.trim() : this.callSearch,
    };

    // include audit info if user is proxying
    if(this.userData.id != this.userData.csrep_userid && this.userData.privileges.callFinderManageSearches) {
      this.payload.get_audit_info = true
    }

    this.excludeCall = this.callData.exclusionType != null ? true : false;
    this.typeOfExclusion = this.callData.exclusionType;
    this.note_id = this.callData.note_id;
    this.note_is_blank = this.callData.note_is_blank;
    this.has_tags = this.callData.has_tags;
    let axios_params = {}
    if(this.selfSigned) {
      axios_params['userid'] = this.$route.params.userid
      axios_params['reckey'] = this.$route.params.reckey
    }
    this.searchIds = this.getSearchIds()
    if(this.searchIds.length > 0) {
      this.retrieveSearchHits({
        call_id: this.callDataId,
        search_ids: this.searchIds,
        axios_params: axios_params,
        selfSigned: this.selfSigned
      });
    }

    this.callGetDynalabelCallHit()
    this.retrieveScorecard(this.getParams())
  },
  watch: {
    excludeCall: "checkExclusion",
    allCategoriesNotApplicable: "checkExcludedCallType",
    userDataLoadingStatus: function(status) {
      if (status == "loaded") {
        this.transcriptions = this.userData.features.transcriptions;
      }
    },
    pageSize: "updatePageSize",
    pageNum: "updatePageNum",
    callSearch: "updateCallSearch",
    isChat: "setCallDataId"
  }
};
</script>

<style>

.switch-center-label label {
  font-size: 0.75rem !important;
}

.align-text-print, .align-text-print * {
  text-align: center !important;
}

@media print {
  .no-print, .no-print *
  {
    display: none !important;
  }
  .align-text-print, .align-text-print *
  {
    text-align: right !important;
  }
}

/* v-switch disabled color - not yet used anywhere else in callfinder-ui, we should use the same color when we need it... */
div.switch-disabled.theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__track  {
  color: rgba(0, 0, 0, .28) !important;
}

/* v-switch color when not selected - not yet used anywhere else in callfinder-ui, we should use the same color when we need it... */
div.switch-inactive.v-input--switch--inset .v-input--switch__track, .v-input--switch--inset .v-input--selection-controls__input {
  width: 48px;
  color: rgba(0, 0, 0, .8) !important;
}



</style>
