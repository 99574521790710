<template>
  <v-content>
    <v-container height="50%">
      <v-col>
        <v-row justify="center">
          <v-container fill-height>
            <v-row justify="center">
              <div class="display-2">
                Invalid Token
              </div>
            </v-row>
          </v-container>
        </v-row>
        <v-row justify="center">
          <div class="title text-center">
            Links are only accessible for up to 30 days from the date they are
            generated. Please request a new link from the sender.
          </div>
        </v-row>
      </v-col>
    </v-container>
  </v-content>
</template>

<script>
export default {};
</script>
